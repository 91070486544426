import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { TypographyProps, CommonProps, BorderProps } from "styles/constants";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";
import { Box, Label, Flex } from "primitives";

interface HTMLInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelColor?: string;
  disabled?: boolean;
  checked: boolean;
  onChange: any;
  labelWidth?: any;
  value?: any;
}
interface InputProps
  extends TypographyProps,
    CommonProps,
    BorderProps,
    TestableProps {}

const Icon = styled("svg")`
  fill: none;
  stroke: white;
  stroke-width: 3px;
  margin-bottom: 1.5px;
`;

const HiddenCheckbox = styled("input").attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
export interface CheckboxProps {
  disabled?: boolean;
  checked: boolean;
  onClick: any;
}
const StyledCheckbox = styled(Box)<CheckboxProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.disabled
      ? themeGet("colors.palette.grey.1")
      : props.checked
      ? themeGet("colors.palette.brand.0")
      : "transparent"};
  border: ${themeGet("borders.3")} ${themeGet("colors.palette.brand.0")};
  border-radius: 3px;
  transition: all 150ms;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const CheckBox = (props: HTMLInput & InputProps) => {
  const {
    checked,
    label,
    onChange,
    disabled,
    labelColor,
    labelWidth,
    value
  } = props;
  return (
    <Flex
      flexWrap={label ? "nowrap" : "wrap"}
      m={2}
      overflow="visible"
      flex={1}
    >
      <HiddenCheckbox
        onChange={(event) => onChange(value !== undefined ? !value : event)}
        checked={value !== undefined ? !value : checked}
        disabled={disabled}
      />
      <StyledCheckbox
        mr={1}
        checked={value !== undefined ? value : checked}
        disabled={disabled ? disabled : false}
        onClick={(event: any) =>
          !disabled && onChange(value !== undefined ? !value : event)
        }
        overflow="visible"
      >
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      <Label width={labelWidth} color={labelColor ? labelColor : "text.white"}>
        {label}
      </Label>
    </Flex>
  );
};

CheckBox.displayName = "CheckBox";

CheckBox.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: CheckBox.displayName
};

export default CheckBox;

import React, { useCallback, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { Text, Box, Flex } from "primitives";
import { Form } from "app/shared";
import { createDataSourceAlert } from "../services";
import { alertSchema, alertUiSchema } from "../models/schemas";
import { convertAlertFormDataToApiSpec } from "../helpers";
import useToggle from "components/toggler/hooks/useToggle";
import { Suspense, useMutation } from "app/network";
import { AlertLevels, Alert, DataSource } from "../models";
import { AddButton, SaveButton, CancelButton } from "components";

interface AlertCreateProps {
  dataSource: DataSource;
  onChange: (dataSource: DataSource, alert: Alert) => void;
}

export const AlertCreate = ({ dataSource, onChange }: AlertCreateProps) => {
  const [toggled, onToggle] = useToggle({ defaultToggled: false });
  return (
    <>
      <AddButton onClick={onToggle}>Alert</AddButton>
      <Dialog fullWidth={true} open={toggled} maxWidth="md">
        <Suspense>
          <AlertCreateContent
            dataSource={dataSource}
            onToggle={onToggle}
            onChange={onChange}
          />
        </Suspense>
      </Dialog>
    </>
  );
};

interface AlertCreateContentProps extends AlertCreateProps {
  onToggle: () => void;
}

const AlertCreateContent = ({
  dataSource,
  onToggle,
  onChange
}: AlertCreateContentProps) => {
  const { response, loading, error, action } = useMutation();
  const [formData, setFormData] = useState();
  const [isOneByOne, setIsOneByOne] = useState([""]);
  const [values, setValues] = useState({
    valueBoundary: {}
  });

  const submit = useCallback(
    (e, form) => {
      e.preventDefault();
      setFormData(form.formData);
      const alert = convertAlertFormDataToApiSpec(dataSource, form.formData);
      return createDataSourceAlert(dataSource, alert);
    },
    [dataSource]
  );

  useEffect(() => {
    if (response) {
      const { data } = response;
      onToggle();
      if (onChange) {
        onChange(dataSource, data);
      }
    }
  }, [response, dataSource, onChange, onToggle]);

  const handleFormChange = (
    form: any,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    if (
      //eslint-disable-next-line
      form.formData.valueBoundary.isBulkAssign == "false" &&
      isOneByOne.indexOf("valueBoundary") === -1
    ) {
      form.formData.valueBoundary.isBulkAssign = false;
      const newValues = {
        valueBoundary: {
          minValue: form.formData.valueBoundary.minValue,
          maxValue: form.formData.valueBoundary.maxValue
        }
      };
      setValues({ ...values, ...newValues } as any);
      let newIsOneByOne = [...isOneByOne];
      newIsOneByOne.push("valueBoundary");
      setIsOneByOne(newIsOneByOne);
    }
    //eslint-disable-next-line
    if (form.formData.valueBoundary.isBulkAssign == "true") {
      form.formData.valueBoundary.isBulkAssign = true;
      let newIsOneByOne = [...isOneByOne];
      const index = newIsOneByOne.indexOf("valueBoundary");
      if (index > -1) {
        newIsOneByOne.splice(index, 1);
      }
      setIsOneByOne(newIsOneByOne);
    }

    AlertLevels.forEach((alertLevel) => {
      if (
        //eslint-disable-next-line
        form.formData.alertLevels[alertLevel].isBulkAssign == "false" &&
        isOneByOne.indexOf(alertLevel) === -1
      ) {
        form.formData.alertLevels[alertLevel].isBulkAssign = false;
        const newValues: Record<any, any> = {};
        if (
          form.formData.alertLevels[alertLevel].lowerThreshold ||
          form.formData.alertLevels[alertLevel].upperThreshold
        ) {
          newValues[alertLevel] = {
            thresholds: {
              lowerThreshold:
                form.formData.alertLevels[alertLevel].lowerThreshold,
              upperThreshold:
                form.formData.alertLevels[alertLevel].upperThreshold
            }
          };
        }
        setValues({ ...values, ...newValues } as any);
        let newIsOneByOne = [...isOneByOne];
        newIsOneByOne.push(alertLevel);
        setIsOneByOne(newIsOneByOne);
      }
      //eslint-disable-next-line
      if (form.formData.alertLevels[alertLevel].isBulkAssign == "true") {
        form.formData.alertLevels[alertLevel].isBulkAssign = true;
        let newIsOneByOne = [...isOneByOne];
        const index = newIsOneByOne.indexOf(alertLevel);
        if (index > -1) {
          newIsOneByOne.splice(index, 1);
        }
        setIsOneByOne(newIsOneByOne);
      }
      setFormData(form.formData);
    });
  };

  return (
    <>
      <DialogTitle>
        Add Alert to #{dataSource.id} {dataSource.name}
      </DialogTitle>

      <DialogContent>
        <Form
          id="alert-add"
          formData={formData}
          schema={alertSchema(dataSource, isOneByOne, values)}
          uiSchema={alertUiSchema(dataSource, isOneByOne)}
          onChange={(form: any, e: React.FormEvent<HTMLInputElement>) => {
            handleFormChange(form, e);
          }}
          onSubmit={(form: any, e: React.FormEvent<HTMLInputElement>) => {
            return action(() => submit(e, form));
          }}
          disabled={loading}
        >
          <></>
        </Form>
      </DialogContent>
      <DialogActions>
        <Flex justifyItems="flex-end" alignItems="center">
          {error ? (
            <Box mx={2}>
              <Text color="text.danger">Error...</Text>
            </Box>
          ) : null}
          <SaveButton type="submit" form="alert-add" mr={1} disabled={loading}>
            Save
          </SaveButton>
          <CancelButton onClick={onToggle}>Cancel</CancelButton>
        </Flex>
      </DialogActions>
    </>
  );
};

import { Fonts } from "styled-components";

//                [0, 1, 2,  3,  4,  5,  6,  7,  8];
const fontSizes = [8, 10, 11, 12, 13, 15, 15, 19, 33];

const fonts: Fonts = {
  fonts: ["'Exo 2', sans-serif"],
  fontSizes,
  fontWeights: {
    light: 300,
    regular: 400,
    bold: 600
  },
  heading: [
    fontSizes[8],
    fontSizes[7],
    fontSizes[6],
    fontSizes[5],
    fontSizes[4],
    fontSizes[3]
  ]
};

export default fonts;

import { SelectBox } from "app/shared";
import { CheckBox } from "components";

const telecommandsExecutionConfigOptionsSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "telecommandsExecution.schema.json",
  title: "Aurora TelecommandsExecution",
  description:
    "The configuration options for the Aurora's TelecommandsExecution component",
  type: "object",
  definitions: {
    defaults: {
      $id: "#/definitions/defaults",
      title: "Defaults",
      description:
        "Properties related to the visualization of the default values inside the telecommands",
      type: "object",
      properties: {
        fill: {
          $id: "#/definitions/fill",
          title: "Default Fill",
          description: "Default fill type used for the telecommand filling",
          type: "string",
          enum: ["none", "Form", "Terminal", "JsonUpload"],
          default: "Form"
        },
        visualization: {
          $id: "#/definitions/defaults/properties/visualization",
          title: "Defaults Visualization",
          description: "Behavior of the default values visualization",
          type: "string",
          enum: ["visible", "hidden", "defaultVisible", "defaultHidden"],
          default: "defaultVisible"
        },
        automatic: {
          $id: "#/definitions/defaults/properties/automatic",
          title: "Automatic Fill",
          description: "Weather the default values are automatically filled",
          type: "boolean",
          default: true
        }
      },
      required: ["fill", "visualization", "automatic"]
    },
    send: {
      $id: "#/definitions/send",
      title: "Send Properties",
      description: "Properties related to the telecommand sending",
      type: "object",
      properties: {
        sendConfirmation: {
          $id: "#/definitions/send/properties/sendConfirmation",
          title: "Send Confirmation",
          description: "Weather the sending action requires a confirmation",
          type: "boolean",
          default: false
        },
        preventMultiSend: {
          $id: "#/definitions/send/properties/preventMultiSend",
          title: "Prevent Multi Sending",
          description:
            "If enabled, locks the sending button until the user selects a new telecommand (or select the same telecommand again, or even use the resend/reuse options)",
          type: "boolean",
          default: false
        }
      }
    }
  },
  properties: {
    defaults: {
      $ref: "#/definitions/defaults"
    },
    send: {
      $ref: "#/definitions/send"
    }
  },
  required: ["defaults", "send"]
};

const telecommandsExecutionConfigOptionsDefault = {
  defaults: {
    fill: "Form",
    visualization: "defaultVisible",
    automatic: true
  },
  send: {
    sendConfirmation: false,
    preventMultiSend: false
  }
};

const telecommandsExecutionConfigOptionsUISchema = {
  defaults: {
    fill: { "ui:widget": SelectBox },
    visualization: { "ui:widget": SelectBox },
    automatic: { "ui:widget": CheckBox }
  },
  send: {
    sendConfirmation: { "ui:widget": CheckBox },
    preventMultiSend: { "ui:widget": CheckBox }
  }
};

export {
  telecommandsExecutionConfigOptionsSchema,
  telecommandsExecutionConfigOptionsDefault,
  telecommandsExecutionConfigOptionsUISchema
};

import React, { Component } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

export class TabInput extends Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { tabs, containers, record, disabled } = this.props;
    const { value } = this.state;
    return (
      <>
        <Tabs value={value} onChange={this.handleChange}>
          {tabs.map((label) => (
            <Tab
              key={label}
              label={label}
              disabled={disabled && disabled.indexOf(label) !== -1}
            />
          ))}
        </Tabs>
        {containers(value, record)}
      </>
    );
  }
}

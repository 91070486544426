import { SelectBox, InputBase } from "app/shared";
import { MultiCheckBox } from "components";

const passageCountdownConfigOptionsSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "table.schema.json",
  title: "Aurora Table",
  description: "The configuration options for the Aurora's Table component",
  type: "object",
  definitions: {
    groundstations: {
      $id: "#/properties/header/properties/groundstations",
      title: "Ground Stations",
      description:
        "Only passages from the selected groundstations will be shown.",
      type: "array",
      default: [],
      items: {
        $id: "#/properties/header/properties/show/items",
        type: "number",
        enum: [-1],
        enumNames: []
      },
      uniqueItems: true
    },
    gsVisibilityMode: {
      $id: "#/definitions/visibilityMode",
      title: "Visibility Mode",
      description: "The visibility mode of the component",
      type: "string",
      enum: ["alwaysVisible", "alwaysHidden", "onHoverVisible"],
      default: "onHoverVisible"
    },
    closeApproachTime: {
      $id: "#/definitions/closeApproachTime",
      title: "Close Approach Time",
      description:
        "Time, in milliseconds, before the beggining of the passage that is considered a close approach.",
      type: "number",
      default: "30000"
    }
  },
  properties: {
    groundstations: {
      $ref: "#/definitions/groundstations"
    },
    gsVisibilityMode: {
      $ref: "#/definitions/gsVisibilityMode"
    },
    closeApproachTime: {
      $ref: "#/definitions/closeApproachTime"
    }
  },
  required: ["groundstations", "gsVisibilityMode", "closeApproachTime"]
};

const passageCountdownConfigOptionsDefault = {
  groundstations: [],
  gsVisibilityMode: "alwaysVisible",
  closeApproachTime: 30000
};

const passageCountdownConfigOptionsUISchema = {
  groundstations: { "ui:field": MultiCheckBox },
  gsVisibilityMode: { "ui:widget": SelectBox },
  closeApproachTime: { "ui:widget": InputBase }
};

export {
  passageCountdownConfigOptionsSchema,
  passageCountdownConfigOptionsDefault,
  passageCountdownConfigOptionsUISchema
};

import { fetchJson } from "app/network/utils/fetch";
import { httpClient as appHttpClient } from "app/network/httpClient";
import {
  GET_MANY,
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  DELETE_LIST,
  UPDATE_ONE,
  UPLOAD
} from "app/network";
import { stringify } from "qs";

const DataProvider = (apiUrl, httpClient = fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = "";
    let query = (params && params.query) || {};
    const options = {};
    switch (type) {
      case GET_LIST: {
        const { filter, pagination } = params || {};

        if (resource === "telecommands") {
          const { satellite } = params.filter;
          delete filter.satellite;
          url = `${apiUrl}/satellite/${satellite}/${resource}`;
        } else {
          url = `${apiUrl}/${resource}`;
        }

        if (filter) {
          query = {
            ...filter
          };
        }

        if (pagination) {
          const { page, perPage, pageSize, pageNumber } = pagination;
          const offset = page * perPage - perPage;

          query = {
            ...query,
            ...(page && { offset: offset || 0 }),
            ...(perPage && { limit: perPage || 10 }),
            ...(pageSize && { pageSize }),
            ...(pageNumber && { pageNumber })
          };
        }

        // remove empty values
        Object.keys(query).forEach((key) => !query[key] && delete query[key]);

        break;
      }
      case GET_MANY:
      case GET_ONE:
        if (params && params.id) {
          url = `${apiUrl}/${resource}/${params.id}`;
        } else {
          url = `${apiUrl}/${resource}`;
        }
        break;
      case UPDATE:
        if (params && params.id) {
          url = `${apiUrl}/${resource}/${params.id}`;
        } else {
          url = `${apiUrl}/${resource}`;
        }
        options.method = "PUT";
        options.body = JSON.stringify(params.data);
        break;
      case UPDATE_ONE:
        url = `${apiUrl}/${resource}`;
        options.method = "PUT";

        // TODO: fix this
        if (resource.startsWith("script/")) {
          options.headers = new Headers({
            "content-type": "application/json"
          });
        }
        options.body = JSON.stringify(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = "POST";

        // TODO: fix this
        if (resource.startsWith("alert")) {
          options.headers = new Headers({
            "content-type": "application/json"
          });
        }

        options.body = JSON.stringify(params.data);
        break;
      case UPLOAD:
        if (params && params.id) {
          url = `${apiUrl}/${resource}/${params.id}`;
        } else {
          url = `${apiUrl}/${resource}`;
        }
        options.method = "POST";
        options.body = params.data;
        options.headers = new Headers({});
        break;
      case DELETE:
        if (params && params.id) {
          url = `${apiUrl}/${resource}/${params.id}`;
        } else {
          url = `${apiUrl}/${resource}`;
        }
        options.method = "DELETE";
        break;
      case DELETE_LIST:
        url = `${apiUrl}/${resource}`;
        options.method = "DELETE";
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }

    // Add query parameters
    if (query && Object.keys(query).length) {
      url = `${url}?${stringify(query)}`;
    }

    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */

  const convertHTTPResponse = (response, type, resource, params) => {
    const { json, headers } = response;

    if (!json && !headers) {
      throw response;
    }

    switch (type) {
      case GET_LIST:
        return {
          data: json,
          total: parseInt(headers.get("vnd.webgs.pagination.totalcount"), 10),
          nextPageUrl: headers.get("vnd.webgs.pagination.nextpageurl"),
          previousPageUrl: headers.get("vnd.webgs.pagination.previouspageurl")
        };
      case DELETE:
        return null;
      default:
        return { data: json };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE"
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json)
      }));
    }

    if (type === GET_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "GET"
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json)
      }));
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then((response) =>
      convertHTTPResponse(response, type, resource, params)
    );
  };
};

export const dataProvider = DataProvider(
  `${process.env.REACT_APP_API_URL}/sat`,
  appHttpClient
);

export const telemetryDataProvider = DataProvider(
  `${process.env.REACT_APP_API_URL}/telemetry`,
  appHttpClient
);

export const alertDataProvider = DataProvider(
  `${process.env.REACT_APP_API_URL}/telemetry`,
  appHttpClient
);

export const visibilityDataProvider = DataProvider(
  `${process.env.REACT_APP_API_URL}/windows`,
  appHttpClient
);

// TODO: check if this is the correct URL
export const scriptDataProvider = DataProvider(
  `${process.env.REACT_APP_API_URL}/script-scv001`,
  appHttpClient
);

export const groundStationDataProvider = DataProvider(
  `${process.env.REACT_APP_API_URL}/windows`,
  appHttpClient
);

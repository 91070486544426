import React from "react";
import { DropdownMenuLink } from "navigation/DropdownMenu";
import { useSelector } from "react-redux";

export const SettingsSelector = () => {
  const constellationId = useSelector(
    (state: any) =>
      state.constellations.selected && state.constellations.selected.id
  );

  return (
    <>
      <DropdownMenuLink to="/satellite-definition">Satellites</DropdownMenuLink>
      <DropdownMenuLink
        to={
          constellationId
            ? `/constellation/${constellationId}`
            : `/constellation/create`
        }
      >
        Constellation
      </DropdownMenuLink>
      <DropdownMenuLink to="/dashboard">Dashboard</DropdownMenuLink>
      <DropdownMenuLink to="/scripts">Scripts</DropdownMenuLink>
      <DropdownMenuLink to="/resources">Resources</DropdownMenuLink>
      <DropdownMenuLink to="/procedures">Procedures</DropdownMenuLink>
      <DropdownMenuLink to="/groundstations">Ground Stations</DropdownMenuLink>
    </>
  );
};

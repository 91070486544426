import React, { Component, ChangeEvent } from "react";
import { Box, Heading, Flex } from "primitives";
import { PaginationSimple } from "app/shared";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  EditButton
} from "components";
import { Link } from "react-router-dom";
import { AddButton, InputField } from "components";
import { SuspenseQuery, Suspense } from "app/network";
import { connect } from "react-redux";
import { Resource } from "../models";
import { fetchResourcesAction } from "../actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ResourceDeleteContainer } from "./ResourceDelete";

interface ResourceListState {
  pageSize: number;
  page: number;
  searchText: string;
}

interface ResourceListProps {
  fetchResources: () => Promise<Resource[]>;
}

export class ResourceList extends Component<
  ResourceListProps,
  ResourceListState
> {
  state = {
    pageSize: 25,
    page: 0,
    searchText: ""
  };

  onChangeSearch(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const { value } = event.currentTarget;
    this.setState({ searchText: value, page: 0 });
  }

  filterResources(resources: Resource[]) {
    const { pageSize, page, searchText } = this.state;
    const minIndex = page * pageSize;
    const maxIndex = minIndex + pageSize;
    const result: Resource[] = resources
      .filter((resource: Resource) =>
        resource.name
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase())
      )
      .slice(minIndex, maxIndex);
    return result;
  }

  render() {
    const { pageSize, page, searchText } = this.state;
    return (
      <Suspense>
        <SuspenseQuery query={this.props.fetchResources}>
          {({ response, reload }) => {
            const resources = this.filterResources(response);
            return (
              <Box data-testid="ResourcesList">
                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                  <Heading display={1}>Resources</Heading>
                  <Flex alignItems="flex-end">
                    <Box mr={4}>
                      <InputField
                        id="search-input"
                        label="search"
                        autoFocus={true}
                        value={searchText}
                        onChange={(
                          e:
                            | ChangeEvent<HTMLInputElement>
                            | ChangeEvent<HTMLTextAreaElement>
                        ) => this.onChangeSearch(e)}
                      />
                    </Box>
                    <Link to="/resources/create">
                      <AddButton>Create</AddButton>
                    </Link>
                  </Flex>
                </Flex>
                <Table>
                  <TableHead>
                    <TableRow bg="fill.0">
                      <TableCell width="50">ID</TableCell>
                      <TableCell width="auto">Name</TableCell>
                      <TableCell width="50" />
                      <TableCell width="50" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resources &&
                      resources.map((resource: Resource) => (
                        <TableRow key={resource.id}>
                          <TableCell>#{resource.id}</TableCell>
                          <TableCell>{resource.name}</TableCell>
                          <TableCell>
                            <Link to={`/resources/${resource.id}`}>
                              <EditButton>Edit</EditButton>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <ResourceDeleteContainer
                              resource={resource}
                              onChange={() => reload()}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <PaginationSimple
                  pageSize={pageSize}
                  nextPageUrl={resources.length >= pageSize ? page + 1 : 0}
                  previousPageUrl={page - 1}
                  onChange={(newPage: any) =>
                    this.setState({
                      page: Number(newPage),
                      pageSize
                    })
                  }
                  onPageSizeChange={(newPageSize: any) =>
                    this.setState({
                      page: 0,
                      pageSize: Number(newPageSize)
                    })
                  }
                />
              </Box>
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchResources: () => dispatch(fetchResourcesAction())
});

export const ResourceListContainer = connect(
  null,
  mapDispatchToProps
)(ResourceList);

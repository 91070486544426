import React from "react";
import { withStyles } from "@material-ui/core";
import { AddButton, DeleteButton } from "components";

const styles = () => ({
  root: {
    margin: "2px"
  },
  element: {
    display: "flex",
    width: "100%",
    marginBottom: "3px"
  },
  elementInputColumn: {
    display: "flex",
    width: "100%",
    "& > div": {
      width: "100%",
      paddingRight: "10px"
    }
  },
  elementColumn: {
    display: "flex",
    flex: "0 1 auto"
  },
  actions: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end"
  },
  fab: {
    position: "absolute",
    top: 0,
    right: 0
  }
});

const ArrayField = ({
  canAdd,
  disabled,
  items,
  onAddClick,
  readonly,
  classes,
  schema
}) => {
  // eslint-disable-next-line no-console
  const { hasRemove } = schema;

  return (
    <div className={classes.root}>
      {items.map((element) => (
        <div className={classes.element} key={element.index}>
          <div className={classes.elementInputColumn}>{element.children}</div>
          {hasRemove && (
            <div className={classes.elementColumn}>
              <div>
                <DeleteButton
                  onClick={element.onDropIndexClick(element.index)}
                  disabled={disabled || readonly}
                >
                  Remove
                </DeleteButton>
              </div>
            </div>
          )}
        </div>
      ))}
      <div className={classes.actions}>
        {canAdd && (
          <AddButton onClick={onAddClick} disabled={disabled || readonly}>
            Add
          </AddButton>
        )}
      </div>
    </div>
  );
};

export const ArrayFieldTemplate = withStyles(styles)(ArrayField);

import React from "react";
import { SelectBox, InputBase } from "app/shared";
import { CheckBox, MultiCheckBox, DateTimePicker } from "components";

const tableConfigOptionsSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "table.schema.json",
  title: "Aurora Table",
  description: "The configuration options for the Aurora's Table component",
  type: "object",
  definitions: {
    time: {
      $id: "#/definitions/time",
      title: "Time",
      description: "Defines the characteristics of the time",
      type: "string",
      examples: ["2011-08-12T20:17:46.384Z"]
    },
    timeUnixTimestamp: {
      $id: "#/definitions/timeUnixTimestamp",
      title: "Time",
      description: "Defines the characteristics of the time",
      type: "integer",
      minimum: 0,
      examples: [1]
    },
    bootcount: {
      $id: "#/definitions/bootcount",
      title: "Bootcount",
      description: "Defines the characteristics bootcount",
      type: "integer",
      minimum: 1
    },
    timeReference: {
      $id: "#/definitions/timeReference",
      title: "Timereference",
      description:
        "Defines the characteristics of the timeReference to be used to retrive the data to be presented",
      type: "object",
      properties: {
        reference: {
          $id: "#/properties/timeReference/properties/reference",
          title: "Reference",
          description:
            'An enumeration for the selection of the time reference to be used for the data querying.\nIf the reference "satelliteBootcountUtc" is selected, the "fromBootcount" and "toBootcount" can be used.\nIf "from" and "to" (and "fromBootcount" and "toBootount" for the case of "satelliteUtcBootcount" is selected), the mos recent value is queried.\n',
          type: "string",
          enum: ["groundUtc", "satelliteUtc", "satelliteBootcountObt"],
          default: "groundUtc"
        }
      },
      dependencies: {
        reference: {
          oneOf: [
            {
              properties: {
                reference: {
                  enum: ["groundUtc", "satelliteUtc"]
                },
                from: {
                  $id: "#/properties/timeReference/properties/from",
                  title: "From",
                  description:
                    "The date-time used to present the data only after this date-time.\nIf not used, this filter is not applied.",
                  $ref: "#/definitions/time"
                },
                to: {
                  $id: "#/properties/timeReference/properties/to",
                  title: "To",
                  description:
                    "The date-time used to present the data only before this date-time.\nIf not used, this filter is not applied.",
                  $ref: "#/definitions/time"
                }
              }
            },
            {
              properties: {
                reference: {
                  enum: ["satelliteBootcountObt"]
                },
                fromBootcount: {
                  $id: "#/properties/timeReference/properties/fromBootcount",
                  title: "From Bootcount",
                  description:
                    'The bootcount used to present the data only after this bootcount. If null, this filter is not applied.\nThis filter only exists if the reference "satelliteBootcountUtc" is selected.',
                  $ref: "#/definitions/bootcount"
                },
                fromBootcountTimestamp: {
                  $id: "#/properties/timeReference/properties/from",
                  title: "From Timestamp (Milis)",
                  description:
                    "The timestamp used to present the data only after this timestamp.\n",
                  $ref: "#/definitions/timeUnixTimestamp",
                  default: 0
                },
                toBootcount: {
                  $id: "#/properties/timeReference/properties/toBootcount",
                  title: "To Bootcount",
                  description:
                    'The bootcount used to present the data only before this bootcount. If null, this filter is not applied.\nThis filter only exists if the reference "satelliteBootcountUtc" is selected.',
                  $ref: "#/definitions/bootcount"
                },
                toBootcountTimestamp: {
                  $id: "#/properties/timeReference/properties/to",
                  title: "To Timestamp (Milis)",
                  description:
                    "The timestamp used to present the data only before this timestamp.\n",
                  $ref: "#/definitions/timeUnixTimestamp",
                  default: 0
                }
              }
            }
          ]
        }
      },
      required: ["reference"]
    },
    updatePeriod: {
      $id: "#/definitions/updatePeriod",
      title: "Update Period",
      description:
        "The period between queries to retrieve the values (milliseconds)",
      type: "integer",
      minimum: 1000,
      default: 2000
    },
    dataSource: {
      $id: "#/definitions/dataSource",
      type: "object",
      properties: {
        name: {
          $id: "#/definitions/dataSource/properties/name",
          title: "Name",
          description: "The original data-source name",
          type: ["string", "null"]
        },
        label: {
          $id: "#/definitions/dataSource/properties/label",
          title: "Label",
          description:
            "The label to be given to the data-source. When used, it overrides the original data-source name",
          type: "string"
        },
        id: {
          $id: "#/definitions/dataSource/properties/id",
          title: "Id",
          description: "The id of data-source",
          type: "integer",
          minimum: 1
        },
        indexes: {
          $id: "#/definitions/dataSource/properties/indexes",
          title: "Indexes",
          description:
            "In case of a vector data-source, represents the indexes to be presented. When is a scalar data-source, this field is not used. If it is an array data-source, and this field is not present or is empty, all the indexes should be considered",
          oneOf: [
            {
              type: "array",
              items: {
                type: "object",
                properties: {
                  index: {
                    $id:
                      "#/definitions/dataSource/properties/indexes/properties/index",
                    type: "integer",
                    minimum: 0
                  }
                },
                required: ["index"]
              }
            },
            {
              type: "null"
            }
          ]
        }
      },
      required: ["name", "id"]
    },
    visibilityMode: {
      $id: "#/definitions/visibilityMode",
      title: "Visibility Mode",
      description: "The visibility mode of the component",
      type: "string",
      enum: ["alwaysVisible", "alwaysHidden", "onHoverVisible"],
      default: "onHoverVisible"
    },
    header: {
      $id: "#/definitions/header",
      title: "Header",
      description: "An explanation about the purpose of this instance.",
      type: "object",
      properties: {
        visibilityMode: {
          $ref: "#/definitions/visibilityMode"
        },
        show: {
          $id: "#/properties/header/properties/show",
          title: "Show",
          description:
            "List of headers to be shown. In any of the header names is not in the list, the complete column should be hidden",
          type: "array",
          default: ["name", "value", "unit", "timeReference"],
          items: {
            $id: "#/properties/header/properties/show/items",
            type: "string",
            title: "The Items Schema",
            description: "The column to be shown",
            enum: ["name", "value", "unit", "timeReference"]
          },
          uniqueItems: true
        }
      },
      required: ["visibilityMode", "show"]
    }
  },
  properties: {
    timeReference: {
      $ref: "#/definitions/timeReference"
    },
    showAlerts: {
      $id: "#/properties/showAlerts",
      title: "Show Alerts",
      description: "Weather the alerts should be shown",
      type: "boolean",
      default: true
    },
    showSearch: {
      $id: "#/properties/showSearch",
      title: "Show Search Input",
      description: "Weather the search input should be shown",
      type: "boolean",
      default: true
    },
    updatePeriod: {
      $ref: "#/definitions/updatePeriod"
    },
    header: {
      $ref: "#/definitions/header"
    },
    dataSources: {
      $id: "#/properties/data-sources",
      title: "DataSources",
      description: "List of data-sources to be added to the component",
      type: "array",
      minItems: 1,
      items: {
        $ref: "#/definitions/dataSource"
      }
    }
  },
  required: [
    "timeReference",
    "showAlerts",
    "updatePeriod",
    "header",
    "dataSources"
  ]
};

const tableConfigOptionsDefault = {
  timeReference: {
    reference: "groundUtc"
  },
  showAlerts: true,
  showSearch: true,
  updatePeriod: 2000,
  header: {
    visibilityMode: "onHoverVisible",
    show: ["name", "value", "unit", "timeReference"]
  },
  dataSources: []
};

const tableConfigOptionsUISchema = {
  timeReference: {
    reference: { "ui:widget": SelectBox },
    from: { "ui:widget": DateTimePicker },
    to: { "ui:widget": DateTimePicker },
    fromBootcountTimestamp: {
      "ui:widget": (props: any) => <InputBase maxLength={12} {...props} />
    },
    toBootcountTimestamp: {
      "ui:widget": (props: any) => <InputBase maxLength={12} {...props} />
    },
    fromBootcount: { "ui:widget": InputBase },
    toBootcount: { "ui:widget": InputBase }
  },
  showAlerts: { "ui:widget": CheckBox },
  showSearch: { "ui:widget": CheckBox },
  updatePeriod: { "ui:widget": InputBase },
  header: {
    visibilityMode: { "ui:widget": SelectBox },
    show: { "ui:field": MultiCheckBox }
  },
  dataSources: { "ui:widget": "hidden" }
};

export {
  tableConfigOptionsSchema,
  tableConfigOptionsDefault,
  tableConfigOptionsUISchema
};

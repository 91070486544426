import { connect } from "react-redux";
import { SatelliteTMDashboard } from "../components/SatelliteTMDashboard";
import { editDashboard } from "app/dashboard/services";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  dashboards: state.dashboard.list
});

const mapDispatchToProps = () => {
  return {
    editDashboard: (id, dashboardDefinition) =>
      editDashboard(id, dashboardDefinition)
  };
};

export const SatelliteTMDashboardContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SatelliteTMDashboard)
);

import Ajv from "ajv";
import { GET_ONE, GET_LIST, UPDATE_ONE, CREATE, DELETE } from "app/network";
import { dataProvider } from "app/network";
import * as actions from "../actions";
import { store } from "app/store";
import { configOptionsSchema } from "app/dashboard/models/configOptions";

export const getUserDashboardsLists = (satelliteDefinitionId) => {
  return dataProvider(
    GET_LIST,
    `dashboard/user-dashboards/${satelliteDefinitionId}/list`,
    {}
  );
};

export const updateUserDashboardsLists = (satelliteDefinitionId, data) => {
  return dataProvider(
    CREATE,
    `dashboard/user-dashboards/${satelliteDefinitionId}/list`,
    {
      data: data
    }
  );
};

export const getUserDashboards = (satelliteDefinitionId) => {
  return dataProvider(
    GET_LIST,
    `dashboard/user-dashboards/${satelliteDefinitionId}`,
    {}
  );
};

export const getUserDashboard = (dashboardId) => {
  return dataProvider(GET_ONE, `dashboard`, {
    id: dashboardId
  }).then((response) => {
    return response.data;
  });
};

export const editDashboard = (dashboardId, dashboardDefinition) => {
  // NOTE: update the component definition so that it conforms with what the server needs.
  // this will be removed as soon as the front-end implements this feature
  const transformedDashboardDefinition = transformDashboardDefinition(
    dashboardDefinition
  );
  return dataProvider(UPDATE_ONE, `dashboard/${dashboardId}`, {
    data: transformedDashboardDefinition
  }).then((response) => {
    store.dispatch(actions.updateDashboard(response.data));
    return response.data;
  });
};

export const createDashboard = (dashboardDefinition) => {
  // NOTE: update the component definition so that it conforms with what the server needs.
  // this will be removed as soon as the front-end implements this feature
  const transformedDashboardDefinition = transformDashboardDefinition(
    dashboardDefinition
  );
  return dataProvider(CREATE, `dashboard`, {
    data: transformedDashboardDefinition
  });
};

export const deleteUserDashboard = (dashboardId) => {
  const data = {
    id: dashboardId
  };
  return dataProvider(DELETE, `dashboard`, data).then(() => {
    store.dispatch(actions.removeDashboard(data));
    return data;
  });
};

export const addDashboards = (dashboards) =>
  store.dispatch(actions.addDashboards(dashboards));

const transformDashboardDefinition = (originalDashboardDefinition) => {
  // update the components with a default label and transform component configOptions
  const updatedComponents = originalDashboardDefinition.components.map((it) => {
    const updatedComponent = Object.assign({}, it);

    if (!updatedComponent.label) {
      updatedComponent.label = "";
    }

    if (
      updatedComponent.configOptions == null &&
      configOptionsSchema[updatedComponent.type]
    ) {
      updatedComponent.configOptions =
        configOptionsSchema[updatedComponent.type].default;
    }

    const updatedDataSources = [];
    const newConfigOptionsDataSources = [];
    it.dataSources.forEach((dataSource) => {
      updatedDataSources.push({
        datasourceId: dataSource.datasourceId,
        label: dataSource.label
      });

      //Setup configOptions.dataSource and configOptions.dataSources options
      if (updatedComponent.configOptions) {
        if (updatedComponent.configOptions.dataSources) {
          if (dataSource.indexes) {
            dataSource.indexes = dataSource.indexes.map((index) => {
              //For now just the index number field is being sent
              //TODO: add remaining index fields (ex: see Graphs schema)
              return { index: !isNaN(index) ? index : index.index };
            });
          }
          const existingDataSource = updatedComponent.configOptions.dataSources.find(
            (ds) => ds.id === dataSource.datasourceId
          );
          newConfigOptionsDataSources.push({
            id: dataSource.datasourceId,
            name: dataSource.name
              ? dataSource.name
              : existingDataSource
              ? existingDataSource.name
              : null,
            label: dataSource.label
              ? dataSource.label
              : existingDataSource
              ? existingDataSource.label
              : null,
            indexes: dataSource.indexes
              ? dataSource.indexes
              : existingDataSource
              ? existingDataSource.indexes
              : null
          });
        }
      }
    });

    updatedComponent.dataSources = updatedDataSources;
    if (
      updatedComponent.configOptions &&
      updatedComponent.configOptions.dataSources
    ) {
      updatedComponent.configOptions.dataSources = newConfigOptionsDataSources;
    }

    //Validate if configOptions are valid using a JSON schema validator
    if (
      configOptionsSchema[updatedComponent.type] &&
      configOptionsSchema[updatedComponent.type].schema
    ) {
      const ajv = new Ajv();
      const validate = ajv.compile(
        configOptionsSchema[updatedComponent.type].schema
      );
      const valid = validate(updatedComponent.configOptions);
      if (!valid) {
        console.error(validate.errors);
      }
    }

    return updatedComponent;
  });

  const transformedDashboardDefinition = Object.assign(
    {},
    originalDashboardDefinition
  );
  transformedDashboardDefinition.components = updatedComponents;
  return transformedDashboardDefinition;
};

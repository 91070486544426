import React, { Component } from "react";
import {
  Grid,
  Select,
  FormControl,
  InputLabel,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { SelectDataSources } from "app/shared";
import { Telemetry } from "app/dataSource";
import { OnboardTimeFilter, DateTimeFilter } from "./HistoricalDataFilters";
import { PaginationSimple, DownloadFile } from "app/shared";
import { createExportRequest, getExportRequest } from "app/satellite/services";
import { Table, TableHead, TableRow, TableCell, TableBody } from "components";
import { Box, Text } from "primitives";
import { TimeReference } from "../../models";
import config from "config/constants";

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  header: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    display: "flex"
  },
  sticky: {
    position: "sticky",
    top: 10,
    height: "95vh",
    overflow: "scroll"
  },
  cell: {
    minWidth: "130px"
  },
  paper: {
    margin: "30px 0",
    padding: "20px 0",
    width: "100%",
    overflowX: "auto"
  },
  download: {
    padding: "0 30px"
  },
  tableContainer: {
    maxWidth: "90vw",
    overflowX: "auto",
    maxHeight: "700px",
    overflow: "auto"
  },
  table: {
    maxWidth: "100vw"
  },
  onboardTimeInput: {
    maxWidth: "100px"
  }
});

const NoData = ({ classes }) => {
  return (
    <div className={classes.tableContainer}>
      <Typography style={{ textAlign: "center", color: "#7AB9DB" }}>
        No TM data
      </Typography>
    </div>
  );
};

const TimeForReference = ({ timeReference, timeInfo }) => {
  if (timeReference === TimeReference.OnboardTime) {
    return (
      <span>
        {timeInfo.bootCount} | {timeInfo.onboardTimeMillis}
      </span>
    );
  } else if (timeReference === TimeReference.SendUtcTime) {
    return <span>{timeInfo.sendTimestamp}</span>;
  } else {
    return <span>{timeInfo.receiveTimestamp}</span>;
  }
};

class DatasourceHistoryTableComponent extends Component {
  state = {
    timeReference: TimeReference.ReceiveUtcTime,
    selectedSystems: [],
    paginationUrl: null,
    pageSize: 25,
    from: null,
    to: null
  };

  componentDidMount() {
    if (!this.props.satellite) {
      const { id } = this.props.match.params;
      this.props.getSatellite(id);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.satellite && this.props.satellite) {
      const { satellite } = this.props;
      const { satelliteDefinitionSummary } = satellite;
      const { satelliteDefinitionId } = satelliteDefinitionSummary;
      this.props.getSatelliteDefinition(satelliteDefinitionId);
    }
  }

  componentWillUnmount() {
    this.props.selectDataSources([]);
  }

  /**
   * Call selectSystem to dispatch
   * the action
   * @param {object} dataSources
   */
  selectDataSources(dataSources) {
    this.props.selectDataSources(dataSources);
    this.setState({ paginationUrl: null });
  }

  onChangeTimeReference(e) {
    const { value } = e.target;
    this.setState({
      timeReference: value,
      from: null,
      to: null,
      paginationUrl: null
    });
  }

  onChangeTimeReferenceHandler = (e) => this.onChangeTimeReference(e);

  renderContent(tmData) {
    const { classes } = this.props;
    const { pageSize } = this.state;

    if (!tmData || (tmData && !tmData.datasourceReading)) {
      return <NoData classes={classes} />;
    }

    const rows = tmData.datasourceReading.data;
    const labels = Object.values(tmData.datasourceReading.dataSourceNames);
    const keys = Object.keys(rows);

    const { nextPageUrl, previousPageUrl } = tmData;
    return (
      <>
        <div className={classes.tableContainer}>
          <Table>
            {keys.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <NoData classes={classes} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                <TableHead>
                  <TableRow bg="fill.0" color="text.white" py={2}>
                    <TableCell
                      position="sticky"
                      top={0}
                      bg="fill.1"
                      width="auto"
                      border={4}
                      borderColor="fill.3"
                    >
                      Time
                    </TableCell>
                    {labels.map((label) => (
                      <TableCell
                        position="sticky"
                        top={0}
                        bg="fill.1"
                        width="auto"
                        border={4}
                        borderColor="fill.3"
                        key={label}
                      >
                        {label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keys.map((key) => (
                    <TableRow key={key}>
                      <TableCell className={classes.cell}>
                        <TimeForReference
                          timeReference={this.state.timeReference}
                          timeInfo={rows[key].timeInfo}
                        />
                      </TableCell>
                      {Object.entries(rows[key].data).map(
                        ([sourceName, source]) => {
                          const sourceValue = source || {};
                          return (
                            <TableCell key={`${key}-${sourceName}`}>
                              {sourceValue.valueHumanReadable ||
                                (Array.isArray(sourceValue.value)
                                  ? sourceValue.value
                                      .map((value) => value.value)
                                      .join(",")
                                  : sourceValue.value)}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </div>

        <PaginationSimple
          pageSize={pageSize}
          nextPageUrl={nextPageUrl}
          previousPageUrl={previousPageUrl}
          onChange={(paginationUrl) => this.setState({ paginationUrl })}
          onPageSizeChange={(size) =>
            this.setState({ pageSize: size, paginationUrl: null })
          }
        />
      </>
    );
  }

  render() {
    const {
      classes,
      satellite,
      options,
      satelliteDefinition,
      selectedDataSources
    } = this.props;

    const { selectedSystems, timeReference, from, to, ...params } = this.state;
    const exportFilters = {
      timeReference,
      from,
      to,
      datasources: selectedDataSources.join(",")
    };

    // TODO: Add a debounce on the query so that the request is only sent when the from and to values stabilize
    return (
      <div className={classes.root} data-testid="DataSourceTree">
        {options.label && (
          <Text fontSize={18} m="10px 0">
            {options.label}
          </Text>
        )}
        <Box overflow="visible" bg="fill.0" p={2}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                item
                xs={12}
              >
                <FormControl>
                  <InputLabel>Time reference</InputLabel>
                  <Select
                    native
                    value={timeReference}
                    onChange={this.onChangeTimeReferenceHandler}
                  >
                    <option value={TimeReference.ReceiveUtcTime}>
                      Ground Station UTC
                    </option>
                    <option value={TimeReference.SendUtcTime}>
                      Satellite UTC
                    </option>
                    <option value={TimeReference.OnboardTime}>
                      Satellite OBT
                    </option>
                  </Select>
                </FormControl>

                {timeReference === TimeReference.OnboardTime ? (
                  <OnboardTimeFilter
                    classes={classes}
                    timeFilterChanged={(from, to) =>
                      this.setState({ from, to, paginationUrl: null })
                    }
                  />
                ) : (
                  <DateTimeFilter
                    timeFilterChanged={(from, to) =>
                      this.setState({ from, to, paginationUrl: null })
                    }
                  />
                )}

                <SelectDataSources
                  key={satelliteDefinition.id}
                  satelliteDefinition={satelliteDefinition}
                  selectedDataSources={selectedDataSources}
                  selectedSystems={selectedSystems}
                  onChange={({ dataSources, systems }) => {
                    this.selectDataSources(dataSources);
                    this.setState({ selectedSystems: systems });
                  }}
                />

                {selectedDataSources.length > 0 && from && to && (
                  <DownloadFile
                    title="Export as CSV"
                    createExportRequest={createExportRequest}
                    getExportRequest={getExportRequest}
                    params={{ satellite, filter: exportFilters }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {satellite && (
                  <Box
                    bg="fill.3"
                    overflow="auto"
                    style={{ marginTop: "20px" }}
                  >
                    <Telemetry
                      satellite={satellite}
                      ids={selectedDataSources}
                      multiple={true}
                      params={{ from, to, timeReference, ...params }}
                      autoUpdates={false}
                      interval={config.timer.table}
                    >
                      {(tmData) => this.renderContent(tmData)}
                    </Telemetry>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}

export const DatasourceHistoryTable = withStyles(styles)(
  DatasourceHistoryTableComponent
);

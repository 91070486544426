/**
 * https://github.com/frontend-collective/react-sortable-tree
 * https://github.com/frontend-collective/react-sortable-tree/blob/master/src/node-renderer-default.js
 * Override the default component (NodeRendererDefault) for rendering nodes (but keep the scaffolding generator).
 * This is a last resort for customization - most custom styling should be able to be solved with generateNodeProps,
 * a theme or CSS rules. If you must use it, is best to copy the component in node-renderer-default.js
 * to use as a base, and customize as needed.
 */
import React, { Component } from "react";
import { Box, Flex, Button, Icon } from "primitives";
import { backgroundColorOpacity, borderColorOpacity } from "primitives/Box";
import styled from "styled-components";
import "./nodeRendererDefault.css";
import { Colors, AlertValueStatus, Severity } from "app/alert/models";

const ButtonCollapsable = styled(Button)`
  appearance: none;
  position: absolute;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  padding: 0;
  top: 13px;
  transform: translate(-50%, -50%);
  cursor: pointer;

  ${backgroundColorOpacity}
  ${borderColorOpacity}
`;

const NodeBody = styled(Flex)``;

NodeBody.defaultProps = {
  bg: "fill.0",
  border: 1,
  px: 1,
  borderRadius: "2px",
  minWidth: "500px",
  color: "text.default",
  fontSize: 1,
  alignItems: "center"
};

class NodeConstellationRenderer extends Component {
  getAlertSeverities(node) {
    const alertSeverities = [];
    node.alerts &&
      node.alerts.forEach((alert) => {
        if (alert.value.length === 1) {
          const valueAlert = alert.value[0].alert;
          if (valueAlert && valueAlert.type === AlertValueStatus.AlertValue) {
            alertSeverities.push(valueAlert.severity);
          } else if (
            valueAlert &&
            valueAlert.type === AlertValueStatus.OutOfBoundsValue
          ) {
            alertSeverities.push(AlertValueStatus.OutOfBoundsValue);
          }
        } else if (alert.value.length > 1) {
          alert.value.forEach((value) => {
            const valueAlert = value.alert;
            if (valueAlert && valueAlert.type === AlertValueStatus.AlertValue) {
              if (alertSeverities.indexOf(valueAlert.severity) === -1) {
                alertSeverities.push(valueAlert.severity);
              }
            } else if (
              valueAlert &&
              valueAlert.type === AlertValueStatus.OutOfBoundsValue
            ) {
              if (
                alertSeverities.indexOf(AlertValueStatus.OutOfBoundsValue) ===
                -1
              ) {
                alertSeverities.push(AlertValueStatus.OutOfBoundsValue);
              }
            }
          });
        }
      });
    return alertSeverities;
  }

  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      node,
      body,
      path,
      treeIndex,
      dataSources,
      variableHeight
    } = this.props;
    const alertSeverities = this.getAlertSeverities(node);

    let color = this.props.color;

    if (alertSeverities.includes(Severity.Normal)) {
      color = Colors[Severity.Normal];
    }

    if (alertSeverities.includes(Severity.Warning)) {
      color = Colors[Severity.Warning];
    }

    if (alertSeverities.includes(Severity.Critical)) {
      color = Colors[Severity.Critical];
    }

    if (alertSeverities.includes(AlertValueStatus.OutOfBoundsValue)) {
      color = Colors.OutOfBounds;
    }

    let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };

    return (
      <Box pt={1} color={color}>
        {toggleChildrenVisibility && node.children && node.children.length > 0 && (
          <>
            <ButtonCollapsable
              color={color}
              border={1}
              borderColor={color}
              borderOpacity={6}
              bg="fill.2"
              style={buttonStyle}
              onClick={() =>
                toggleChildrenVisibility({
                  node,
                  path,
                  treeIndex
                })
              }
            >
              {node.expanded ? (
                <Icon name="Minus" size={8} />
              ) : (
                <Icon name="Add" size={8} />
              )}
            </ButtonCollapsable>
          </>
        )}
        {node.record && node.record.dataSources.length > 0 && (
          <ButtonCollapsable
            top={0}
            color={color}
            border={1}
            borderColor={color}
            borderOpacity={6}
            bg="fill.2"
            style={buttonStyle}
            onClick={() => this.props.expandNodeContent()}
          >
            {node.contentExpanded ? (
              <Icon name="Minus" size={8} />
            ) : (
              <Icon name="Add" size={8} />
            )}
          </ButtonCollapsable>
        )}
        <NodeBody
          color={color}
          borderColor={color}
          bg={color}
          bgOpacity={1}
          borderOpacity={6}
          fontSize={10}
          height={node.header ? "24px" : "16px"}
        >
          {body}
        </NodeBody>
        {node.record &&
          node.record.dataSources.length > 0 &&
          node.contentExpanded && (
            <Box
              height={
                variableHeight
                  ? dataSources.filter((el) => el !== null).length * 30
                  : "300px"
              }
              maxWidth="500px"
              overflow="scroll"
              borderBottom={3}
              borderLeft={3}
              borderRight={3}
              borderColor={color}
              borderOpacity={6}
            >
              {dataSources}
            </Box>
          )}
      </Box>
    );
  }
}

NodeConstellationRenderer.defaultProps = {
  toggleChildrenVisibility: null,
  body: null,
  color: "palette.brand.0",
  dataSources: null,
  expandNodeContent: null
};

export default NodeConstellationRenderer;

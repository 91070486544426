import React, { useState, useEffect } from "react";
import TablePagination from "@material-ui/core/TablePagination";

export interface PaginationParams {
  page: number;
  perPage: number;
  to?: string | undefined;
}

interface PaginationProps {
  total: number;
  onChange: (props: PaginationParams) => void;
  rowsPerPageOptions?: number[];
  initialPerPage?: number;
  style?: object;
}

const paginationInitial: PaginationParams = {
  page: 1,
  perPage: 10
};

export const Pagination = (props: PaginationProps) => {
  const [page, setPage] = useState(paginationInitial.page);
  const [perPage, setPerPage] = useState(
    props.initialPerPage || paginationInitial.perPage
  );

  const [total, setTotal] = useState(props.total);

  const onChange = ({
    page: newPage,
    perPage: newPerPage
  }: PaginationParams) => {
    const pagination: PaginationParams = {
      page: newPage || page,
      perPage: newPerPage || perPage
    };

    setPage(pagination.page);
    setPerPage(pagination.perPage);

    props.onChange(pagination);
  };

  useEffect(() => {
    total !== props.total && setTotal(props.total);
  }, [total, props.total]);

  return (
    <TablePagination
      count={total}
      rowsPerPage={perPage}
      rowsPerPageOptions={props.rowsPerPageOptions}
      page={page - 1}
      onChangePage={(event, newPage) =>
        onChange({ page: newPage + 1, perPage })
      }
      onChangeRowsPerPage={(event) => {
        onChange({ perPage: parseInt(event.target.value, 10), page });
      }}
      //was "span" before. not sure why.
      component="div"
      style={props.style}
    />
  );
};

import { isArrayOfObjects } from "app/shared/utils";
import { TelemetryFrame } from "app/telemetry/models";

export const LITERAL_ARG_TYPE = "Literal";
export const ENUM_ARG_TYPE = "Enum";
export const ARRAY_ARG_TYPE = "Array";
export const GROUP_ARG_TYPE = "Group";
export const FILE_ARG_TYPE = "File";

export const MANUAL_KIND = "manual";
export const AUTO_KIND = "auto";
export const CONST_KIND = "constant";

export enum TelecommandExecutionActionTypes {
  SelectTelecommand = "SELECT_TC_TO_EXECUTE",
  SelectExecutionMode = "SELECT_TC_EXECUTION_MODE",
  ClearTelecommand = "CLEAR_TC_EXECUTION"
}

export enum TelecommandListActionTypes {
  ClearResponse = "CLEAR_TC_RESPONSE",
  TelecommandResponse = "TC_RESPONSE"
}

export enum TelecommandExecutionMode {
  Form = "Form",
  Terminal = "Terminal",
  JsonUpload = "JsonUpload"
}

export enum TelecommandType {
  Sync = "Sync",
  Async = "Async"
}

export enum TelecommandExecutionStatus {
  Sending = "Sending",
  Sent = "Sent",
  NotSent = "NotSent"
}

export enum TelecommandResponseLevel {
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
  SUCCESS = "SUCCESS"
}

export enum TelecommandResponseStatus {
  TcAck = "TcAck",
  TcNack = "TcNack"
}

/**
 * Redux store state
 */
export interface TelecommandExecutionStoreState {
  telecommandId?: string;
  telecommandExecutionMode?: TelecommandExecutionMode;
  telecommandFormData?: any;
  telecommandPayload?: TelecommandPayload;
  // The telecommand  database id
  telecommandRecordId?: number;
}

export interface TelecommandListStoreState {
  executedTelecommandId?: Number;
  response?: TelemetryFrame;
}

/**
 * Historical executed telecommands
 */

export interface TelecommandResponse {
  statusName: string;
  level: TelecommandResponseLevel;
  reason?: string | null;
}

export interface ExecutedTelecommand {
  satId: number;
  id: number;
  sentAt: string;
  groundStation: null;
  tcType: TelecommandType;
  tcLabel: string;
  status: TelecommandExecutionStatus;
  response: TelecommandResponse;
  telecommandExecutionPayload: { telecommand: TelecommandPayload };
  telecommandProcessedPayload: { telecommand: TelecommandPayload };
}

/**
 * Definition of the specification of the fields for a telecommand. It differs from the
 * TelecommandPayload because the args are JSON schema elements
 */
export interface TelecommandSpec {
  id: string;
  label: string;
  args: any[];
}

/**
 * Information required to run a telecommand
 */
export interface TelecommandArg {
  id: string;
  value: any;
}

export interface TelecommandPayload {
  tcId: string;
  args: TelecommandArg[];
}

export type TelecommandFormData = any;

export class AuroraTelecommandPayload implements TelecommandPayload {
  readonly tcId: string;
  readonly args: TelecommandArg[];
  private passageId?: number;

  private constructor(id: string, args: TelecommandArg[]) {
    this.tcId = id;
    this.args = args;
  }

  toFormData(): TelecommandFormData {
    return this.args.reduce((acc, arg) => {
      return { ...acc, ...this.telecommandArgTransform(arg, {}) };
    }, {});
  }

  private telecommandArgTransform(root: TelecommandArg, parent: any): any {
    if (isArrayOfObjects(root.value)) {
      //recursive call
      const container = root.value.map((v: any) =>
        this.telecommandArgTransform(v, {})
      );

      parent[root.id] = [
        container.reduce((acc: any, v: any) => {
          return { ...acc, ...v };
        }, {})
      ];
    } else {
      parent[root.id] = root.value;
    }
    return parent;
  }

  setPassageId(id: number) {
    this.passageId = id;
  }

  toOutputModel(): {
    telecommand: TelecommandPayload;
    passageId?: number;
  } {
    return {
      telecommand: {
        tcId: this.tcId,
        args: this.args
      },
      passageId: this.passageId
    };
  }

  static build(rawPayload: TelecommandPayload): AuroraTelecommandPayload {
    return new AuroraTelecommandPayload(rawPayload.tcId, rawPayload.args);
  }

  static from(
    spec: TelecommandSpec,
    formData: TelecommandFormData
  ): AuroraTelecommandPayload {
    const args: TelecommandArg[] = Object.keys(formData).map((k) =>
      this.formatTelecommand(k, (formData as any)[k])
    );
    return new AuroraTelecommandPayload(spec.id, args);
  }

  /**
   *
   * @param {string} key
   * @param {primitive, object, array} value
   */
  private static formatTelecommand(id: string, value: any): TelecommandArg {
    if (isArrayOfObjects(value)) {
      const groupValue: TelecommandArg[] = [];
      value.forEach((groupItem: any) => {
        Object.keys(groupItem).forEach((key) => {
          groupValue.push(
            AuroraTelecommandPayload.formatTelecommand(key, groupItem[key])
          );
        });
      });
      return { id, value: groupValue };
    }
    return { id, value };
  }
}

import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseMutation } from "app/network";
import { SaveButton } from "components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { createGroundStationConfig } from "../services";
import { GroundStation, GroundStationConfiguration } from "../models";
import { schemaCreate, uiSchemaCreate } from "../models/schemas";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { SatelliteInstance } from "app/satellite/models";
import { fetchGroundStationsAction } from "../actions";

interface GroundStationConfigProps extends RouteComponentProps {
  satellites: SatelliteInstance[];
  groundStations: GroundStation[];
  fetchGroundStations: () => Promise<GroundStation[]>;
  createGroundStationConfig: (data: any) => Promise<GroundStationConfiguration>;
}

interface GroundStationConfigState {
  formData: any | null;
  groundStationType: string;
}

export class GroundStationConfig extends Component<
  GroundStationConfigProps,
  GroundStationConfigState
> {
  state = {
    formData: null,
    groundStationType: ""
  };

  componentDidMount() {
    this.props.fetchGroundStations();
  }

  onChange(form: any) {
    if (form.formData && form.formData.groundStationID) {
      if (this.getGroundStationById(form.formData.groundStationID)) {
        this.setState({
          groundStationType: this.getGroundStationById(
            form.formData.groundStationID
          ).type
        });
      }
    }

    this.setState({ formData: form.formData });
  }

  getGroundStationById(groundStationID: number): GroundStation {
    return this.props.groundStations.filter((groundStation: GroundStation) => {
      return groundStationID === groundStation.groundStationID;
    })[0];
  }

  async submit(
    e: React.FormEvent<HTMLInputElement>,
    formData: any,
    groundStationType: string
  ) {
    e.preventDefault();

    const newGroundStation = formData;
    newGroundStation.type = groundStationType;
    await this.props.createGroundStationConfig(newGroundStation);
    this.props.history.push("/groundstations");
  }

  render() {
    const { formData, groundStationType } = this.state;
    const { satellites, groundStations } = this.props;

    return (
      <Box color="text.default">
        <Flex mb={2}>
          <Heading display={1}>Ground Station configuration</Heading>
        </Flex>
        <Flex flexDirection="column" bg="fill.0" p={3}>
          <SuspenseMutation>
            {({ loading, error, action }) => (
              <Form
                formData={formData}
                schema={schemaCreate(
                  satellites || [],
                  groundStations,
                  groundStationType
                )}
                uiSchema={uiSchemaCreate(groundStationType)}
                disabled={loading}
                onSubmit={(
                  form: { formData: any },
                  e: React.FormEvent<HTMLInputElement>
                ) =>
                  action(() => this.submit(e, form.formData, groundStationType))
                }
                onChange={(form: GroundStationConfigState) =>
                  this.onChange(form)
                }
              >
                <SaveButton type="submit" disabled={loading}>
                  Save
                </SaveButton>

                {error ? (
                  <Box my={2}>
                    <Text color="text.danger">Error...</Text>
                  </Box>
                ) : null}
              </Form>
            )}
          </SuspenseMutation>
        </Flex>
      </Box>
    );
  }
}

const mapStateToProps = (state: any) => ({
  scripts: state.scripts.scripts,
  groundStations: state.groundStations.groundStations,
  satellites: state.constellations.selected
    ? state.constellations.selected.satelliteInstances
    : []
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  createGroundStationConfig,
  fetchGroundStations: () => dispatch(fetchGroundStationsAction())
});

export const GroundStationConfigCreateContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GroundStationConfig)
);

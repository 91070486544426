import * as ActionTypes from "../models/constants";

/**
 * Telemetry readings
 */

/** fetchReading */
export const fetchReadingStart = () => ({
  type: ActionTypes.FETCH_READING_START
});

export const fetchReadingSuccess = (payload) => ({
  payload,
  type: ActionTypes.FETCH_READING_SUCCESS
});

export const fetchReadingError = () => ({
  type: ActionTypes.FETCH_READING_ERROR
});
/** */

/**
 * Units
 */
export const fetchDatasourceUnitsStart = () => ({
  type: ActionTypes.FETCH_DATASOURCE_UNITS_START
});

export const fetchDatasourceUnitsSuccess = (payload) => ({
  payload,
  type: ActionTypes.FETCH_DATASOURCE_UNITS_SUCCESS
});

export const fetchDatasourceUnitsError = () => ({
  type: ActionTypes.FETCH_DATASOURCE_UNITS_ERROR
});

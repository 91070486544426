import React, { Component } from "react";
import { Flex, Box, Button, Icon } from "primitives";
import { Toggler } from "components";
import { FramePayloadBodyGrid } from "../FramePayloadBodyGrid";
import { AuroraTelemetryFrame, TelemetryFrame } from "app/telemetry/models";
import { FramePayloadAuxiliariesGrid } from "../FramePayloadAuxiliariesGrid";
import { TabInput } from "app/shared";

interface FramesListProps {
  list: TelemetryFrame[];
}

export class SatelliteFramesListTable extends Component<FramesListProps> {
  render() {
    const { list } = this.props;
    return (
      <>
        <Box
          p={2}
          bg="fill.0"
          fontSize={2}
          color="text.default"
          data-testid="SatelliteFramesListTable"
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex minWidth="150px">Receive Timestamp</Flex>
            <Flex width="50px">Satellite Id</Flex>
            <Flex width="100px">Frame Id</Flex>
            <Flex width="200px">Frame name</Flex>
            <Flex width="32px" />
          </Flex>
        </Box>

        {list && list.map((item, index) => this.renderToggler(item, index))}
      </>
    );
  }

  private renderRawData(frame: TelemetryFrame) {
    return (
      <Box p={2}>
        <pre>{JSON.stringify(frame, null, 2)}</pre>
      </Box>
    );
  }

  private renderToggler(item: TelemetryFrame, index: number) {
    const frame = AuroraTelemetryFrame.of(item);
    const showPrettifiedData =
      frame && frame.header && Object.keys(frame.header).length !== 0;
    const tabs = ["RAW DATA"];
    showPrettifiedData && tabs.unshift("PAYLOAD DATA");
    return (
      <Toggler key={index}>
        {([toggled, onToggle]) => (
          <Box
            p={2}
            bg="fill.1"
            fontSize={2}
            data-testid="SatelliteFramesRowContent"
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mb={1}
              pr={2}
            >
              <Flex minWidth="150px">{item.timestamp}</Flex>
              <Flex width="50px">{item.satelliteId}</Flex>
              <Flex width="100px">{item.frameId}</Flex>
              <Flex width="200px">{item.frameName}</Flex>
              <Flex width="32px">
                <Button size="small" onClick={onToggle}>
                  {toggled ? (
                    <Icon name="ArrowUp" size="14" />
                  ) : (
                    <Icon name="ArrowDown" size="14" />
                  )}
                </Button>
              </Flex>
            </Flex>

            {toggled && (
              <Box bg="fill.2">
                <TabInput
                  tabs={tabs}
                  containers={(value: number) => (
                    <>
                      {value === tabs.indexOf("PAYLOAD DATA") && (
                        <>
                          <FramePayloadAuxiliariesGrid tmFrame={frame} />
                          <FramePayloadBodyGrid tmFrame={frame} />
                        </>
                      )}
                      {value === tabs.indexOf("RAW DATA") &&
                        this.renderRawData(frame)}
                    </>
                  )}
                />
              </Box>
            )}
          </Box>
        )}
      </Toggler>
    );
  }
}

export enum ExecutionState {
  CREATED = "CREATED",
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  RUNNING = "RUNNING",
  WAITING_INPUT = "WAITING_INPUT",
  ENDED = "ENDED",
  TERMINATED = "TERMINATED",
  TIMEOUT = "TIMEOUT",
  ERROR = "ERROR"
}

/* eslint-disable-next-line @typescript-eslint/no-namespace */
export class ExecutionStateUtils {
  static isTerminatedSuccessfully(state: ExecutionState): boolean {
    const terminalStates = [ExecutionState.ENDED];
    return terminalStates.includes(state);
  }

  static isFailed(state: ExecutionState): boolean {
    const terminalStates = [
      ExecutionState.ERROR,
      ExecutionState.TERMINATED,
      ExecutionState.TIMEOUT
    ];
    return terminalStates.includes(state);
  }

  static isRunning(state: ExecutionState): boolean {
    const terminalStates = [ExecutionState.RUNNING, ExecutionState.STARTED];
    return terminalStates.includes(state);
  }

  static isReady(state: ExecutionState): boolean {
    const terminalStates = [ExecutionState.CREATED, ExecutionState.SCHEDULED];
    return terminalStates.includes(state);
  }

  static isBlocked(state: ExecutionState): boolean {
    const terminalStates = [ExecutionState.WAITING_INPUT];
    return terminalStates.includes(state);
  }

  static isActive(state: ExecutionState): boolean {
    return (
      ExecutionStateUtils.isReady(state) ||
      ExecutionStateUtils.isRunning(state) ||
      ExecutionStateUtils.isBlocked(state)
    );
  }
}

/**
 * Models an item on the scripts list
 */
export interface ScriptListItem {
  id: string;
  name: string;
  dateCreated: Date;
}

/**
 * Models the details of a script
 */
export interface Script {
  id: number;
  name: string;
  script: string;
}

/**
 * Command to create a script
 */
export interface CreateScriptCommand {
  name: string;
  script: string;
  resources: [
    {
      id: number;
      targetLocation: string;
      s3ObjectName: string;
    }
  ];
}

/**
 * Command to delete a script
 */
export type EditScriptCommand = CreateScriptCommand;

/***
 * Simple alias for a script id
 */
export type ExecutionId = string;

/***
 * Type that represents user provided input in the context of a script execution
 */
export type ScriptInput = string | null;

/***
 * Models a script execution.
 */
export interface Execution {
  id: ExecutionId;
  createdAt: number;
  startedExecutionUtcMillis: number;
  userId: string;
  state: ExecutionState;
  input: Map<string, ScriptInput>;
  logs: string[];
  script: ExecuteScript;
}

export interface ExecuteScript {
  name: string;
  id: number;
  body: string;
}

export interface ExecuteScriptCommand {
  scriptId: number;
  procedureId: number;
  satelliteId: number;
  satelliteDefinitionId: number;
  passageId: number | null;
}

export interface ScriptExecutionListItem {
  id: ExecutionId;
  createdAt: number;
  startedExecutionUtcMillis: number;
  organizationId: string;
  userId: string;
  satelliteId: number;
  state: ExecutionState;
  scriptId: number;
  scriptName: string;
  procedure: {
    id: number;
    name: string;
  };
}

import React, { Component } from "react";
import { DataSourceReadingContainer } from "../containers/DataSourceReadingContainer";
import { TelemetryProps } from "app/telemetry/models";

/**
 * Telemetry component
 * Based in the props args, the component defines
 * which approach  must be used to reading data sources
 *
 * @params {Array<number>} ids
 * @params {object} satellite
 * @params {number} interval
 * @params {ReactNode} children
 * @params {object} system
 * @params {Boolean} multiple
 */
export class Telemetry extends Component<TelemetryProps> {
  render() {
    const {
      ids,
      satellite,
      interval,
      children,
      multiple,
      params,
      autoUpdates,
      options
    } = this.props;
    // Read datasources from IDs
    if (ids && ids.length > 0) {
      return (
        <DataSourceReadingContainer
          key={satellite.id}
          ids={ids}
          satellite={satellite}
          interval={interval}
          multiple={multiple}
          params={params}
          autoUpdates={autoUpdates}
          options={options}
        >
          {(readingProps: any) => children({ ...readingProps })}
        </DataSourceReadingContainer>
      );
    } else {
      return children(null);
    }
  }
}

import React, { Component, MouseEvent } from "react";
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import { deleteSatelliteInstance } from "app/satellite/services";
import { DeleteButton, CancelButton } from "components";
import { Flex } from "primitives";
import { SatelliteInstance } from "app/satellite/models";

interface SatelliteInstanceDeleteProps {
  record: SatelliteInstance;
  onChange: () => void;
}

interface SatelliteInstanceDeleteState {
  modalOpen: boolean;
}

export class SatelliteInstanceDelete extends Component<
  SatelliteInstanceDeleteProps,
  SatelliteInstanceDeleteState
> {
  state = {
    modalOpen: false
  };

  hideDialog = () => {
    this.setState({ modalOpen: false });
  };

  openDialog = () => {
    this.setState({ modalOpen: true });
  };

  handleSubmit = (e: MouseEvent) => {
    e.preventDefault();
    const { record } = this.props;

    deleteSatelliteInstance(record).then(() => {
      this.props.onChange();
      this.hideDialog();
    });
  };

  render() {
    const { record } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        <DeleteButton onClick={() => this.openDialog()}>Delete</DeleteButton>
        <Dialog
          fullWidth={true}
          open={modalOpen}
          maxWidth="sm"
          data-testid="SatelliteInstanceDelete"
        >
          <DialogTitle>Delete {record.label}?</DialogTitle>

          <DialogActions>
            <Flex
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <DeleteButton
                onClick={(e: MouseEvent) => this.handleSubmit(e)}
                mr={1}
              >
                Delete
              </DeleteButton>
              <CancelButton onClick={() => this.hideDialog()}>
                Cancel
              </CancelButton>
            </Flex>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

import React, { Component, MouseEvent } from "react";
import { Box, Heading, Flex, Text, Grid, Button } from "primitives";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { createResource } from "../services";
import { ResourceCreate } from "../models";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Dropzone from "react-dropzone";

interface ResourcesCreateProps extends RouteComponentProps {
  createNewResource: (data: FormData) => any;
}

interface ResourcesCreateState<T extends File> {
  formData: ResourceCreate | null;
  file: T | null;
  loading: boolean;
  uploadError: string;
}

export class ResourcesCreate extends Component<
  ResourcesCreateProps,
  ResourcesCreateState<any>
> {
  constructor(props: ResourcesCreateProps) {
    super(props);
    this.state = {
      formData: null,
      file: null,
      loading: false,
      uploadError: ""
    };
  }

  onChange(form: ResourcesCreateState<any>) {
    this.setState({ formData: form.formData });
  }

  private clearFile() {
    this.setState({ file: null });
  }

  private onDropAccepted<T extends File>(files: T[]) {
    this.setState({ file: files[0], uploadError: "" });
  }

  private onUploadHander(e: MouseEvent<HTMLButtonElement>) {
    const { file } = this.state;
    if (file) {
      try {
        this.setState({ loading: true }, () => {
          this.sendResource(file);
          this.clearFile();
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      this.setState({ loading: false });
      e.preventDefault();
    }
  }

  sendResource(file: File) {
    const { createNewResource } = this.props;
    const data = new FormData();
    data.append("name", file.name);
    data.append("filePayload", file);
    createNewResource(data)
      .then(() => {
        this.props.history.push("/resources");
      })
      .catch((err: Error) => {
        this.setState({ uploadError: err.message });
      });
  }

  render() {
    const { file, loading } = this.state;

    return (
      <Box color="text.default" data-testid="ResourceCreate">
        <Flex mb={2}>
          <Heading display={1}>Resource</Heading>
        </Flex>

        {this.state.uploadError && (
          <Flex mb={2}>
            <Heading display={3} color="text.danger">
              {this.state.uploadError}
            </Heading>
          </Flex>
        )}
        <Grid data-tesid="Resource Upload">
          <Dropzone
            onDrop={(files) => this.onDropAccepted(files)}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <>
                <div {...getRootProps()}>
                  <Box mb={2} p={3} bg="fill.0" border={1}>
                    <input {...getInputProps()} />
                    <p>Drop a file to upload, or click to select it.</p>
                  </Box>
                </div>
                {file && (
                  <Flex
                    p={2}
                    my={2}
                    bg="fill.0"
                    key={file.path}
                    alignItems="center"
                  >
                    <Text bold>{`File:`}</Text>
                    <Text ml={1}>{` ${file.path} - ${file.size} bytes`}</Text>
                  </Flex>
                )}
              </>
            )}
          </Dropzone>

          <Button
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              this.onUploadHander(e);
            }}
            width="fit-content"
            disabled={loading || !file}
          >
            Upload
          </Button>
        </Grid>
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  createNewResource: createResource
});

export const ResourceCreateContainer = withRouter(
  connect(
    null,
    mapDispatchToProps
  )(ResourcesCreate)
);

import React, { Component, RefObject, SFC } from "react";
import {
  Execution,
  ExecutionId,
  ExecutionState,
  ScriptInput,
  ExecutionStateUtils
} from "../models";
import { connect } from "react-redux";
import { fetchExecutionDetails, sendInput } from "../actions";
import { ThunkDispatch } from "redux-thunk";
import { Box, Button, Grid as GridBox, Text, Flex } from "primitives";
import { Grid, TextField } from "@material-ui/core";
import { AnyAction } from "redux";
import ansicolor from "ansicolor";
import { DownloadFile } from "app/shared";

interface ExecutionOutputProps {
  out: string;
}

const ExecutionOutput: SFC<ExecutionOutputProps> = (
  props: ExecutionOutputProps
) => {
  const formattedOut = props.out
    .substring(20) // Clear the timestamp the server injects
    .replace(/</gi, "&lt;") // don't allow html tag injection (XSS)
    .replace(/>/gi, "&gt;") // same
    .replace(/\t/gi, "&nbsp;&nbsp;&nbsp;&nbsp;") // Tabs;
    .replace(/\n/gi, "<br/>"); //Line breaks

  const coloredOut = ansicolor
    .parse(formattedOut)
    .spans.map((it) => `<span style=${it.css}>${it.text}</span>`)
    .join("");

  const payload = { __html: coloredOut };

  return <p dangerouslySetInnerHTML={payload} />;
};

interface ScriptExecutionConsoleProps {
  getExecutionDetails: (executionId: ExecutionId) => Promise<Execution>;
  send: (executionId: ExecutionId, input: ScriptInput) => Promise<Execution>;
  currentExecution?: Execution;
  refreshIntervalMillis: number;
  options: any;
}

interface ScriptExecutionConsoleState {
  timeoutHandle?: any;
  inputAlreadySent: boolean;
  bluredInput: boolean;
  numFetchErrors: number;
  input?: ScriptInput;
}

class BaseScriptExecutionConsole extends Component<
  ScriptExecutionConsoleProps,
  ScriptExecutionConsoleState
> {
  private endOfScroll: RefObject<HTMLDivElement>;
  private inputRef: RefObject<HTMLDivElement>;

  constructor(props: ScriptExecutionConsoleProps) {
    super(props);
    this.state = {
      timeoutHandle: undefined,
      input: undefined,
      numFetchErrors: 0,
      inputAlreadySent: false,
      bluredInput: false
    };
    this.endOfScroll = React.createRef<HTMLDivElement>();
    this.inputRef = React.createRef<HTMLInputElement>();
  }

  componentDidMount() {
    this.stopNetworkPolling();
    this.startNetworkPolling();
  }

  componentWillUnmount() {
    this.stopNetworkPolling();
  }

  componentDidUpdate(prev: ScriptExecutionConsoleProps) {
    const { currentExecution } = this.props;
    const executionChanged =
      currentExecution &&
      prev.currentExecution &&
      prev.currentExecution.id !== currentExecution.id;
    const startedExecution = currentExecution && !prev.currentExecution;
    const stoppedExecution = !currentExecution && prev.currentExecution;
    const currentExecutionTerminated =
      currentExecution && !ExecutionStateUtils.isActive(currentExecution.state);

    if (executionChanged) {
      // Stop polling on the old execution id an restart with the new Id
      this.stopNetworkPolling();
      this.startNetworkPolling();
    } else if (startedExecution) {
      // No previous execution, just start polling
      this.startNetworkPolling();
    } else if (
      !executionChanged &&
      (stoppedExecution || currentExecutionTerminated)
    ) {
      // Only stop network polling after --- Script execution ended output
      setTimeout(() => this.stopNetworkPolling(), 3000);
    } else if (!this.state.timeoutHandle && currentExecution) {
      console.warn(
        `AURORA-1747 - Expecting stable polling loop for script execution with id: ${currentExecution.id}`
      );
      this.startNetworkPolling();
    }

    // If the output of the console has changed, then make sure the user sees the last
    if (
      prev.currentExecution &&
      currentExecution &&
      prev.currentExecution.logs.length !== currentExecution.logs.length
    ) {
      this.scrollToBottom();
    }

    // For the same execution, if the state transitions to WAITING_FOR_INPUT, enable the input button
    const prevExectionIsNotWaitingForInput = prev.currentExecution
      ? !ExecutionStateUtils.isBlocked(prev.currentExecution.state)
      : true;
    const currentExecutionIsWaitingForInput = currentExecution
      ? ExecutionStateUtils.isBlocked(currentExecution.state)
      : false;
    if (
      !executionChanged &&
      prevExectionIsNotWaitingForInput &&
      currentExecutionIsWaitingForInput
    ) {
      this.setState({ inputAlreadySent: false, bluredInput: false });
    }
  }

  private stopNetworkPolling() {
    if (this.state.timeoutHandle) {
      clearTimeout(this.state.timeoutHandle);
      this.setState({ timeoutHandle: undefined });
    }
  }

  private startNetworkPolling() {
    const {
      currentExecution,
      refreshIntervalMillis,
      getExecutionDetails
    } = this.props;
    if (
      currentExecution &&
      refreshIntervalMillis &&
      ExecutionStateUtils.isActive(currentExecution.state)
    ) {
      const executionRefresher = () => {
        getExecutionDetails(currentExecution.id)
          .then(() => {
            const timeOutHandle = setTimeout(
              executionRefresher,
              refreshIntervalMillis
            );
            this.setState({ timeoutHandle: timeOutHandle, numFetchErrors: 0 });
          })
          .catch((err) => {
            console.error("Error fetching execution details", err);
            const factor = this.state.numFetchErrors + 1; // A back-off factor in case of errors
            const timeOutHandle = setTimeout(
              executionRefresher,
              factor * refreshIntervalMillis
            );
            this.setState({
              timeoutHandle: timeOutHandle,
              numFetchErrors: factor
            });
          });
      };
      executionRefresher();
    }
  }

  private sendInput() {
    const { currentExecution, send } = this.props;
    const { input } = this.state;
    if (currentExecution && input !== undefined) {
      const toSend = input;
      this.setState({ input: undefined, inputAlreadySent: true }, () => {
        send(currentExecution.id, toSend).catch(() => {
          console.warn(`Multiple inputs sent to: ${currentExecution.id}`);
        });
        setTimeout(() => this.setState({ inputAlreadySent: false }), 1000);
      });
    }
  }

  private handleKey(e: any) {
    if (e.keyCode === 13) {
      if (!this.state.input) {
        this.setState({ input: "\n" }, () => {
          this.sendInput();
        });
      } else {
        this.sendInput();
      }
    }
  }

  private scrollToBottom() {
    const node = this.endOfScroll.current;
    if (node) {
      node.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => node.scrollIntoView({ behavior: "smooth" }), 100);
    }
  }

  render() {
    const { currentExecution, options } = this.props;
    const { input, inputAlreadySent, bluredInput } = this.state;

    const inputRequired =
      currentExecution &&
      currentExecution.state === ExecutionState.WAITING_INPUT &&
      !inputAlreadySent;
    if (inputRequired && !bluredInput) {
      const inputRef = this.inputRef;
      inputRef && inputRef.current && inputRef.current.focus();
    }

    return (
      <>
        {options.label && (
          <Text fontSize={18} m="10px 0">
            {options.label}
          </Text>
        )}
        <GridBox
          gridTemplateRows="1fr 5fr 0.6fr"
          height="100%"
          border="1px solid #2E4162"
          data-testid="ScriptExecutionConsole"
        >
          <Flex bg="fill.0" overflow="visible" padding="0 20px">
            <Flex flex={1}>
              <h3>
                Status:{" "}
                {currentExecution
                  ? currentExecution.state
                  : "Awaiting script execution"}
              </h3>
            </Flex>
            {currentExecution ? (
              <DownloadFile
                title="Download Logs"
                fileContent={currentExecution.logs.join("\r\n")}
                fileExtension="txt"
                fileName={`${currentExecution.script.id} - ${currentExecution.script.name}`}
              />
            ) : null}
          </Flex>
          <Box bg="fill.1" overflow="auto" padding="0 20px">
            {currentExecution &&
              currentExecution.logs.map((stdout, i) => (
                <ExecutionOutput key={i} out={stdout} />
              ))}
            <div ref={this.endOfScroll}></div>
          </Box>
          <Box
            bg="fill.1"
            padding="5px 20px"
            borderTop="1px solid #2E4162"
            overflow="visible"
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={10}>
                <TextField
                  name="script-input"
                  fullWidth
                  required={true}
                  disabled={!currentExecution || !inputRequired}
                  value={input ? input : ""}
                  onChange={(e) => this.setState({ input: e.target.value })}
                  onKeyDown={(e) => this.handleKey(e)}
                  inputRef={this.inputRef}
                  onBlur={() => this.setState({ bluredInput: true })}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  size="medium"
                  disabled={!currentExecution || !inputRequired}
                  onClick={() => this.sendInput()}
                  ml={2}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Box>
        </GridBox>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentExecution: state.scriptExecution.currentExecution
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  getExecutionDetails: (executionId: ExecutionId) =>
    dispatch(fetchExecutionDetails(executionId)),
  send: (executionId: ExecutionId, input: ScriptInput) =>
    dispatch(sendInput(executionId, input))
});

export const ScriptExecutionConsole = connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseScriptExecutionConsole);

import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import {
  TYPOGRAPHY,
  BORDER,
  TypographyProps,
  CommonProps,
  BorderProps
} from "styles/constants";
import { Box } from "primitives";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

interface HTMLInput extends React.InputHTMLAttributes<HTMLInputElement> {}

interface InputProps
  extends TypographyProps,
    BorderProps,
    CommonProps,
    TestableProps {}

const StyledInput = styled("input")`
  ${TYPOGRAPHY}
  ${BORDER}
    background-color: transparent;
  outline: none;
  border-width: 0px 0px 1px 0px;
  width: ${(props) => (props.width ? props.width : "100%")};
  color: ${(props) =>
    props.disabled
      ? themeGet("colors.palette.grey.1")
      : themeGet("colors.palette.brand.0")};
  padding: 5px 0px;
  &:focus {
    border-bottom-width: 2px;
  }
  &:hover {
    border-bottom-width: 2px;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    font-size: 16px !important;
    -webkit-text-fill-color: ${themeGet("colors.palette.brand.0")} !important;
  }
`;

const Input = (props: HTMLInput & InputProps) => {
  return (
    <Box height="30px">
      <StyledInput {...props} />
    </Box>
  );
};

Input.displayName = "Input";

Input.defaultProps = {
  fontSize: "16px",
  [DATA_TEST_ID_ATTR_NAME]: Input.displayName
};

export default Input;

export enum FeedbackStatus {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error"
}

export interface Feedback {
  title: string;
  details?: string;
  status: FeedbackStatus;
}

import React from "react";
import styled from "styled-components";
import {
  COMMON,
  TYPOGRAPHY,
  BORDER,
  TypographyProps,
  CommonProps,
  BorderProps
} from "styles/constants";
import { Flex, Icon } from "primitives";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

interface HTMLSelect extends React.SelectHTMLAttributes<HTMLSelectElement> {}
interface SelectProps
  extends TypographyProps,
    CommonProps,
    BorderProps,
    TestableProps {
  readOnly?: boolean;
}

const SelectStyled = styled("select")<HTMLSelect & SelectProps>`
  ${COMMON}
  ${TYPOGRAPHY}
  ${BORDER}
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: 100%;
  appearance: none;
  background-color: transparent;
  border-radius: 0;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const IconContainer = styled(Flex)`
  pointer-events: none;
`;

IconContainer.defaultProps = {
  alignItems: "center",
  color: "palette.brand.0",
  borderLeft: 2,
  borderColor: "palette.brand.0",
  px: 2,
  ml: -4
};

const Select = (props: HTMLSelect & SelectProps) => {
  return (
    <Flex alignItems="stretch" flex="1 1 auto">
      <SelectStyled {...props} />
      <IconContainer>
        <Icon name="ArrowDown" width="8" height="4" />
      </IconContainer>
    </Flex>
  );
};

Select.displayName = "Select";

Select.defaultProps = {
  fontWeight: "bold",
  color: "inherit",
  fontSize: 2,
  py: 2,
  pl: 2,
  pr: 5,
  border: "none",
  [DATA_TEST_ID_ATTR_NAME]: Select.displayName
};

export default Select;

import React, { Component } from "react";
import { Telemetry } from "app/dataSource";
import {
  SimpleValueComponent,
  DataTableComponent,
  CesiumComponent,
  SatelliteFramesList,
  GraphDataView,
  ConstellationTree
} from "app/telemetry/components/dashboard";
import { PassageCountdown } from "app/visibilityWindow/components/PassageCountdown";
import { withStyles } from "@material-ui/core/styles";
import { Grid, GridRow } from "components/grid/Grid";
import { TelecommandsList } from "app/telecommand/components/TelecommandsList";
import { TelecommandsExecution } from "app/telecommand/components/TelecommandExecution";
import { Procedure } from "app/procedure/components/ProcedureExecution";
import { VisibilityWindowsList } from "app/visibilityWindow/components/VisibilityWindowsList";
import { Flex, Heading } from "primitives";
import { DatasourceHistoryTableContainer } from "../containers/DatasourceHistoryTableContainer";
import { ScriptExecutionConsole } from "app/scripting/components/ScriptConsole";
import { ScriptExecutionList } from "app/scripting/components/ScriptExecution";
import { DashboardComponents } from "app/dashboard/models";
import config from "config/constants";

const styles = (theme) => ({
  actions: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 20
  },
  gridMargin: {
    marginBottom: 20
  }
});

/**
 * RenderDashboardComponentsByType help us
 * to render the SatelliteDashboard TM.
 * Must be wrapped to the Telemetry component
 * to receive datasourceReadings
 * @param {Array<Object>} component
 */
const RenderDashboardComponentsByType = ({
  component,
  satellite,
  options,
  updateConfigOptions
}) => {
  let dashboardComponent;
  let flexContainerOverflow = "auto";
  let flexContainerOverflowX = "auto";

  const datasourceIds = component.dataSources.map((it) => it.datasourceId);

  const datasourceLabels = {};
  component.dataSources.forEach(
    (ds) => (datasourceLabels[ds.datasourceId] = ds.label)
  );
  //FIXME: HERE
  switch (component.type) {
    case "Label":
      dashboardComponent = (
        <Telemetry
          satellite={satellite}
          satelliteDefinition={satellite.satelliteDefinition}
          ids={datasourceIds}
          interval={
            options && options.updatePeriod
              ? options.updatePeriod
              : config.timer.label
          }
          options={options}
        >
          {(props) => (
            <SimpleValueComponent
              {...props}
              options={{
                ...options,
                label: component.label
              }}
            />
          )}
        </Telemetry>
      );
      break;
    case "RealTimeTable":
      dashboardComponent = (
        <Telemetry
          satellite={satellite}
          satelliteDefinition={satellite.satelliteDefinition}
          ids={datasourceIds}
          interval={
            options && options.updatePeriod
              ? options.updatePeriod
              : config.timer.table
          }
          options={options}
        >
          {(props) => (
            <DataTableComponent
              {...props}
              satellite={satellite}
              options={{ ...options, label: component.label, datasourceLabels }}
              type={component.type}
              updateConfigOptions={(configOptions) =>
                updateConfigOptions(configOptions)
              }
            />
          )}
        </Telemetry>
      );
      break;
    case "Globe":
      flexContainerOverflow = "hidden";
      dashboardComponent = (
        <CesiumComponent options={{ ...options, label: component.label }} />
      );
      break;
    case "TelecommandsSent":
      dashboardComponent = (
        <TelecommandsList
          satellite={satellite}
          options={{ ...options, label: component.label }}
        />
      );
      break;
    case "TelemetryReceived":
      dashboardComponent = (
        <SatelliteFramesList
          satellite={satellite}
          options={{ ...options, label: component.label }}
        />
      );
      break;
    case "TelecommandsExecution":
      dashboardComponent = (
        <TelecommandsExecution
          satellite={satellite}
          options={{ ...options, label: component.label }}
        />
      );
      break;
    case "HistoricalTable":
      dashboardComponent = (
        <DatasourceHistoryTableContainer
          match={{ params: { constellationId: 1, id: satellite.id } }}
          options={{ ...options, label: component.label }}
        />
      );
      break;
    case "HistoricalGraph":
      dashboardComponent = (
        <DatasourceHistoryTableContainer
          match={{ params: { constellationId: 1, id: satellite.id } }}
          options={options}
        />
      );
      break;
    case "ProceduresExecution":
      dashboardComponent = <Procedure name={component.label} />;
      break;
    case "ScriptExecutionConsole":
      dashboardComponent = (
        <ScriptExecutionConsole
          refreshIntervalMillis={250}
          options={{ label: component.label }}
        />
      );
      break;
    case "ScriptExecutionList":
      dashboardComponent = <ScriptExecutionList name={component.label} />;
      break;
    case "RealTimeGraph":
      flexContainerOverflowX = "hidden";
      dashboardComponent = (
        <GraphDataView
          satellite={satellite}
          ids={datasourceIds}
          options={{ ...options, label: component.label, datasourceLabels }}
        />
      );
      break;
    case "ConstellationTree":
      dashboardComponent = (
        <ConstellationTree options={{ ...options, label: component.label }} />
      );
      break;
    case "VisibilityWindows":
      dashboardComponent = (
        <VisibilityWindowsList
          options={{ ...options, label: component.label }}
        />
      );
      break;
    case "PassageCountdown":
      dashboardComponent = (
        <PassageCountdown
          updateConfigOptions={(configOptions) =>
            updateConfigOptions(configOptions)
          }
          type={component.type}
          options={{ ...options, label: component.label }}
        />
      );
      break;
    default:
      dashboardComponent = null;
      break;
  }

  return (
    <Flex
      alignItems="stretch"
      justifyContent="flex-start"
      flexDirection="column"
      height="100%"
      overflow={flexContainerOverflow}
      overflowX={flexContainerOverflowX}
    >
      {dashboardComponent}
    </Flex>
  );
};

/**
 * SatelliteTMDashboard component
 * Used on the constellation page
 */
class TMDashboard extends Component {
  updateConfigOptions(dashboard, componentIndex, configOptions) {
    if (dashboard && componentIndex >= 0 && configOptions) {
      dashboard.components[componentIndex].configOptions = configOptions;
      const { id } = dashboard;
      dashboard.satDefinitionId = dashboard.applicableSatDefinition.id;
      const updatedDashboard = { ...dashboard };
      delete updatedDashboard.applicableSatDefinition;
      delete updatedDashboard.id;
      delete updatedDashboard.ownerId;
      return this.props.editDashboard(id, updatedDashboard);
    }
  }

  renderDashboardComponents(dashboard) {
    const { satellite } = this.props;

    return (
      <Grid
        gridTemplateRows={`repeat(${dashboard.size.row}, 1fr)`}
        gridTemplateColumns={`repeat(${dashboard.size.col}, 1fr)`}
        alignItems="flex-start"
        height="100%"
        overflow="hidden"
        gridGap={2}
        key={dashboard.id}
      >
        {dashboard.components.map((component, index) => (
          <GridRow
            key={index}
            gridRow={`${component.position.row} / ${component.position.row +
              component.size.row}}`}
            gridColumn={`${component.position.col} / ${component.position.col +
              component.size.col}`}
            overflow="auto"
            height="100%"
            alignSelf="stretch"
          >
            <RenderDashboardComponentsByType
              component={component}
              satellite={satellite}
              options={
                component.configOptions ||
                DashboardComponents.getByType(component.type).options
              }
              updateConfigOptions={(configOptions) =>
                this.updateConfigOptions(dashboard, index, configOptions)
              }
            />
          </GridRow>
        ))}
      </Grid>
    );
  }

  render() {
    const { dashboards, satellite, location, match } = this.props;
    if (location.pathname === "/" && dashboards.length > 0) {
      this.props.history.push(`/dashboard/view/${dashboards[0].id}`);
    }
    const dashboardId = match.params.id;
    const dashboard = dashboards.find((it) => it.id === dashboardId);
    if (!dashboard || !dashboard.components) return null;
    return (
      <Grid
        gridTemplateRows="auto 1fr"
        gridTemplateColumns="1fr"
        p={2}
        color="text.default"
        height="100%"
        overflow="hidden"
        data-testid="SatelliteTMDashboard"
      >
        <Heading display={1} mb={2}>
          {satellite.label} - {dashboard.name}
        </Heading>

        {this.renderDashboardComponents(dashboard)}
      </Grid>
    );
  }
}

export const SatelliteTMDashboard = withStyles(styles)(TMDashboard);

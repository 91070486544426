import React from "react";
import { Box } from "primitives";
import { BoxProps } from "primitives/Box";

interface ImageProps extends BoxProps {
  src: string;
}

const Image = (props: ImageProps) => <Box as="img" {...props} />;

Image.displayName = "Image";

Image.defaultProps = {
  as: "img",
  maxWidth: "100%",
  height: "auto"
};

export default Image;

import React, { FocusEvent, ChangeEvent, KeyboardEvent } from "react";
import styled from "styled-components";
import { TypographyProps, CommonProps, BorderProps } from "styles/constants";
import { Box, Label } from "primitives";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";
import Input from "./Input";
import TextArea from "./TextArea";
import File from "./File";

interface InputFieldProps {
  id: string;
  value?: string | number;
  label?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onBlur?: (
    event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>
  ) => void;
  onChange?: (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onKeyDown?: (
    event: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLTextAreaElement>
  ) => void;
  schema?: Object;
  classes?: Object;
  options?: Object;
  rawErrors?: Object;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  file?: boolean;
  width?: string;
  defaultValue?: any;
  containerStyle?: any;
}

interface InputProps
  extends TypographyProps,
    BorderProps,
    CommonProps,
    TestableProps {}

const Container = styled(Box)`
  width: 100%;
`;

const InputField = (props: InputFieldProps & InputProps) => {
  const { file, multiline, label, containerStyle } = props;
  return (
    <Container style={containerStyle}>
      {label ? <Label>{label}</Label> : null}
      {file ? (
        <File {...props} />
      ) : multiline ? (
        <TextArea {...props} />
      ) : (
        <Input {...props} />
      )}
    </Container>
  );
};

InputField.displayName = "InputField";

InputField.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: InputField.displayName
};

export default InputField;

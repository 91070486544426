import { TelecommandListActions } from "../actions";
import { TelecommandListActionTypes, TelecommandListStoreState } from "../models";

const initialState: TelecommandListStoreState = {
    executedTelecommandId: undefined,
    response: undefined
};

const telecommandListReducer = (state = initialState, action: TelecommandListActions): TelecommandListStoreState => {
    switch (action.type) {
        case TelecommandListActionTypes.TelecommandResponse:
            return { ...initialState, executedTelecommandId: action.executedTelecommandId, response: action.response };
        case TelecommandListActionTypes.ClearResponse:
            return { ...initialState };
        default:
            return state;
    }
};

export default telecommandListReducer;
import React, { useState, useCallback, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  createStyles,
  withStyles
} from "@material-ui/core";
import { DataSourceTreeField } from "app/shared";
import { AddButton, SaveButton, CancelButton } from "components";
import { Flex, Text } from "primitives";
import useToggle from "components/toggler/hooks/useToggle";
import { searchDataSourceById } from "./utils";

const styles = createStyles({
  root: { height: "100%", padding: 0 },
  paperScrollPaper: { height: "100%" }
});

export interface FormDataOnChangeProps {
  dataSources: number[];
  systems: number[] | undefined;
}

export interface DataSourceTreeFieldOnChangeProps {
  dataSources: number[];
  systems: number[];
  dataSourceLabels?: any;
  dataSourceIndexes?: any;
}

export interface SelectDataSourcesProps {
  satelliteDefinition: any;
  selectedDataSources: DataSourceIdLabel[];
  selectedSystems?: number[];
  onChange: (props: FormDataOnChangeProps) => void;
  classes: any;
  showConfig: boolean | undefined;
}

interface DataSourceIdLabel {
  datasourceId: number;
  label: string | null;
  indexes: any[];
}

const SelectDataSourcesBase = ({
  satelliteDefinition,
  selectedDataSources,
  selectedSystems,
  onChange,
  classes,
  showConfig
}: SelectDataSourcesProps) => {
  const [toggled, onToggle] = useToggle({ defaultToggled: false });

  const getDataSourceIds = (datasources: DataSourceIdLabel[]) => {
    datasources = datasources.filter((item) => item);
    return datasources.map((dataSource: DataSourceIdLabel) => {
      return dataSource.datasourceId ? dataSource.datasourceId : dataSource;
    });
  };

  const getDataSourceLabels = (datasources: DataSourceIdLabel[]) => {
    const labels = {};
    datasources.forEach((dataSource: DataSourceIdLabel) => {
      (labels as any)[dataSource.datasourceId] = dataSource.label;
    });
    return labels;
  };

  const getDataSourceIndexes = (datasources: DataSourceIdLabel[]) => {
    const indexes = {};
    datasources.forEach((dataSource: DataSourceIdLabel) => {
      (indexes as any)[dataSource.datasourceId] =
        dataSource.indexes && dataSource.indexes.map((dsI) => dsI.index);
    });
    return indexes;
  };

  const [dataSources, setDataSources] = useState(
    getDataSourceIds(selectedDataSources)
  );
  const [dataSourceLabels, setDataSourceLabels] = useState(
    getDataSourceLabels(selectedDataSources)
  );
  const [dataSourceIndexes, setDataSourceIndexes] = useState(
    getDataSourceIndexes(selectedDataSources)
  );
  const [systems, setSystems] = useState(selectedSystems);

  const generateDataSourceData = useCallback(() => {
    const newDataSources: any = [];
    dataSources.forEach((dataSource: any) => {
      const systemDataSource: any = searchDataSourceById(
        satelliteDefinition.systems,
        dataSource
      );
      const newDataSource = {
        datasourceId: dataSource,
        name: systemDataSource
          ? systemDataSource.name
          : (dataSourceLabels as any)[dataSource]
          ? (dataSourceLabels as any)[dataSource]
          : null,
        label: (dataSourceLabels as any)[dataSource]
          ? (dataSourceLabels as any)[dataSource]
          : null,
        indexes: (dataSourceIndexes as any)[dataSource]
          ? (dataSourceIndexes as any)[dataSource]
          : null
      };
      newDataSources.push(newDataSource);
    });
    return newDataSources;
  }, [dataSources, dataSourceLabels, dataSourceIndexes, satelliteDefinition]);

  const submit = useCallback(() => {
    onChange({
      dataSources: showConfig ? generateDataSourceData() : dataSources,
      systems
    });
    onToggle();
  }, [
    onChange,
    onToggle,
    dataSources,
    systems,
    showConfig,
    generateDataSourceData
  ]);

  const cancel = useCallback(() => {
    setDataSources(getDataSourceIds(selectedDataSources));
    setSystems(selectedSystems);
    onToggle();
  }, [onToggle, selectedSystems, selectedDataSources]);

  useEffect(() => {
    setDataSources(getDataSourceIds(selectedDataSources));
  }, [selectedDataSources]);

  useEffect(() => {
    setSystems(selectedSystems);
  }, [selectedSystems]);

  if (!satelliteDefinition) return null;
  return (
    <>
      <AddButton onClick={onToggle}>
        <Text caps>Select DataSources</Text>
      </AddButton>
      <Dialog fullWidth={true} open={toggled} maxWidth="md" classes={classes}>
        <DialogTitle>Select DataSources</DialogTitle>
        <DialogContent>
          <DataSourceTreeField
            key={satelliteDefinition.id}
            satelliteDefinition={satelliteDefinition}
            selectedDataSources={dataSources}
            dataSourceLabels={dataSourceLabels}
            dataSourceIndexes={dataSourceIndexes}
            selectedSystems={systems}
            onChange={(props: DataSourceTreeFieldOnChangeProps) => {
              setDataSourceIndexes(props.dataSourceIndexes);
              setDataSourceLabels(props.dataSourceLabels);
              setDataSources(props.dataSources);
              setSystems(props.systems);
            }}
            showConfig={showConfig}
          />
        </DialogContent>
        <DialogActions>
          <Flex justifyItems="flex-end" alignItems="center">
            <SaveButton mr={1} onClick={submit}>
              Save
            </SaveButton>

            <CancelButton onClick={cancel}>Cancel</CancelButton>
          </Flex>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const SelectDataSources = withStyles(styles)(SelectDataSourcesBase);

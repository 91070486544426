import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { SelectContainer, SelectLabel, Select } from "components";

const styles = () => ({
  form: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  label: {
    marginRight: 10
  },
  formControl: {
    margin: "0 10px"
  }
});

class SatelliteSelector extends Component {
  componentDidMount() {
    this.props.getConstellations();
  }

  componentDidUpdate(prevProps) {
    const { constellations, selectedSatellite: satellite } = this.props;
    const selectedSatellite = satellite || {};
    const previousSelectedSatellite = prevProps.selectedSatellite || {};

    // Get constellation satellites on first update
    if (
      !constellations.selected &&
      constellations &&
      constellations.list.length > 0
    ) {
      this.props.getConstellation(constellations.list[0].id);
    }

    // Get user dashboards when satellite changes
    if (selectedSatellite.id !== previousSelectedSatellite.id) {
      this.getUserDashboards();
    }
  }

  getUserDashboards() {
    const { selectedSatellite } = this.props;

    if (selectedSatellite) {
      this.props
        .getUserDashboards(
          selectedSatellite.satelliteDefinitionSummary.satelliteDefinitionId
        )
        .then(({ data }) => {
          this.props.addDashboards(data || []);
        });
    }
  }

  onHandleChange = (satellite) => {
    this.props.setSatelliteVisibility(satellite);
  };

  render() {
    const { dashboard, selectedSatellite } = this.props;

    return (
      <SelectContainer bg="transparent" data-testid="SatelliteSelector">
        <SelectLabel htmlFor="satellite">Satellite:</SelectLabel>
        <Select
          id="satellite"
          value={selectedSatellite && selectedSatellite.id}
          onChange={(event) => this.onHandleChange(event.target.value)}
          color="text.success"
        >
          {dashboard &&
            dashboard.map((satellite) => (
              <option key={satellite.id} value={satellite.id}>
                {satellite.label}
              </option>
            ))}
        </Select>
      </SelectContainer>
    );
  }
}

export const ConstellationSatelliteSelector = withStyles(styles)(
  SatelliteSelector
);

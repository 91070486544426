import React, { Component } from "react";
import { TelemetryBaseComponent, DateTimeComponent } from "./index";
import { DashboardComponents } from "app/dashboard/models";
import { getAlertColor, getAlertColorForArrayValue } from "app/alert/helpers";
import {
  configOptionsGetTimeReferenceLabel,
  configOptionsGetTimeReferenceValue
} from "app/telemetry/utils/dashboard";
import { Colors } from "app/alert/models";
import { Text, Flex } from "primitives";
import { Tooltip } from "components";

export class SimpleValueComponent extends Component {
  getDataSource(data) {
    return data && data.length > 0 && data[0];
  }

  getReading(readings) {
    return readings && readings.length > 0 && readings[0];
  }

  renderComponentByUnit(reading, unit) {
    let { options } = this.props;
    let inputValue = reading.value;
    switch (unit) {
      case "epoch":
      case "sec":
        return (
          <DateTimeComponent
            unixTimestampSec={inputValue[0].value}
            outputFormat={unit}
            options={options}
          />
        );
      default: {
        if (Array.isArray(inputValue)) {
          const colors = getAlertColorForArrayValue(reading);
          //Filter out non selected indexes and reorder the selected ones
          let reorderedInputValue = [];
          let reorderedColors = [];
          if (options && options.dataSources) {
            const dataSourceOptions = options.dataSources[0];
            if (dataSourceOptions && dataSourceOptions.indexes) {
              dataSourceOptions.indexes.forEach((index) => {
                const value = inputValue[index.index];
                reorderedInputValue.push(value);
                const color = colors[index.index];
                reorderedColors.push(color);
              });
            } else {
              reorderedInputValue = inputValue;
              reorderedColors = colors;
            }
          } else {
            reorderedInputValue = inputValue;
            reorderedColors = colors;
          }
          inputValue = reorderedInputValue.map((value, index, array) => {
            return (
              <>
                <Text
                  color={
                    options.showAlerts !== false
                      ? reorderedColors[index]
                      : Colors.Default
                  }
                >
                  {value.value}
                </Text>
                {index < reorderedInputValue.length - 1 ? (
                  <Text color={Colors.Default}>, </Text>
                ) : null}
              </>
            );
          });
        }
        break;
      }
    }

    switch (options.visibilityMode) {
      case "alwaysVisible":
        return (
          <Flex flexDirection="column" alignItems="center">
            <Flex>
              <Text color="text.white">
                {configOptionsGetTimeReferenceValue(options, reading)}
              </Text>
              <Text color="text.white" ml={1}>
                ({configOptionsGetTimeReferenceLabel(options)})
              </Text>
            </Flex>
            <Text color={getAlertColor(reading)}>{inputValue}</Text>
          </Flex>
        );
      case "onHoverVisible":
        return (
          <Tooltip
            placement="right"
            trigger="hover"
            tooltip={`${configOptionsGetTimeReferenceValue(
              options,
              reading
            )} (${configOptionsGetTimeReferenceLabel(options)})`}
          >
            <Text color={getAlertColor(reading)}>{inputValue}</Text>
          </Tooltip>
        );
      default:
        return <Text color={getAlertColor(reading)}>{inputValue}</Text>;
    }
  }

  renderComponent(data) {
    const reading = this.getReading(data.readings);
    if (reading) {
      const unit = data.dataSource.units && data.dataSource.units.unit;
      return this.renderComponentByUnit(reading, unit);
    }

    return <Text>{`<No Data>`}</Text>;
  }

  render() {
    let { options } = this.props;
    return (
      <TelemetryBaseComponent {...this.props} getData={this.getDataSource}>
        {({ data }) => (
          <Flex
            color="text.default"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            my={4}
            data-testid="SimpleValueComponent"
            overflow="visible"
          >
            {options.label && (
              <Text fontSize={18} bold>
                {options.label}
              </Text>
            )}
            <Flex mt={1} p={2} backgroundColor="rgba(122,185,219,0.1)">
              {this.renderComponent(data)}
            </Flex>
          </Flex>
        )}
      </TelemetryBaseComponent>
    );
  }
}

SimpleValueComponent.defaultProps = {
  ...DashboardComponents.SimpleValue
};

import React, { Component, ChangeEvent } from "react";
import { Select, FormControl, InputLabel } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { extendMoment } from "moment-range";
import config from "config/constants";
import { fetchAllPassages } from "../services";
import { DashboardComponents } from "app/dashboard/models";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DatePicker,
  TableSort
} from "components";
import { Flex, Button, Box } from "primitives";
import { Passage, NextPassageDuration } from "../models";
import { SatelliteInstance } from "app/satellite/models";
import { getActiveSatellite } from "app/shared/utils";
import { sort } from "utils/arrays";
import {
  getNextPassageRemainingTime,
  getNextPassageDuration,
  convertPassagesToTimelineFormat
} from "../helpers";

interface VisibilityWindowsListBaseProps {
  selectedSatellite: SatelliteInstance;
}

interface VisibilityWindowsListBaseState {
  isCalendarView: boolean;
  sortBy: string | null;
  isSortAscending: boolean;
  calendarViewSelectedPassage: Passage | null | undefined;
  allPassages: Passage[];
  groundStations: string[];
  selectedGroundStation: string;
  begin: any;
  end: any;
}

const TableHeader = styled(TableCell)``;
TableHeader.defaultProps = {
  position: "sticky",
  top: "2px",
  border: 4,
  borderColor: "fill.3",
  bg: "palette.blue.3"
};

const momentFormat = "YYYY-MM-DD HH:mm:ss";
const ALL_GROUND_STATIONS: string = "All";

/**
 * Primary component to display the visibility windows.
 */
class VisibilityWindowsListBase extends Component<
  VisibilityWindowsListBaseProps,
  VisibilityWindowsListBaseState
> {
  static defaultProps: any;
  private timer: any;
  private lastTableBeginDate: any;
  private lastTableEndDate: any;
  private lastCalendarBeginDate: any;
  private lastCalendarEndDate: any;

  constructor(props: VisibilityWindowsListBaseProps) {
    super(props);
    this.state = {
      isCalendarView: false,
      sortBy: "aos",
      isSortAscending: true,
      calendarViewSelectedPassage: null,
      allPassages: [],
      groundStations: [],
      selectedGroundStation: ALL_GROUND_STATIONS,
      begin: moment().toDate(),
      end: moment()
        .add(1, "month")
        .toDate()
    };
    this.lastTableBeginDate = moment().toDate();
    this.lastTableEndDate = moment()
      .add(1, "day")
      .toDate();
    this.lastCalendarBeginDate = moment()
      .add(-12, "hour")
      .toDate();
    this.lastCalendarEndDate = moment()
      .add(12, "hour")
      .toDate();
  }

  componentWillMount() {
    const { selectedSatellite } = this.props;
    this.updatePassages(selectedSatellite.id);
  }

  componentDidUpdate(prev: VisibilityWindowsListBaseProps) {
    const { selectedSatellite } = this.props;
    if (prev.selectedSatellite.id !== selectedSatellite.id) {
      this.updatePassages(selectedSatellite.id);
    }

    const { begin, end, isCalendarView } = this.state;
    if (!isCalendarView && begin !== this.lastTableBeginDate) {
      this.lastTableBeginDate = this.state.begin;
    }

    if (!isCalendarView && end !== this.lastTableEndDate) {
      this.lastTableEndDate = this.state.end;
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  updatePassages(satelliteId: number) {
    const begin = moment()
      .subtract(3, "month")
      .toISOString();
    const end = moment()
      .add(1, "month")
      .toISOString();
    fetchAllPassages(satelliteId, begin, end).then((allPassages: Passage[]) => {
      this.setState({ allPassages });
      this.timer = setInterval(() => this.forceUpdate(), config.timer.passages);
    });
  }

  handleItemSelect = (passageID: number, passages: Passage[]) => {
    const calendarViewSelectedPassage = passages.find(
      (passage) => passage.passageID === passageID
    );
    this.setState({ calendarViewSelectedPassage });
  };

  handleCalendarTimeChange = (
    visibleTimeStart: any,
    visibleTimeEnd: any,
    updateScrollCanvas: any
  ) => {
    const begin = new Date(visibleTimeStart);
    const end = new Date(visibleTimeEnd);
    this.lastCalendarBeginDate = begin;
    this.lastCalendarEndDate = end;
    this.setState({ begin, end });
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
  };

  groupRenderer = ({ group }: any) => {
    const { selectedGroundStation } = this.state;
    return (
      <div
        onClick={() => {
          if (selectedGroundStation === group.title) {
            this.setState({ selectedGroundStation: ALL_GROUND_STATIONS });
          } else {
            this.setState({ selectedGroundStation: group.title });
          }
        }}
      >
        <span>{group.title}</span>
      </div>
    );
  };

  renderCalendarView(
    selectedSatellite: any,
    passages: Passage[],
    begin: Date,
    end: Date
  ) {
    const formatedData = convertPassagesToTimelineFormat(passages);
    const passage = this.state.calendarViewSelectedPassage;
    if (passage) {
      passages = [passage];
    }
    return (
      <Box>
        <Timeline
          groups={formatedData.groups}
          items={formatedData.items}
          defaultTimeStart={moment(begin)}
          defaultTimeEnd={moment(end)}
          onItemSelect={(passageID: number) =>
            this.handleItemSelect(passageID, passages)
          }
          onCanvasClick={() =>
            this.setState({
              selectedGroundStation: ALL_GROUND_STATIONS,
              calendarViewSelectedPassage: null
            })
          }
          onTimeChange={this.handleCalendarTimeChange}
          groupRenderer={this.groupRenderer}
        ></Timeline>
        <Table data-testid="DataTableSimple">
          <TableHead>{this.renderTableHead()}</TableHead>
          <TableBody>
            {this.renderPassageTableRows(selectedSatellite, passages)}
          </TableBody>
        </Table>
      </Box>
    );
  }

  setSort(newSortBy: string) {
    const { sortBy, isSortAscending } = this.state;
    this.setState({
      sortBy: newSortBy,
      isSortAscending: newSortBy === sortBy ? !isSortAscending : true
    });
  }

  renderFilters = (groundStations: string[]) => {
    const { selectedGroundStation, begin, end } = this.state;
    return (
      <Flex bg="fill.0" p={2} alignItems="flex-end" overflow="visible">
        <Box mr={4} minWidth={"150px"}>
          <FormControl fullWidth>
            <InputLabel>Ground Station</InputLabel>
            <Select
              fullWidth
              native
              value={selectedGroundStation}
              onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                this.setState({ selectedGroundStation: event.target.value })
              }
            >
              {groundStations.map((groundStation) => (
                <option key={groundStation} value={groundStation}>
                  {groundStation}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box overflow="visible" mr={2}>
          <DatePicker
            label="From"
            name="fromDate"
            placeholder={"Select a date"}
            handleChange={(event: any) => this.setState({ begin: event.value })}
            selected={this.lastTableBeginDate ? this.lastTableBeginDate : begin}
          />
        </Box>
        <Box overflow="visible">
          <DatePicker
            label="To"
            name="toDate"
            placeholder={"Select a date"}
            handleChange={(event: any) => this.setState({ end: event.value })}
            selected={this.lastTableEndDate ? this.lastTableEndDate : end}
          />
        </Box>
      </Flex>
    );
  };

  renderPassageTableRows(selectedSatellite: any, passages: Passage[]) {
    return passages.map((passage: Passage) => {
      const passageDuration: NextPassageDuration = getNextPassageDuration(
        passage
      );
      const passageRemainingTime: NextPassageDuration = getNextPassageRemainingTime(
        passage
      );
      return (
        <TableRow key={passage.passageID}>
          <TableCell>{selectedSatellite.label}</TableCell>
          <TableCell>{passage.groundStationName}</TableCell>
          <TableCell>
            {passageRemainingTime && passageRemainingTime.durationMinutes > 0
              ? passageRemainingTime.formatedDuration
              : moment().isAfter(moment(passage.los))
              ? "Done"
              : "Running"}
          </TableCell>
          <TableCell>{moment.utc(passage.aos).format(momentFormat)}</TableCell>
          <TableCell>{moment.utc(passage.tca).format(momentFormat)}</TableCell>
          <TableCell>{moment.utc(passage.los).format(momentFormat)}</TableCell>
          <TableCell>{passageDuration.formatedDuration}</TableCell>
          <TableCell>{`${passage.maxElevation}º`}</TableCell>
        </TableRow>
      );
    });
  }

  renderTableHead = () => {
    const { sortBy, isSortAscending } = this.state;
    return (
      <TableRow color="text.white">
        <TableHeader width="auto">Satellite</TableHeader>
        <TableHeader width="auto">
          <TableSort
            id="groundStationName"
            name="Ground Station"
            isSortAscending={isSortAscending}
            sortBy={sortBy}
            setSort={(id: string) => this.setSort(id)}
          />
        </TableHeader>
        <TableHeader width="auto">Remaining Time</TableHeader>
        <TableHeader width="auto">
          <TableSort
            id="aos"
            name="AOS"
            isSortAscending={isSortAscending}
            sortBy={sortBy}
            setSort={(id: string) => this.setSort(id)}
          />
        </TableHeader>
        <TableHeader width="auto">
          <TableSort
            id="tca"
            name="TCA"
            isSortAscending={isSortAscending}
            sortBy={sortBy}
            setSort={(id: string) => this.setSort(id)}
          />
        </TableHeader>
        <TableHeader width="auto">
          <TableSort
            id="los"
            name="LOS"
            isSortAscending={isSortAscending}
            sortBy={sortBy}
            setSort={(id: string) => this.setSort(id)}
          />
        </TableHeader>
        <TableHeader width="auto">Duration</TableHeader>
        <TableHeader width="auto">
          <TableSort
            id="maxElevation"
            name="Maximum Elevation"
            isSortAscending={isSortAscending}
            sortBy={sortBy}
            setSort={(id: string) => this.setSort(id)}
          />
        </TableHeader>
      </TableRow>
    );
  };

  renderTableView(
    selectedSatellite: any,
    passages: Passage[],
    groundStations: string[]
  ) {
    return (
      <>
        {this.renderFilters(groundStations)}
        <Table data-testid="DataTableSimple">
          <TableHead>{this.renderTableHead()}</TableHead>
          <TableBody>
            {this.renderPassageTableRows(selectedSatellite, passages)}
          </TableBody>
        </Table>
      </>
    );
  }

  render() {
    const {
      isCalendarView,
      sortBy,
      isSortAscending,
      allPassages,
      selectedGroundStation,
      begin,
      end
    } = this.state;
    const { selectedSatellite } = this.props;
    const filters = {
      selectedGroundStation,
      begin,
      end
    };
    const filterPassagesResult = filterPassages(allPassages, filters);
    const groundStations = filterPassagesResult.groundStations;
    let filteredPassages = filterPassagesResult.passages;
    //Sort
    if (sortBy) {
      filteredPassages = sort(filteredPassages, sortBy, isSortAscending);
    }
    return (
      <>
        <Flex mb={2} overflow="visible">
          {isCalendarView ? (
            <Button
              onClick={() => {
                this.setState({
                  isCalendarView: false,
                  sortBy: null,
                  selectedGroundStation: ALL_GROUND_STATIONS,
                  begin: this.lastTableBeginDate,
                  end: this.lastTableEndDate
                });
              }}
            >
              Change to Table View
            </Button>
          ) : (
            <Button
              onClick={() => {
                const defaultBegin = this.lastCalendarBeginDate;
                const defaultEnd = this.lastCalendarEndDate;
                this.setState({
                  isCalendarView: true,
                  sortBy: null,
                  selectedGroundStation: ALL_GROUND_STATIONS,
                  begin: defaultBegin,
                  end: defaultEnd
                });
              }}
            >
              Change to Calendar View
            </Button>
          )}
        </Flex>
        {isCalendarView
          ? this.renderCalendarView(
              selectedSatellite,
              filteredPassages,
              begin,
              end
            )
          : this.renderTableView(
              selectedSatellite,
              filteredPassages,
              groundStations
            )}
      </>
    );
  }
}

//Returns object with passages filtered by date range and groundstation and list of groundstations from passages within the time range
const filterPassages = (
  passages: Passage[],
  filters: any
): { passages: Passage[]; groundStations: string[] } => {
  const { selectedGroundStation, begin, end } = filters;
  const result = {
    passages: [] as Passage[],
    groundStations: [ALL_GROUND_STATIONS] as string[]
  };
  passages.forEach((passage: Passage) => {
    if (begin && end) {
      const momentExtended = extendMoment(moment as any);
      const calendarRange = momentExtended.range(begin, end);
      const passageRange = momentExtended.range(
        moment(passage.aos),
        moment(passage.los)
      );
      if (!passageRange.overlaps(calendarRange)) {
        return false;
      }
    }

    if (result.groundStations.indexOf(passage.groundStationName) === -1) {
      result.groundStations.push(passage.groundStationName);
    }

    if (
      selectedGroundStation &&
      selectedGroundStation !== ALL_GROUND_STATIONS &&
      passage.groundStationName !== selectedGroundStation
    ) {
      return false;
    }

    result.passages.push(passage);
  });
  return result;
};

VisibilityWindowsListBase.defaultProps = {
  ...DashboardComponents.VisibilityWindows
};

const mapStateToProps = (state: any) => {
  return {
    selectedSatellite: getActiveSatellite(state.constellations.dashboard)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const VisibilityWindowsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(VisibilityWindowsListBase);

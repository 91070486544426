import styled, { css } from "styled-components";
import { themeGet } from "styled-system";
import {
  COMMON,
  TYPOGRAPHY,
  BORDER,
  TypographyProps,
  CommonProps,
  BorderProps
} from "styles/constants";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

export interface TextProps
  extends TypographyProps,
    CommonProps,
    BorderProps,
    TestableProps {
  children?: React.ReactNode | string;
  caps?: boolean;
  regular?: boolean;
  bold?: boolean;
  italic?: boolean;
  center?: boolean;
  wordBreak?: string;
  textDecoration?: string;
  cursor?: string;
  width?: string;
}

const caps = (props: TextProps) =>
  props.caps ? css({ textTransform: "uppercase" }) : null;

const regular = (props: TextProps) =>
  props.regular
    ? css({ fontWeight: themeGet("fontWeights.regular")(props) })
    : null;

const bold = (props: TextProps) =>
  props.bold ? css({ fontWeight: themeGet("fontWeights.bold")(props) }) : null;

const italic = (props: TextProps) =>
  props.italic ? css({ fontStyle: "italic" }) : null;

const center = (props: TextProps) =>
  props.center ? css({ textAlign: "center" }) : null;

const textDecoration = (props: TextProps) =>
  props.textDecoration ? css({ textDecoration: props.textDecoration }) : null;

const cursor = (props: TextProps) =>
  props.cursor ? css({ cursor: props.cursor }) : null;

const width = (props: TextProps) =>
  props.width ? css({ width: props.width }) : "auto";

const Text = styled("span")<TextProps>`
  user-select: none;
  ${TYPOGRAPHY}
  ${COMMON}
  ${BORDER}
  ${caps}
  ${bold}
  ${regular}
  ${italic}
  ${center}
  ${textDecoration}
  ${cursor}
  ${width}
  ${(props) => (props.wordBreak ? `word-break: ${props.wordBreak}` : "")}
`;

Text.displayName = "Text";

Text.defaultProps = {
  p: 0,
  m: 0,
  color: "inherit",
  fontSize: "inherit",
  [DATA_TEST_ID_ATTR_NAME]: Text.displayName
};

export default Text;

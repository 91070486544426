import React, { Component, Fragment } from "react";

export class TelemetryBase extends Component {
  render() {
    const {
      children,
      datasourceReading,
      getData,
      allowedDataTypes
    } = this.props;
    let data = getData(datasourceReading);

    // Filter for valid dataSources
    if (Array.isArray(data)) {
      data = data.filter((it) => it.dataSource && it.readings);
    }

    // Check if valid data is returned
    if (!data || data.length === 0 || Array.isArray(data[0])) {
      return "No TM data";
    }

    // TODO: Review this step
    // Check if provided dataType and categories are allowed
    // Disabled throw to avoid dashboard configuration issues
    (Array.isArray(data) ? data : [data]).forEach((it) => {
      if (
        it.dataSource.tmDataType &&
        !allowedDataTypes.includes(it.dataSource.tmDataType.type)
      ) {
        // eslint-disable-next-line no-console
        console.log(`DataType '${it.dataSource.tmDataType.type}' not allowed`);
        //throw Error(`DataType '${it.dataSource.tmDataType}' not allowed`);
      }
    });

    return <Fragment>{children({ data })}</Fragment>;
  }
}

TelemetryBase.defaultProps = {
  minimumHorizontalSize: 1,
  minimumVerticalSize: 1,
  allowedDataTypes: [],
  getData: (data) => data || null
};

export const TelemetryBaseComponent = TelemetryBase;

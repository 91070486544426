import React, { useState, useEffect } from "react";
import { themeGet } from "styled-system";
import styled from "styled-components";
import moment from "moment";
import { Box, Flex, Text, Icon, Image } from "primitives";
import { DateTimeLabel, Popover } from "components";
import { ConstellationSatelliteSelector } from "app/constellation";
import { theme } from "styles";
import { Logout } from "app/auth";
import { store } from "app/store";
import { AlertCounter } from "app/alert";
import { PassagesSelectorContainer } from "app/visibilityWindow";
import config from "config/constants";

const NavbarItem = styled(Flex)``;
NavbarItem.defaultProps = {
  alignItems: "center",
  borderLeft: 2,
  borderRight: 0,
  borderColor: "palette.brand.0",
  px: 2
};

const ProfileDropdown = (
  <Flex
    py={1}
    flexDirection="column"
    bg="fill.0"
    color="text.default"
    minWidth="100px"
    fontSize={3}
    data-testid="ProfileDropdown"
  >
    <Box p={2}>
      <Logout data-testid="Logout">
        <Text as="div" center caps>
          Logout
        </Text>
      </Logout>
    </Box>
  </Flex>
);

const Navbar = ({ showSideBar, toggleSideBar }: any) => {
  const [time, setTime] = useState(
    moment.utc().format("YYYY-MM-DD[T]HH:mm:ss")
  );

  const height = themeGet("sizes.navbarHeight")({ theme });

  useEffect(() => {
    const timer = setInterval(function() {
      setTime(moment.utc().format("YYYY-MM-DD[T]HH:mm:ss"));
    }, config.timer.clock);

    return () => clearInterval(timer);
  });

  return (
    <Flex
      height={height}
      color="text.default"
      alignItems="stretch"
      borderBottom={3}
      borderColor="border.1"
      bg="fill.3"
      pl={2}
      data-testid="Navbar"
    >
      <Flex flex="1 0 auto" alignItems="center">
        <Icon name="Logo" color="palette.white.0" />
        <Text fontSize={4} caps pl={2}>
          Mission control platform
        </Text>
      </Flex>
      <Flex display={{ xs: "none", lg: "flex" }}>
        <NavbarItem px={1} overflow="visible">
          <AlertCounter />
        </NavbarItem>
        <NavbarItem px={0} alignItems="stretch">
          <ConstellationSatelliteSelector />
        </NavbarItem>
        <NavbarItem minWidth="175px" justifyContent="center">
          <PassagesSelectorContainer />
        </NavbarItem>
        <NavbarItem>
          <DateTimeLabel
            value={new Date(moment.utc().format())}
            format="short"
            fontSize={4}
          />
          <Text px={1}>::</Text>
          <Flex width="70px" overflow="visible">
            <DateTimeLabel
              value={time}
              format="time"
              fontSize={7}
              color="palette.white.0"
            />
          </Flex>
          <Text pl={1} fontSize={7} caps>
            utc
          </Text>
        </NavbarItem>

        <Popover overlay={ProfileDropdown} placement="bottom-end">
          <NavbarItem>
            <Image size={20} src="/static/Aurora.png" />
            <Text pl={2} fontSize={2} color="palette.white.0" caps>
              {store.getState().keycloak.idTokenParsed.name}
            </Text>
            <Box ml={2} p={1} border={3} borderRadius="50%">
              <Icon name="ArrowDown" size={6} />
            </Box>
          </NavbarItem>
        </Popover>
      </Flex>
    </Flex>
  );
};

Navbar.displayName = "Navbar";

export default Navbar;

import { DataSource } from "app/dataSource/models";

export interface AlertCounterProps {
  getAlertcount: (satelliteId: number) => Promise<{}>;
  alertCounter: {
    numCritical: number;
    numWarnings: number;
    numOthers: number;
  };
  satellites: [
    {
      id: number;
      label: string;
    }
  ];
  refreshData: boolean;
}

export interface AlertCounterState {
  alertColor: string;
  alertNumber: number | string;
}

/**
 * Alert Response and DTO
 */

//Response
export interface AlertResponse {
  id: string;
  satelliteId: number;
  dataSourceId: number;
  dataSourceName: string;
  receiveTimestamp: string;
  sendTimestamp: string;
  bootCount: number;
  onboardTimeMillis: number;
  valueStatus: SimpleAlertValueStatusResponse | ArrayAlertValueStatusResponse;
  value: number | number[];
  dismissed: boolean;
}

export interface SimpleAlertValueStatusResponse {
  alertType: AlertType.SimpleAlert;
  triggeredParameters: [
    AlertValueResponse | OutOfBoundsResponse | NormalValueResponse
  ];
}

export interface ArrayAlertValueStatusResponse {
  alertType: AlertType.ArrayAlert;
  triggeredParameters: [
    AlertValueResponse | OutOfBoundsResponse | NormalValueResponse
  ];
}

export interface OutOfBoundsResponse {
  type: AlertValueStatus.OutOfBoundsValue;
  index: number | null;
  valueBoundary: ValueBoundaryResponse;
  alertLevel?: AlertLevelResponse;
}

export interface AlertValueResponse {
  type: AlertValueStatus.AlertValue;
  index: number | null;
  alertLevel: AlertLevelResponse;
  valueBoundary?: ValueBoundaryResponse;
}

export interface NormalValueResponse {
  type: AlertValueStatus.NormalValue;
  index: number | null;
  alertLevel?: AlertLevelResponse;
  valueBoundary?: ValueBoundaryResponse;
}

export interface AlertLevelResponse {
  severity: Severity;
  upperThreshold: number;
  lowerThreshold: number;
}

export interface ValueBoundaryResponse {
  maxValue: number;
  minValue: number;
}

//DTO

export interface AlertDTO {
  id: string;
  satelliteId: number;
  dataSourceId: number;
  dataSourceName: string;
  receiveTimestamp: string;
  sendTimestamp: string;
  bootCount: number;
  onboardTimeMillis: number;
  value: AlertValue[];
  currentValue: number | number[];
  dismissed: boolean;
}

export interface AlertValue {
  index: number | null;
  alert: AlertDetails;
}

export interface AlertDetails {
  index: number | null;
  type: AlertValueStatus;
  severity?: Severity;
  upperThreshold?: number;
  lowerThreshold?: number;
  maxValue?: number;
  minValue?: number;
}

export interface GetAlertsResponse {
  data: AlertResponse[] | AlertDTO[] | null;
  total: number;
  nextPageUrl: string | null;
  previousPageUrl: string | null;
}

export interface GetAlertsDataSourceListResponse {
  data: AlertsDataSourceListResponse[];
  total: number;
  nextPageUrl: string | null;
  previousPageUrl: string | null;
}

export interface AlertsDataSourceListResponse {
  alert: AlertDTO;
  datasource: DataSource;
}

/*******/

export interface AlertState {
  loading: boolean;
  error: { msg: string; status: boolean };
  alerts: AlertDTO[];
}

export interface SelectedAlert {
  id: string;
  dataSourceId: number;
  receiveTimestamp: string;
}

export enum AlertType {
  SimpleAlert = "SimpleAlert",
  ArrayAlert = "ArrayAlert",
  NormalValue = "NormalValue"
}

export enum AlertValueStatus {
  AlertValue = "AlertValue",
  OutOfBoundsValue = "OutOfBoundsValue",
  NormalValue = "NormalValue"
}

export enum Severity {
  Normal = "Normal",
  Warning = "Warning",
  Critical = "Critical"
}

export enum Colors {
  Default = "text.default",
  Normal = "text.success",
  Warning = "text.warning",
  Critical = "text.danger",
  OutOfBounds = "palette.purple.1",
  Black = "palette.black.0"
}

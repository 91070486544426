import {
  SET_REFRESH_DATA_TRUE,
  SET_REFRESH_DATA_FALSE,
  SET_FEEDBACK,
  CLEAR_FEEDBACK
} from "../models/constants";
import { FeedbackStatus } from "../models";

/*
 * Refresh Data
 */

export const setRefreshData = () => ({
  type: SET_REFRESH_DATA_TRUE
});

export const unsetRefreshData = () => ({
  type: SET_REFRESH_DATA_FALSE
});

/*
 * Feedback
 */

let clearFeedbackTimeout: any = null;
const CLEAR_FEEDBACK_TIMEOUT = 6000;

export const setFeedback = (
  title: string,
  status: FeedbackStatus,
  details: string = ""
) => (dispatch: any) => {
  if (clearFeedbackTimeout) {
    clearTimeout(clearFeedbackTimeout);
    clearFeedbackTimeout = null;
  }
  clearFeedbackTimeout = setTimeout(() => {
    dispatch(clearFeedback());
  }, CLEAR_FEEDBACK_TIMEOUT);
  const setFeedbackAction = {
    type: SET_FEEDBACK,
    payload: {
      title,
      status,
      details
    }
  };
  return dispatch(setFeedbackAction);
};

export const clearFeedback = () => (dispatch: any) => {
  clearTimeout(clearFeedbackTimeout);
  clearFeedbackTimeout = null;
  return dispatch({ type: CLEAR_FEEDBACK });
};

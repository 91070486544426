import React, { Component, Fragment } from "react";
import { constants } from "config";

/**
 * DataSourceReading component
 * Responsible for get reading for a given data source repeatedly
 * TODO: split the component
 */
export class DataSourceReading extends Component {
  constructor(props) {
    super(props);

    this.timeout = null;
    this.willUnmount = false;
    this.state = {
      datasourceReading: null,
      nextPageUrl: null,
      previousPageUrl: null
    };
  }

  /** If no timeout for any reason when component update,
   * the component start to call the api again */
  componentDidUpdate() {
    const { refreshData } = this.props;
    if (!this.timeout && refreshData) {
      this.startDatasourceReading();
    }
  }

  componentDidMount() {
    this.startDatasourceReading();
  }

  componentWillUnmount() {
    this.clean();
    this.willUnmount = true;
  }

  componentWillUpdate(nextProps) {
    const { refreshData } = this.props;
    if (refreshData !== nextProps.refreshData && !nextProps.refreshData) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (
      refreshData !== nextProps.refreshData &&
      nextProps.refreshData &&
      !this.timeout
    ) {
      this.startDatasourceReading();
    }
  }

  /**
   * @returns {boolean}
   * true if has satellite and valid ids
   */
  hasValidIdsParams() {
    const { satellite, ids } = this.props;
    return satellite && ids && ids.every(Boolean);
  }

  clean() {
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  componentDidCatch() {
    this.clean();
  }

  /**
   * Start reading by system or ids individually
   */
  startDatasourceReading() {
    if (!this.hasValidIdsParams()) return;

    if (this.props.multiple) {
      this.getReadingMultiple(0);
      return;
    }

    this.getReadingByIds(0);
  }

  /**
   *
   * @param {number} initialInterval
   * The first call has initialInterval = 0
   * the next ones have a periodic interval
   */
  getReadingByIds(initialInterval) {
    const {
      satellite,
      ids,
      interval,
      params,
      autoUpdates,
      options
    } = this.props;
    this.timeout = setTimeout(() => {
      this.props
        .getReadingsByDataSources(satellite, ids, params || {}, options)
        .then((datasourceReading) => {
          if (datasourceReading) {
            if (!this.willUnmount && autoUpdates) {
              this.setState({ datasourceReading });
            }
          }
        })
        .catch((error) => {
          this.setState({ error });
          this.clean();
        })
        .finally(() => {
          if (!this.willUnmount && autoUpdates) {
            this.getReadingByIds(
              interval || constants.timer.defaultDataSourceReading
            );
          } else {
            this.clean();
          }
        });
    }, initialInterval);
  }

  /**
   *
   * @param {number} initialInterval
   * The first call has initialInterval = 0
   * the next ones have a periodic interval
   */
  getReadingMultiple(initialInterval) {
    const { satellite, ids, interval, params, options } = this.props;
    const { paginationUrl, ...restParams } = params;

    const filter = {
      ...restParams,
      datasources: ids.join(",")
    };

    this.timeout = setTimeout(() => {
      this.props
        .getReadingsMultipleDataSources(
          satellite,
          {
            filter,
            paginationUrl
          },
          options
        )
        .then((response) => {
          const { data, nextPageUrl, previousPageUrl } = response;
          if (!this.willUnmount && data) {
            this.setState({
              datasourceReading: data,
              nextPageUrl,
              previousPageUrl
            });
          }
        })
        .catch((error) => {
          this.setState({ error });
          this.clean();
        })
        .finally(() => {
          if (
            !this.willUnmount &&
            (!params.from || !params.to) &&
            this.props.autoUpdates
          ) {
            this.getReadingMultiple(
              interval || constants.timer.defaultDataSourceReading
            );
          } else {
            this.clean();
          }
        });
    }, initialInterval);
  }

  render() {
    const { children } = this.props;
    const { datasourceReading, nextPageUrl, previousPageUrl } = this.state;
    if (!children) return null;

    return (
      <Fragment>
        {children({ datasourceReading, nextPageUrl, previousPageUrl })}
      </Fragment>
    );
  }
}

DataSourceReading.defaultProps = {
  autoUpdates: true
};

import React from "react";
import { Button, Icon, Text } from "primitives";
import { ButtonProps } from "primitives/Button";
import { DATA_TEST_ID_ATTR_NAME } from "components/testable/models";

const AddButton = ({ children, ...props }: ButtonProps) => (
  <Button size="small" variant="outline" {...props}>
    <Icon name="Add" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

AddButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "AddButton"
};

const EditButton = ({ children, ...props }: ButtonProps) => (
  <Button size="small" variant="outline" {...props}>
    <Icon name="Edit" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

EditButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "EditButton"
};

const SaveButton = ({ children, ...props }: ButtonProps) => (
  <Button size="small" variant="default" {...props}>
    <Icon name="Save" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

SaveButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "SaveButton"
};

const DeleteButton = ({ children, ...props }: ButtonProps) => (
  <Button size="small" variant="danger" {...props}>
    <Icon name="Delete" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

DeleteButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "DeleteButton"
};

const CancelButton = ({ children, ...props }: ButtonProps) => (
  <Button size="small" variant="cancel" {...props}>
    <Text>{children}</Text>
  </Button>
);

CancelButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "CancelButton"
};

const CopyButton = ({ children, ...props }: ButtonProps) => (
  <Button
    size="small"
    variant="default"
    hoverBackgroundColor={props.backgroundColor}
    {...props}
  >
    <Icon name="Copy" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

CopyButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "CopyButton"
};

const PasteButton = ({ children, ...props }: ButtonProps) => (
  <Button
    size="small"
    variant="default"
    hoverBackgroundColor={props.backgroundColor}
    {...props}
  >
    <Icon name="Paste" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

PasteButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "PasteButton"
};

export {
  AddButton,
  EditButton,
  SaveButton,
  DeleteButton,
  CancelButton,
  CopyButton,
  PasteButton
};

import React from "react";
import { withStyles, Select } from "@material-ui/core";
import { CONST_KIND } from "app/telecommand/models";

const styles = () => ({
  formControl: {
    width: "100%",
    margin: `5px 0`
  }
});

const SelectBase = ({
  id,
  value,
  readonly,
  autofocus,
  disabled,
  required,
  onBlur,
  onFocus,
  onChange,
  placeholder,
  classes,
  options,
  rawErrors,
  schema
}) => {
  const { enumOptions } = options;
  let kind = null;

  if (schema) {
    kind = schema.kind;
  }

  const _onChange = (selectValue) => {
    return onChange(selectValue === "" ? options.emptyValue : selectValue);
  };

  return (
    <Select
      native
      inputProps={{
        id,
        "data-testid": id
      }}
      value={typeof value === "undefined" || value === null ? "" : value}
      required={required}
      disabled={disabled || readonly || (kind && kind === CONST_KIND)}
      autoFocus={autofocus}
      onBlur={
        onBlur &&
        ((event) => {
          onBlur(id, event.target.value);
        })
      }
      onFocus={
        onFocus &&
        ((event) => {
          onFocus(id, event.target.value);
        })
      }
      onChange={(target) => {
        const {
          target: { value: fieldValue }
        } = target;
        _onChange(fieldValue);
      }}
    >
      <option value="">{placeholder}</option>
      {enumOptions.map(({ value: option, label }, i) => {
        return (
          <option key={i} value={option}>
            {label}
          </option>
        );
      })}
    </Select>
  );
};

export const SelectBox = withStyles(styles)(SelectBase);

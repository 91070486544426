import React from "react";
import { Button, Flex } from "primitives";
import { AbstractSeries } from "react-vis";
import { configOptionsGetTimeReferenceValue } from "app/telemetry/utils/dashboard";
import styled, { createGlobalStyle } from "styled-components";
import moment from "moment";
import {
  DataSourceReadingDTO,
  ReadingDTO,
  ValueDTO,
  GraphDataSourceReading,
  GraphReading,
  ScalableValues,
  FormatedData
} from "../../models";
import { AlertValueStatus } from "app/alert/models";

export const colors = [
  "#FF8755",
  "#46C3A5",
  "#46B4DC",
  "#FFD067",
  "#EB6EC3",
  "#834AAE",
  "#5DC8C9"
];
const gridStrokeColor = "#6E8091";

export const GraphDataViewGlobalStyles = createGlobalStyle`
.rv-xy-plot {
  user-select: none;
}


.rv-xy-plot__axis.rv-xy-plot__axis--vertical {
  transform: translate(-25px,10px);
}

.rv-xy-plot__axis__tick__text {
  color: #8091A5;
  fill: #7AB7D9;
  border-radius: 2px;
}

.rv-xy-plot__axis__ticks .rv-xy-plot__axis__tick:first-child {
  display: none;
}

.rv-xy-plot__axis--horizontal .rv-xy-plot__axis__tick:last-child {
  display: none;
}

.rv-discrete-color-legend-item__title {
  font-size: 8px;
  letter-spacing: 0.7px;
  color: #6E8091 !important;
  font-weight: normal !important;
  text-transform: uppercase;
}

.rv-discrete-color-legend-item__color__path {
  stroke-width: 4px !important;
}

.rv-discrete-color-legend-item.vertical {
  padding: 2px 10px;
}

`;

export const gridLinesStyles = {
  stroke: gridStrokeColor,
  strokeWidth: 1,
  strokeDasharray: "1 5",
  opacity: 0.6
};

export const lineSeriesStyles = (color: string, selected: boolean) => ({
  fill: "none",
  stroke: color,
  strokeWidth: 1,
  opacity: selected ? 1 : 0.2
});

export const markSeriesStyles = (color: string, selected: boolean) => ({
  fill: color,
  stroke: "transparent",
  strokeWidth: 10,
  opacity: selected ? 1 : 0.2
});

// value to increase the x position of each yAxis
export const yAxisFactor = 30;

export const yAxisStyles = (index: number, selected: boolean) => ({
  line: {
    transform: `translate(${yAxisFactor * index}px, 10px)`,
    stroke: colors[index],
    strokeWidth: 1
  },
  opacity: selected ? 1 : 0.6
});

export const xAxisStyles = (color: string = gridStrokeColor) => ({
  line: {
    stroke: color,
    strokeWidth: 1
  }
});

export const GraphButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.palette.grey[4]};
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.palette.grey[3]};
  justify-content: center;
`;

GraphButton.defaultProps = {
  width: "30px",
  height: "30px",
  p: 0
};

export const YAxisLabel = styled(Flex)``;
YAxisLabel.defaultProps = {
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 1,
  bg: "fill.2"
};

export const XAxisLabel = styled(Flex)``;
XAxisLabel.defaultProps = {
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  color: "palette.blue.1",
  fontSize: 2,
  bg: "palette.brand.0",
  bgOpacity: 1,
  borderRadius: "2px",
  py: 1,
  px: 2
};

/**
 * Method to position the yAxis side by side
 */
export const yAxisFormatter = (index: number, scalableValues: any) => (
  value: number
) => {
  const { scale, maxValues, minValues } = scalableValues;
  const scaleValue = scale
    .domain([minValues[index], maxValues[index]])
    .invert(value);
  return (
    <>
      <foreignObject
        width="26"
        height="20"
        transform={`translate(${-13 + yAxisFactor * index}, -14)`}
      >
        <YAxisLabel color={colors[index]}>
          {scaleValue.toExponential(1)}
        </YAxisLabel>
      </foreignObject>
    </>
  );
};

export const xAxisFormatter = (value: number | string) => {
  return (
    <>
      <foreignObject
        width="25"
        height="20"
        style={{ transform: "translate(-10px, 14px)" }}
      >
        <XAxisLabel>{Number(value) / 1000}s</XAxisLabel>
      </foreignObject>
    </>
  );
};

export class Actions extends AbstractSeries<any> {
  render() {
    return (
      <g>
        <foreignObject width="100%" height="30">
          <Flex
            height="100%"
            alignItems="flex-start"
            justifyContent="flex-end"
            mx={2}
            overflow={"visible"}
          >
            <GraphButton onClick={this.props.reset} mb={2} width="auto">
              Reset Zoom
            </GraphButton>
          </Flex>
        </foreignObject>
      </g>
    );
  }
}

export const graphFormatData = (
  start: Date,
  telemetryData: DataSourceReadingDTO[],
  scalableValues: ScalableValues,
  options: any
): [FormatedData[], ScalableValues] => {
  const { datasourceLabels, dataSources } = options;
  const newScalableValues = { ...scalableValues };
  //Flatten Telemetry Data
  const flatTelemetryData: GraphDataSourceReading[] = flattenTelemetryData(
    telemetryData
  );

  //Filter out and reorder array datasources indexes
  let reorderedTelemetryData: any = [];
  const alreadyOrderedDataSources: number[] = [];
  if (dataSources) {
    flatTelemetryData.forEach((data: GraphDataSourceReading, index: number) => {
      if (dataSources && data.dataSource.id && data.readings.length > 0) {
        if (alreadyOrderedDataSources.indexOf(data.dataSource.id) === -1) {
          alreadyOrderedDataSources.push(data.dataSource.id);
          const dataSource: any = dataSources.find(
            (ds: any) => ds.id === data.dataSource.id
          );
          if (dataSource && dataSource.indexes) {
            dataSource.indexes.forEach((dataSourceIndex: any) => {
              flatTelemetryData.forEach((tD: GraphDataSourceReading) => {
                const readingIndex: number | null | undefined =
                  tD.dataSource.index;
                if (
                  data.dataSource.id === tD.dataSource.id &&
                  readingIndex &&
                  !isNaN(readingIndex) &&
                  readingIndex === dataSourceIndex.index
                ) {
                  reorderedTelemetryData.push(tD);
                }
              });
            });
          } else {
            //If datasource indexes config options === null
            reorderedTelemetryData.push(data);
            alreadyOrderedDataSources.pop();
          }
        }
      } else {
        reorderedTelemetryData.push(data);
      }
    });
  } else {
    reorderedTelemetryData = flatTelemetryData;
  }

  const formatedDataList = reorderedTelemetryData
    .map((data: GraphDataSourceReading, index: number) => {
      // max and min will be used to set the scale domain
      const max =
        data.readings.length === 0
          ? 0
          : Math.max(
              ...data.readings.map((reading: GraphReading) =>
                Number(reading.value.value)
              )
            );

      const min =
        data.readings.length === 0
          ? 0
          : Math.min(
              ...data.readings.map((reading: GraphReading) =>
                Number(reading.value.value)
              ),
              0
            );

      // the domain is defined by data series
      newScalableValues.maxValues[index] =
        max > (newScalableValues.maxValues[index] || Number.MIN_SAFE_INTEGER)
          ? max
          : newScalableValues.maxValues[index];

      newScalableValues.minValues[index] =
        min < (newScalableValues.minValues[index] || Number.MAX_SAFE_INTEGER)
          ? min
          : newScalableValues.minValues[index];

      //Ensure Sorted Data Readings
      //Fixes strange lines problem
      data.readings = data.readings.sort((a: any, b: any) => {
        if (
          configOptionsGetTimeReferenceValue(options, a) <
          configOptionsGetTimeReferenceValue(options, b)
        )
          return -1;
        if (
          configOptionsGetTimeReferenceValue(options, a) >
          configOptionsGetTimeReferenceValue(options, b)
        )
          return 1;
        return 0;
      });

      // the x with the seconds information has as reference the moment
      // when the operator opens the graph.
      const readings = data.readings.map((reading: GraphReading) => {
        return {
          x:
            new Date(
              configOptionsGetTimeReferenceValue(options, reading)
            ).getTime() - start.getTime(),
          y: newScalableValues.scale.domain([min, max])(
            Number(reading.value.value)
          ),
          originalX: moment(
            configOptionsGetTimeReferenceValue(options, reading)
          ).format("YYYY-MM-DD HH:mm:ss.SSS"),
          originalY: reading.value.value,
          alert: getAlertFromReading(reading)
        };
      });

      if (datasourceLabels && datasourceLabels[data.dataSource.id]) {
        const arrayInitialCharacter = data.dataSource.name.indexOf("[");
        const datasourceName =
          arrayInitialCharacter === -1
            ? data.dataSource.name
            : data.dataSource.name.substring(0, arrayInitialCharacter);
        data.dataSource.name = data.dataSource.name.replace(
          datasourceName,
          datasourceLabels[data.dataSource.id]
        );
      }
      return {
        dataSource: data.dataSource,
        readings
      };
    })
    .filter((formatedData: any) => formatedData !== null);

  return [formatedDataList, newScalableValues];
};

const getAlertFromReading = (reading: GraphReading) => {
  let alert = null;
  if (reading.value) {
    alert =
      reading.value.alert &&
      reading.value.alert.type !== AlertValueStatus.NormalValue
        ? reading.value.alert
        : null;
  }
  return alert;
};

const flattenTelemetryData = (
  telemetryData: DataSourceReadingDTO[]
): GraphDataSourceReading[] => {
  const flatTelemetryData: GraphDataSourceReading[] = [];

  telemetryData.forEach((tD: DataSourceReadingDTO) => {
    let dataSourceReading: GraphDataSourceReading = {
      dataSource: Object.assign({}, tD.dataSource),
      readings: []
    };
    const dataSourceName = tD.dataSource.name;
    if (tD.readings && tD.readings.length > 0) {
      const isArray = tD.readings[0].valueIsArray;
      if (!isArray) {
        dataSourceReading.dataSource.index = null;
        tD.readings.forEach((reading: ReadingDTO) => {
          const flatReading: GraphReading = {
            ...reading,
            value: reading.value[0]
          };
          dataSourceReading.readings.push(flatReading);
        });
        flatTelemetryData.push(dataSourceReading);
      } else {
        const numberOfIndexes = tD.readings[0].value.length;
        Array.from(Array(numberOfIndexes)).forEach(
          (indexNumber: number, index: number) => {
            dataSourceReading = {
              dataSource: Object.assign({}, tD.dataSource),
              readings: []
            };
            dataSourceReading.dataSource.index = index;
            dataSourceReading.dataSource.name = `${dataSourceName}[${index}]`;
            dataSourceReading.readings = [];
            tD.readings.forEach((reading: ReadingDTO) => {
              const indexReading = reading.value.find(
                (value: ValueDTO) => value.index === index
              );
              if (indexReading) {
                const flatReading: GraphReading = {
                  ...reading,
                  value: indexReading
                };
                dataSourceReading.readings.push(flatReading);
              }
            });
            flatTelemetryData.push(dataSourceReading);
          }
        );
      }
    } else {
      flatTelemetryData.push(dataSourceReading);
    }
  });

  return flatTelemetryData;
};

import { connect } from "react-redux";
import { SatelliteDefinitionCreate } from "../components/SatelliteDefinitionCreate";
import { withRouter } from "react-router-dom";

export const SatelliteDefinitionCreateContainer = withRouter(
  connect(
    null,
    null
  )(SatelliteDefinitionCreate)
);

import { connect } from "react-redux";
import { DashboardList } from "../components/DashboardList";
import {
  getUserDashboardsLists,
  updateUserDashboardsLists,
  addDashboards,
  getUserDashboards
} from "../services";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state: any) => ({
  constellationSelected: state.constellations.selected,
  selectedSatellite: state.constellations.dashboard.find(
    (satellite: any) => satellite.visible
  )
});

const mapDispatchToProps = () => {
  return {
    getUserDashboardsLists: (satelliteDefinitionId: number | string | null) =>
      getUserDashboardsLists(satelliteDefinitionId),
    updateUserDashboardsLists: (
      satelliteDefinitionId: number | string | null,
      data: any
    ) => updateUserDashboardsLists(satelliteDefinitionId, data),
    getUserDashboards: (satelliteDefinitionIds: any) =>
      getUserDashboards(satelliteDefinitionIds),
    addDashboards: (dashboards: any) => addDashboards(dashboards)
  };
};

export const DashboardListContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DashboardList)
);

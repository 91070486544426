import React, { Component, MouseEvent } from "react";
import Dropzone from "react-dropzone";
import { withStyles, createStyles, Theme } from "@material-ui/core";
import { Box, Flex, Text, Button, Grid } from "primitives";

const styles = ({ spacing }: Theme) =>
  createStyles({
    dropzone: {
      margin: "10px"
    }
  });

interface TelecommandUploadProps<T extends File> {
  classes: any;
  uploadTelecommand: (file: T) => void;
}

interface TelecommandUploadState<T extends File> {
  file: T | null;
  loading: boolean;
}

class BaseTelecommandFileUpload extends Component<
  TelecommandUploadProps<any>,
  TelecommandUploadState<any>
> {
  constructor(props: TelecommandUploadProps<any>) {
    super(props);
    this.state = {
      file: null,
      loading: false
    };
  }

  render() {
    const { classes } = this.props;
    const { file, loading } = this.state;
    return (
      <Grid className={classes.dropzone} data-tesid="BaseTelecommandFileUpload">
        <Dropzone
          onDrop={(files) => this.onDropAccepted(files)}
          accept="application/json"
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              <div {...getRootProps()}>
                <Box mb={2} p={3} bg="fill.0" border={1}>
                  <input {...getInputProps()} />
                  <p>Drop a file to upload, or click to select it.</p>
                </Box>
              </div>
              {file && (
                <Flex
                  p={2}
                  my={2}
                  bg="fill.0"
                  key={file.path}
                  alignItems="center"
                >
                  <Text pr={2}>
                    {file.path} - {file.size} bytes
                  </Text>
                  <Button onClick={() => this.clearFile()}>Remove</Button>
                </Flex>
              )}
            </>
          )}
        </Dropzone>

        <Button
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            this.onUploadHander(e);
          }}
          disabled={loading || !file}
        >
          Upload
        </Button>
      </Grid>
    );
  }

  private onDropAccepted<T extends File>(files: T[]) {
    this.setState({ file: files[0] });
  }

  private clearFile() {
    this.setState({ file: null });
  }

  private onUploadHander(e: MouseEvent<HTMLButtonElement>) {
    const { uploadTelecommand } = this.props;
    const { file } = this.state;
    if (file) {
      try {
        this.setState({ loading: true }, () => {
          uploadTelecommand(file);
          this.clearFile();
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      this.setState({ loading: false });
      e.preventDefault();
    }
  }
}

export const TelecommandFileUpload = withStyles(styles)(
  BaseTelecommandFileUpload
);

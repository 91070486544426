import { Passage, NextPassageDuration } from "../models";
import moment from "moment";
import "moment-duration-format";

const getNextPassageRemainingTime = (passage: Passage): NextPassageDuration => {
  const now = moment();
  const beginPassage = moment(passage.aos);
  const duration = moment.duration(beginPassage.diff(now));
  const durationMinutes = duration.asMinutes();
  const formatedDuration = moment
    .duration(durationMinutes, "minutes")
    .format("D[d]HH[h]mm[m]ss[s]");
  return {
    durationMinutes: durationMinutes || 0,
    formatedDuration
  };
};

const getNextPassageDuration = (passage: Passage): NextPassageDuration => {
  const beginPassage = moment(passage.aos);
  const endPassage = moment(passage.los);
  const duration = endPassage.valueOf() - beginPassage.valueOf();
  const s = Math.floor((duration / 1000) % 60);
  const m = Math.floor((duration / 1000 / 60) % 60);
  const h = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const d = Math.floor(duration / (1000 * 60 * 60 * 24));
  return {
    durationMinutes: m || 0,
    formatedDuration: `${d > 0 ? `${d}d` : ``}${h > 0 ? `${h}h` : ``}${
      m > 0 ? `${m}m` : ``
    }${s > 0 ? `${s}s` : ``}`
  };
};

const convertPassagesToTimelineFormat = (passages: Passage[]) => {
  const formatedData = {
    groups: [] as any,
    items: [] as any
  };
  const groups = passages.map(({ groundStationID, groundStationName }) => {
    return {
      id: groundStationID,
      title: groundStationName
    };
  });
  const uniq = {} as any;
  formatedData.groups = groups.filter(
    (obj) => !uniq[obj.id] && (uniq[obj.id] = true)
  );
  formatedData.items = passages.map(
    ({ passageID, groundStationID, aos, los }) => {
      return {
        id: passageID,
        group: groundStationID,
        title: ``,
        // eslint-disable-next-line camelcase
        start_time: new Date(aos),
        // eslint-disable-next-line camelcase
        end_time: new Date(los),
        canMove: false,
        canResize: false,
        canChangeGroup: false,
        itemProps: {
          style: {
            background: "#7AB9DB",
            borderColor: "#5DC8C9"
          }
        }
      };
    }
  );
  return formatedData;
};

export {
  getNextPassageRemainingTime,
  getNextPassageDuration,
  convertPassagesToTimelineFormat
};

import React from "react";
import { FormLabel, withStyles } from "@material-ui/core";
import { Button, Text } from "primitives";
import { HourglassEmpty } from "@material-ui/icons";

const styles = () => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  error: {
    padding: "5px 0 10px 0"
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)"
    },
    "100%": {
      transform: "rotate(360deg)"
    }
  },
  loading: {
    animation: "spin 2s linear infinite"
  }
});

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.pollingIntervalMs = 1000;
    this.state = { loading: false, error: null };
    this.getFile = this.getFile.bind(this);
    this.jobPoller = this.jobPoller.bind(this);
  }

  componentWillUnmount() {
    this.stopPolling();
  }

  render() {
    const { error, loading } = this.state;
    const { classes, title } = this.props;
    return (
      <div className={classes.root}>
        {loading && <HourglassEmpty className={classes.loading} />}
        <div>
          <Button
            variant="outline"
            color="primary"
            bg="fill.0"
            onClick={this.getFile}
            disabled={loading}
            data-testid="DownloadButton"
          >
            <Text color="text.default">{title}</Text>
          </Button>
          <br />
          {Boolean(error) && (
            <FormLabel error={true} className={classes.error}>
              {error}
            </FormLabel>
          )}
        </div>
      </div>
    );
  }

  getFile() {
    const {
      createExportRequest,
      params,
      fileContent,
      fileExtension,
      fileName
    } = this.props;

    this.setState({ loading: true, error: null });
    if (createExportRequest) {
      createExportRequest(params)
        .then(({ data }) => {
          this.timeout = setInterval(
            () => this.jobPoller(data.id),
            this.pollingIntervalMs
          );
        })
        .catch((e) => {
          console.error("Cannot get data export", e);
          this.stopPolling("Failed to export");
        });
    } else {
      this.generateFile(fileContent, fileExtension, fileName);
    }
  }

  generateFile(fileContent, fileExtension, fileName) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(fileContent)
    );
    element.setAttribute(
      "download",
      `${fileName}-${new Date()}.${fileExtension}`
    );

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    this.setState({ loading: false });
  }

  jobPoller(id) {
    const { getExportRequest, params } = this.props;
    getExportRequest({ satelliteId: params.satelliteId, requestId: id })
      .then(({ data }) => {
        if (data.status === "Completed") {
          this.stopPolling();
          this.download(data.url);
        } else if (data.status === "Failed") {
          this.stopPolling(data.failureReason);
        }
      })
      .catch((e) => {
        console.error("Cannot get data export", e);
        this.stopPolling("Failed to export");
      });
  }

  stopPolling(err) {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
    if (err) {
      this.setState({ loading: false, error: err });
    } else {
      this.setState({ loading: false });
    }
  }

  download(url) {
    const fakeLink = document.createElement("a");
    fakeLink.style.display = "none";
    document.body.appendChild(fakeLink);
    fakeLink.setAttribute("href", url);
    fakeLink.click();
  }
}

export const DownloadFile = withStyles(styles)(Download);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Passage, NextPassageDuration } from "../models";
import {
  fetchPassagesAction,
  selectPassageAction,
  fetchNextPassagesAction
} from "../actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getActiveSatellite } from "app/shared/utils";
import { SelectContainer, SelectLabel, Select } from "components";
import { SatelliteInstance } from "app/satellite/models";
import { Text } from "primitives";
import moment from "moment";
import { getNextPassageRemainingTime } from "../helpers";
import config from "config/constants";

interface PassagesSelectorProps {
  passages: Passage[];
  nextPassages: Passage[];
  selectedPassage: Passage;
  selectedSatellite: SatelliteInstance;
  fetchPassages: (satelliteID: number) => Promise<Passage[]>;
  fetchNextPassages: (satelliteID: number) => Promise<Passage[]>;
  selectPassage: (passage: Passage | null) => void;
}

export const PassagesSelector = ({
  fetchPassages,
  fetchNextPassages,
  selectPassage,
  passages,
  nextPassages,
  selectedPassage,
  selectedSatellite
}: PassagesSelectorProps) => {
  const onHandleChange = (value: string) => {
    const currentPassage = passages.filter((passage: Passage) => {
      return passage.passageID === parseInt(value, 10);
    })[0];

    selectPassage(currentPassage);
  };

  // Force component re-draw to force updating time left
  const [tick, setTick] = useState(0);
  const [fetchPassagesInterval, setFetchPassagesInterval] = useState(
    config.timer.passagesFetch
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTick(new Date().getTime());
      setFetchPassagesInterval(fetchPassagesInterval + 1000);
    }, config.timer.passages);

    return () => clearInterval(timer);
  });

  // Request current / next passages
  useEffect(() => {
    //If current time is greater than any of the current available passages or smaller than any of the next passages, passage data should be fetch and automatic selected passage changes should occur
    let forceFetch = false;
    if (
      selectedPassage &&
      moment(selectedPassage.los).diff(moment(), "seconds") < 0
    ) {
      forceFetch = true;
    }
    nextPassages.forEach((nextPassage: Passage) => {
      if (moment(nextPassage.aos).diff(moment(), "seconds") < 5) {
        forceFetch = true;
      }
    });
    // Check if should fetch passages data
    if (
      selectedSatellite &&
      selectedSatellite.id &&
      (fetchPassagesInterval >= config.timer.passagesFetch ||
        (forceFetch && fetchPassagesInterval >= 1000))
    ) {
      setFetchPassagesInterval(0);
      fetchPassages(selectedSatellite.id).then((result: Passage[]) => {
        if (selectedPassage) {
          //Check if selected passage exists in current passages list
          if (
            (result &&
              result.length &&
              !result.some(
                (passage) => passage.passageID === selectedPassage.passageID
              )) ||
            !result ||
            result.length === 0
          ) {
            //De-select Passage
            selectPassage(null);
          }
        }
        if (!selectedPassage && result && result.length) {
          selectPassage(result[0]);
        }
        fetchNextPassages(selectedSatellite.id);
      });
    }
  }, [
    selectedSatellite,
    selectedPassage,
    selectPassage,
    fetchNextPassages,
    fetchPassages,
    passages,
    nextPassages,
    tick,
    fetchPassagesInterval
  ]);

  useEffect(() => {
    setFetchPassagesInterval(config.timer.passagesFetch);
  }, [selectedSatellite]);

  let nextPassageOptions = null;
  if (nextPassages && nextPassages.length) {
    nextPassageOptions = nextPassages.map((nextPassage, index) => {
      const nextPassageDuration: NextPassageDuration = getNextPassageRemainingTime(
        nextPassage
      );
      return (
        <option key={index} value={nextPassage.passageID} disabled={true}>
          {nextPassage.groundStationName} | Next passage in:{" "}
          {nextPassageDuration &&
            nextPassageDuration.durationMinutes > 0 &&
            nextPassageDuration.formatedDuration}
        </option>
      );
    });
  }

  if (passages && passages.length) {
    return (
      <SelectContainer bg="transparent">
        <SelectLabel htmlFor="satellite">Current passages:</SelectLabel>
        <Select
          id="passages"
          value={
            selectedPassage && selectedPassage.passageID
              ? selectedPassage.passageID
              : undefined
          }
          onChange={(event) => onHandleChange(event.target.value)}
          color="text.success"
        >
          {passages.map((passage: Passage) => {
            const format = "YYYY-MM-DD HH:mm:ss";
            return (
              <option key={passage.passageID} value={passage.passageID}>
                {passage.groundStationName} | AOS:{" "}
                {moment.utc(passage.aos).format(format)} | LOS:{" "}
                {moment.utc(passage.los).format(format)}
              </option>
            );
          })}
          {nextPassageOptions}
        </Select>
      </SelectContainer>
    );
  } else if (nextPassages && nextPassages.length) {
    return (
      <SelectContainer bg="transparent">
        <SelectLabel htmlFor="satellite">Current passages:</SelectLabel>
        <Select
          id="passages"
          value={"none"}
          color="text.success"
          readOnly={true}
        >
          <option value={"none"} disabled={true}>
            No Current Passages
          </option>
          {nextPassageOptions}
        </Select>
      </SelectContainer>
    );
  } else {
    return <Text fontSize={4}>Without next passages</Text>;
  }
};

const mapStateToProps = (state: any) => ({
  passages: state.visibilityWindow.passages,
  nextPassages: state.visibilityWindow.nextPassages,
  selectedPassage: state.visibilityWindow.selectedPassage,
  selectedSatellite: getActiveSatellite(state.constellations.dashboard)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchPassages: (satelliteID: number) =>
    dispatch(fetchPassagesAction(satelliteID)),
  fetchNextPassages: (satelliteID: number) =>
    dispatch(fetchNextPassagesAction(satelliteID)),
  selectPassage: (passage: Passage | null) =>
    dispatch(selectPassageAction(passage))
});

export const PassagesSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PassagesSelector);

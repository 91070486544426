import { SET_FEEDBACK, CLEAR_FEEDBACK } from "../models/constants";
import { Feedback, FeedbackStatus } from "../models";

const initialState: Feedback = {
  title: "",
  details: "",
  status: FeedbackStatus.SUCCESS
};

const RefreshDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FEEDBACK:
      return { ...state, ...action.payload };
    case CLEAR_FEEDBACK:
      return initialState;
    default:
      return state;
  }
};

export default RefreshDataReducer;

import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseMutation } from "app/network";
import { SaveButton } from "components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { createProcedure } from "../services";
import { Procedure, CreateProcedureCommand } from "../models";
import { schemaCreate, uiSchemaCreate } from "../models/schemas";
import { ScriptListItem } from "app/scripting/models";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchScriptsAction } from "app/scripting/actions";

interface ProcedureCreateProps extends RouteComponentProps {
  createProcedure: (data: CreateProcedureCommand) => Promise<Procedure>;
  fetchScripts: () => Promise<ScriptListItem[]>;
  scripts: ScriptListItem[];
}

interface ProcedureCreateState {
  formData: CreateProcedureCommand | null;
}

export class ProcedureCreate extends Component<
  ProcedureCreateProps,
  ProcedureCreateState
> {
  state = {
    formData: null
  };

  onChange(form: ProcedureCreateState) {
    this.setState({ formData: form.formData });
  }

  async submit(
    e: React.FormEvent<HTMLInputElement>,
    formData: CreateProcedureCommand
  ) {
    e.preventDefault();

    await this.props.createProcedure(formData);
    this.props.history.push("/procedures");
  }

  componentDidMount() {
    this.props.fetchScripts();
  }

  render() {
    const { formData } = this.state;
    const { scripts } = this.props;

    return (
      <Box color="text.default" data-testid="ProcedureCreate">
        <Flex mb={2}>
          <Heading display={1}>Procedure</Heading>
        </Flex>
        <Flex flexDirection="column" bg="fill.0" p={3}>
          <SuspenseMutation>
            {({ loading, error, action }) => (
              <Form
                formData={formData}
                schema={schemaCreate(scripts || [])}
                uiSchema={uiSchemaCreate}
                disabled={loading}
                onSubmit={(
                  form: { formData: CreateProcedureCommand },
                  e: React.FormEvent<HTMLInputElement>
                ) => action(() => this.submit(e, form.formData))}
                onChange={(form: ProcedureCreateState) => this.onChange(form)}
              >
                <SaveButton type="submit" disabled={loading}>
                  Save
                </SaveButton>

                {error ? (
                  <Box my={2}>
                    <Text color="text.danger">Error...</Text>
                  </Box>
                ) : null}
              </Form>
            )}
          </SuspenseMutation>
        </Flex>
      </Box>
    );
  }
}

const mapStateToProps = (state: any) => ({
  scripts: state.scripts.scripts
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  createProcedure,
  fetchScripts: () => dispatch(fetchScriptsAction())
});

export const ProcedureCreateContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProcedureCreate)
);

import { connect } from "react-redux";
import {
  getReadingsByDataSources,
  getReadingsMultipleDataSources
} from "../services";
import { DataSourceReading } from "../components/DataSourceReading";

const mapStateToProps = (state) => ({
  refreshData: state.app.refreshData
});

const mapDispatchToProps = () => {
  return {
    getReadingsByDataSources: (satellite, ids, params, options) =>
      getReadingsByDataSources(satellite, ids, params, options),
    getReadingsMultipleDataSources: (satellite, ids, options) =>
      getReadingsMultipleDataSources(satellite, ids, options)
  };
};

export const DataSourceReadingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataSourceReading);

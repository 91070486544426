import React, { useState } from "react";
import { DATA_TEST_ID_ATTR_NAME } from "components/testable/models";
import { CheckBox } from "components";
import { Flex } from "primitives";

const MultiCheckBox = (props: any) => {
  const { schema, formData, onChange } = props;
  const [selectedValues, setSelectedValues] = useState(formData);

  let values: string[] = [];
  let labels: string[] = [];
  if (schema && schema.items && schema.items.enum) {
    values = schema.items.enum;
    labels = schema.items.enumNames || [];
  }

  const toggleCheckBox = (value: string) => {
    const index = selectedValues.indexOf(value);
    if (index === -1) {
      selectedValues.push(value);
      setSelectedValues([...selectedValues]);
      return selectedValues;
    } else {
      selectedValues.splice(index, 1);
      setSelectedValues([...selectedValues]);
      return selectedValues;
    }
  };

  return (
    <Flex flexDirection="column">
      {values.map((value: string, index: number) => (
        <CheckBox
          key={value}
          checked={selectedValues.indexOf(value) !== -1}
          label={labels[index] || value}
          onChange={() => onChange(toggleCheckBox(value))}
          labelWidth={"auto"}
        />
      ))}
    </Flex>
  );
};

MultiCheckBox.displayName = "MultiCheckBox";

MultiCheckBox.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: MultiCheckBox.displayName
};

export default MultiCheckBox;

import { Passage } from "../models";
import { Dispatch } from "react";
import { ThunkAction } from "redux-thunk";
import { fetchPassages, fetchNextPassages } from "../services";

export enum PassageActionType {
  FetchPassages = "FETCH_PASSAGES",
  FetchNextPassages = "FETCH_NEXT_PASSAGES",
  SelectPassage = "SELECT_PASSAGE"
}

export interface FetchPassagesAction {
  type: PassageActionType.FetchPassages;
  payload: Passage[];
}

export interface FetchNextPassagesAction {
  type: PassageActionType.FetchNextPassages;
  payload: Passage[];
}

export interface SelectPassageAction {
  type: PassageActionType.SelectPassage;
  payload: Passage | null;
}

export type PassageActions =
  | FetchPassagesAction
  | SelectPassageAction
  | FetchNextPassagesAction;

export type FetchPassagesActionResult = ThunkAction<
  Promise<Passage[]>,
  {},
  {},
  FetchPassagesAction
>;
export const fetchPassagesAction = (
  satelliteID: number
): FetchPassagesActionResult => {
  return async (dispatch: Dispatch<FetchPassagesAction>) => {
    try {
      const payload = await fetchPassages(satelliteID);
      dispatch({
        type: PassageActionType.FetchPassages,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export type FetchNextPassagesActionResult = ThunkAction<
  Promise<Passage[]>,
  {},
  {},
  FetchNextPassagesAction
>;
export const fetchNextPassagesAction = (
  satelliteID: number
): FetchNextPassagesActionResult => {
  return async (dispatch: Dispatch<FetchNextPassagesAction>) => {
    try {
      const payload = await fetchNextPassages(satelliteID);
      dispatch({
        type: PassageActionType.FetchNextPassages,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export type SelectPassageActionResult = ThunkAction<
  Promise<Passage | null>,
  {},
  {},
  SelectPassageAction
>;
export const selectPassageAction = (
  passage: Passage | null
): SelectPassageActionResult => {
  return async (dispatch: Dispatch<SelectPassageAction>) => {
    dispatch({
      type: PassageActionType.SelectPassage,
      payload: passage
    });
    return Promise.resolve(passage);
  };
};

import React, { Component, ChangeEvent, FormEvent } from "react";
import {
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { Box, Flex } from "primitives";
import { EditButton, SaveButton, CancelButton, InputField } from "components";
import { editSatelliteInstance } from "app/satellite/services";
import { SatelliteInstance } from "app/satellite/models";
import { SatelliteDefinition } from "app/satelliteDefinition/models";

interface SatelliteInstanceEditProps {
  record: SatelliteInstance;
  onChange: () => void;
  satelliteDefinitions: SatelliteDefinition[];
}

interface SatelliteInstanceEditState {
  modalOpen: boolean;
  label: string;
  satelliteDefinitionId: number | string;
}

export class SatelliteInstanceEdit extends Component<
  SatelliteInstanceEditProps,
  SatelliteInstanceEditState
> {
  state = {
    modalOpen: false,
    label: this.props.record.label,
    satelliteDefinitionId: this.props.record.satelliteDefinitionSummary
      .satelliteDefinitionId
  };

  onChangeLabel(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const { value } = event.currentTarget;
    this.setState({ label: value });
  }

  onChangeSatelliteDefinitionID(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget;
    this.setState({ satelliteDefinitionId: value });
  }

  hideDialog = () => {
    this.setState({ modalOpen: false });
  };

  openDialog = () => {
    this.setState({ modalOpen: true });
  };

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { label, satelliteDefinitionId } = this.state;
    const { record } = this.props;
    const satelliteInstance = {
      id: record.id,
      label,
      satelliteDefinitionId
    };
    editSatelliteInstance(record, satelliteInstance).then(() => {
      this.props.onChange();
      this.hideDialog();
    });
  };

  render() {
    const { record, satelliteDefinitions } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        <EditButton onClick={() => this.openDialog()} mr={1}>
          Edit
        </EditButton>
        <Dialog
          fullWidth={true}
          open={modalOpen}
          maxWidth="md"
          data-testid="SatelliteInstanceEdit"
        >
          <DialogTitle>Edit</DialogTitle>
          <DialogContent>
            <form
              onSubmit={(e) => this.handleSubmit(e)}
              id="edit-satellite-instance"
            >
              <Box mb={2}>
                <InputField
                  id="id"
                  required={true}
                  defaultValue={(record && record.id) || ""}
                  label="ID"
                  disabled
                />
              </Box>
              <Box mb={2}>
                <InputField
                  id="label"
                  required={true}
                  defaultValue={(record && record.label) || ""}
                  label="Label"
                  onChange={(e) => this.onChangeLabel(e)}
                />
              </Box>
              <Box mb={2}>
                <FormControl fullWidth>
                  <InputLabel>Satellite Definition</InputLabel>
                  <Select
                    required={true}
                    name="satelliteDefinition"
                    fullWidth
                    native
                    onChange={(e) => this.onChangeSatelliteDefinitionID(e)}
                    defaultValue={
                      (record &&
                        record.satelliteDefinitionSummary
                          .satelliteDefinitionId) ||
                      ""
                    }
                  >
                    {satelliteDefinitions &&
                      satelliteDefinitions.map((satelliteDefinition) => (
                        <option
                          key={satelliteDefinition.id}
                          value={satelliteDefinition.id}
                        >
                          {satelliteDefinition.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Flex
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <SaveButton type="submit" form="edit-satellite-instance" mr={1}>
                Save
              </SaveButton>
              <CancelButton onClick={() => this.hideDialog()}>
                Cancel
              </CancelButton>
            </Flex>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

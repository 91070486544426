const validateOnboardTime = (bootCount: string, millis: string) => {
  const bootCountExpr = /^\d+$/;
  const millisExpr = /^\d{1,12}$/;
  return bootCountExpr.test(bootCount) && millisExpr.test(millis);
};

// The OnboardTime timestamp must have the following format <boot_count><millis>.
// Where <boot_count> length is at least 1, and <millis> has a fixed length of 12.
export const convertOnboardTime = (
  bootCount: string,
  millis: string
): string => {
  const ONBOARD_MILLIS_SIZE = 12;
  if (!validateOnboardTime(bootCount, millis)) {
    throw Error("Invalid boot count + millis");
  } else {
    return `${bootCount}${millis.padStart(ONBOARD_MILLIS_SIZE, "0")}`;
  }
};

export const isURL = (string: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return pattern.test(string);
};

export const FETCH_ALERT_COUNT_START = "FETCH_ALERT_COUNT_START";
export const FETCH_ALERT_COUNT_SUCCESS = "FETCH_ALERT_COUNT_SUCCESS";
export const FETCH_ALERT_COUNT_ERROR = "FETCH_ALERT_COUNT_ERROR";

export const FETCH_ALERT_START = "FETCH_ALERT_START";
export const FETCH_ALERT_SUCCESS = "FETCH_ALERT_SUCCESS";
export const FETCH_ALERT_ERROR = "FETCH_ALERT_ERROR";

export const DISMISS_ALERT_START = "DISMISS_ALERT_START";
export const DISMISS_ALERT_SUCCESS = "DISMISS_ALERT_SUCCESS";
export const DISMISS_ALERT_ERROR = "DISMISS_ALERT_ERROR";

export const SET_ALERTS = "SET_ALERTS";

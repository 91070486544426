import React from "react";
import { KeycloakInstance } from "keycloak-js";

interface LogoutProps {
  kc: KeycloakInstance;
  children: React.ReactElement;
}

export const Logout = ({ kc, children }: LogoutProps) =>
  React.cloneElement(children, {
    onClick: kc.logout
  });

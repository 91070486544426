import React, { Component, useState } from "react";
import { Relative, Button, Flex, Icon, Text, Box } from "primitives";
import { Telemetry } from "app/dataSource";
import {
  VerticalGridLines,
  HorizontalGridLines,
  FlexibleXYPlot,
  XAxis,
  YAxis,
  LineSeries,
  MarkSeries,
  AreaSeries,
  GradientDefs,
  Borders,
  DiscreteColorLegend,
  Highlight,
  Hint
} from "react-vis";
import {
  GraphDataViewGlobalStyles,
  gridLinesStyles,
  lineSeriesStyles,
  markSeriesStyles,
  yAxisStyles,
  xAxisStyles,
  Actions,
  colors,
  xAxisFormatter,
  yAxisFormatter,
  graphFormatData
} from "./GraphDataViewHelpers";
import themeColors from "styles/palette";
import {
  DataSourceReadingDTO,
  ScalableValues,
  FormatedData
} from "../../models";
import { AlertValueStatus, Colors } from "app/alert/models";
import mergeWith from "lodash.mergewith";
import { scaleLinear } from "d3-scale";
import config from "config/constants";
import { getAlertValueColor, getAlertValueDetails } from "app/alert/helpers";

interface LastDrawLocation {
  left: number;
  right: number;
  bottom: number;
  top: number;
}

interface GraphDataProps {
  datasourceReading: DataSourceReadingDTO[];
  height?: string;
  setPlaying: (isPlaying: boolean) => void;
  playing: boolean;
  hidePlay: (hide: boolean) => void;
  options: any;
}

interface GraphDataState {
  lastDrawLocation: LastDrawLocation | null;
  data: any;
  start: Date;
  serieSelected: number | null;
  hoveredCell: any;
  scalableValues: ScalableValues;
  formatedDataList: FormatedData[];
  hasReadings: boolean;
  zooming: boolean;
  isHovering: boolean;
  isHoveringOtherElements: boolean;
}

interface Legend {
  title: string;
  color: string;
  index: number;
}

const DEFAULT_WINDOW_SIZE = 30;

class GraphData extends Component<GraphDataProps, GraphDataState> {
  state: GraphDataState = {
    lastDrawLocation: null,
    data: [],
    start: new Date(),
    serieSelected: null,
    hoveredCell: { active: false },
    scalableValues: {
      scale: scaleLinear().range([0, 100]),
      maxValues: [],
      minValues: []
    },
    formatedDataList: [],
    hasReadings: false,
    zooming: false,
    isHovering: false,
    isHoveringOtherElements: false
  };

  render() {
    const {
      lastDrawLocation,
      hasReadings,
      zooming,
      isHovering,
      isHoveringOtherElements
    } = this.state;
    const { height, options } = this.props;

    const showLegends =
      !options.legend ||
      !options.legend.visibilityMode ||
      options.legend.visibilityMode === "alwaysVisible" ||
      (options.legend.visibilityMode === "onHoverVisible" && isHovering);
    const showAxis =
      !options.legend ||
      !options.axis.visibilityMode ||
      options.axis.visibilityMode === "alwaysVisible" ||
      (options.axis.visibilityMode === "onHoverVisible" && isHovering);
    const showGrid =
      !options.legend ||
      !options.grid.visibilityMode ||
      options.grid.visibilityMode === "alwaysVisible" ||
      (options.grid.visibilityMode === "onHoverVisible" && isHovering);
    const showAlerts = options && options.showAlerts;
    const scale = options && options.scale;

    return (
      <Relative
        height={height || "100%"}
        overflow="visible"
        pt={2}
        bg={"palette.grey.5"}
        onMouseOver={() => !isHovering && this.setState({ isHovering: true })}
        onMouseOut={() =>
          isHovering &&
          !isHoveringOtherElements &&
          this.setState({ isHovering: false })
        }
      >
        {!hasReadings && (
          <Flex
            alignItems="center"
            justifyContent="center"
            height="90%"
            width="100%"
            position="absolute"
          >
            Waiting data...
          </Flex>
        )}
        <GraphDataViewGlobalStyles />
        <FlexibleXYPlot
          xDomain={
            lastDrawLocation && [lastDrawLocation.left, lastDrawLocation.right]
          }
          yDomain={
            lastDrawLocation
              ? [lastDrawLocation.bottom, lastDrawLocation.top]
              : scale && !isNaN(scale.min) && !isNaN(scale.max)
              ? [scale.min, scale.max]
              : null
          }
        >
          {showGrid ? (
            <HorizontalGridLines
              style={
                options && options.grid && options.grid.thickness
                  ? ({
                      ...gridLinesStyles,
                      strokeWidth: options.grid.thickness
                    } as any)
                  : gridLinesStyles
              }
              tickTotal={
                options && options.grid && !isNaN(options.grid.size.y)
                  ? options.grid.size.y
                  : null
              }
            />
          ) : null}
          {showGrid ? (
            <VerticalGridLines
              style={
                options && options.grid && options.grid.thickness
                  ? ({
                      ...gridLinesStyles,
                      strokeWidth: options.grid.thickness
                    } as any)
                  : gridLinesStyles
              }
              tickTotal={
                options && options.grid && !isNaN(options.grid.size.x)
                  ? options.grid.size.x
                  : null
              }
            />
          ) : null}

          {!hasReadings && <LineSeries data={[{ x: 0, y: 0 }]} />}

          {this.renderAreaSeries()}
          {this.renderLineSeries()}

          {/*
           React-vis highlight creates a layer over all layers.
           We need to change the order of the rendering to get both working.
           When the user starts to do zoom we put the markers behind the highlight,
           in the end, we put the markers in front of the highlight.
           */}
          {zooming && this.renderMarkSeries()}
          {hasReadings && this.renderHighlight()}
          {!zooming && this.renderMarkSeries()}

          {hasReadings && showAxis && this.renderYAxis()}
          {hasReadings && this.renderXAxis()}

          {hasReadings && [
            showLegends ? this.renderLegends() : null,
            this.renderActions(),
            showAlerts ? this.renderAlertHints() : null,
            this.renderHint()
          ]}
        </FlexibleXYPlot>
      </Relative>
    );
  }

  componentWillReceiveProps(nextProps: GraphDataProps) {
    const { datasourceReading, options } = nextProps;
    const { start, data, scalableValues } = this.state;
    if (datasourceReading) {
      this.setState(
        {
          data: mergeWith(this.state.data, datasourceReading, function(
            objValue: DataSourceReadingDTO[],
            srcValue: DataSourceReadingDTO[]
          ) {
            if (Array.isArray(objValue)) {
              return objValue.concat(srcValue).slice(-DEFAULT_WINDOW_SIZE);
            }
          })
        },
        () => {
          const [formatedDataList, newScalableValues] = graphFormatData(
            start,
            data,
            scalableValues,
            options
          );
          this.setState({
            formatedDataList,
            scalableValues: newScalableValues
          });

          this.setHasReadings(formatedDataList);
        }
      );
    }
  }

  setHasReadings(formatedDataList: FormatedData[]) {
    this.setState({
      hasReadings: formatedDataList.some((i: any) => i.readings.length > 0)
    });
  }

  renderAreaSeries() {
    const { formatedDataList, data, serieSelected } = this.state;
    return formatedDataList.map((row: FormatedData, index) => {
      return (
        data &&
        serieSelected === index && (
          <AreaSeries
            key={index}
            color={`${colors[index]}`}
            stroke="transparent"
            opacity={0.1}
            data={row.readings}
          />
        )
      );
    });
  }

  renderMarkSeries() {
    const {
      formatedDataList,
      data,
      serieSelected,
      hoveredCell,
      zooming
    } = this.state;

    return formatedDataList.map((row: FormatedData, index) => {
      return (
        data && (
          <MarkSeries
            key={index}
            size={3}
            {...markSeriesStyles(
              colors[index],
              serieSelected === null || serieSelected === index
            )}
            data={row.readings}
            onValueMouseOver={(cell: any) => {
              if (zooming) return;
              this.setState({ hoveredCell: { ...cell, active: true } });
            }}
            onValueMouseOut={() => {
              if (zooming) return;
              this.setState({ hoveredCell: { ...hoveredCell, active: false } });
            }}
          />
        )
      );
    });
  }

  renderLineSeries() {
    const { options } = this.props;
    const { formatedDataList, data, serieSelected } = this.state;

    let strokeWidth = 2;
    if (options && options.plot && options.plot.type === "scatter") {
      strokeWidth = 0;
    } else if (options && options.plot && !isNaN(options.plot.thickness)) {
      strokeWidth = options.plot.thickness;
    }

    return formatedDataList.map((row: FormatedData, index) => {
      return (
        data && (
          <LineSeries
            key={index}
            style={{
              ...lineSeriesStyles(
                colors[index],
                serieSelected === null || serieSelected === index
              ),
              ...{ strokeWidth }
            }}
            data={row.readings}
          />
        )
      );
    });
  }

  legendClickHandler = (props: any) => {
    const { serieSelected } = this.state;
    this.setState({
      serieSelected: serieSelected === props.index ? null : props.index
    });
  };

  renderLegends() {
    const { formatedDataList, isHoveringOtherElements } = this.state;

    const legends: Legend[] =
      formatedDataList &&
      formatedDataList.map((legend: FormatedData, index: number) => ({
        title: `${legend.dataSource.name} ${
          legend.dataSource.units ? `(${legend.dataSource.units.unit})` : ""
        }`,
        color: colors[index],
        index
      }));

    const graphStyle = {
      position: "absolute",
      right: "10px",
      top: "10px",
      backgroundColor: "rgba(31, 30, 44, 0.8)"
    };

    return (
      <DiscreteColorLegend
        key="DiscreteColorLegend"
        style={graphStyle}
        orientation="vertical"
        items={legends || []}
        onItemClick={this.legendClickHandler}
        onItemMouseEnter={() =>
          !isHoveringOtherElements &&
          this.setState({ isHoveringOtherElements: true, isHovering: true })
        }
        onItemMouseLeave={() =>
          isHoveringOtherElements &&
          this.setState({ isHoveringOtherElements: false })
        }
      />
    );
  }

  renderYAxis() {
    const { formatedDataList, serieSelected, scalableValues } = this.state;

    return [
      <GradientDefs key="GradientDefs">
        <linearGradient
          id="gradient"
          x1="0"
          x2={(formatedDataList.length + 1) * 25}
          y1="0"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="50%" stopColor={themeColors.fill[2]} stopOpacity={1} />
          <stop
            offset="100%"
            stopColor={themeColors.palette.grey[4]}
            stopOpacity={0.4}
          />
        </linearGradient>
      </GradientDefs>,
      <Borders
        key="Borders"
        style={{
          bottom: { fill: themeColors.palette.grey[6] },
          left: {
            fill: "url(#gradient)",
            width: `${(formatedDataList.length + 1) * 25}px`
          },
          right: { fill: "transparent" },
          top: { fill: "transparent" }
        }}
      />,
      formatedDataList.map((row: FormatedData, index) => {
        return (
          row && (
            <YAxis
              key={index}
              tickFormat={yAxisFormatter(index, scalableValues)}
              style={yAxisStyles(
                index,
                serieSelected === null || serieSelected === index
              )}
            />
          )
        );
      })
    ];
  }

  renderXAxis() {
    return <XAxis hideLine style={xAxisStyles()} tickFormat={xAxisFormatter} />;
  }

  renderHint() {
    const { hoveredCell } = this.state;
    return (
      <Hint
        key="Hint"
        value={hoveredCell || ""}
        style={{
          transition: "opacity 0.2s ease",
          opacity: hoveredCell.active ? 1 : 0,
          marginBottom: "10px"
        }}
        align={{ horizontal: "auto", vertical: "top" }}
      >
        <Flex
          flexDirection="column"
          bg="palette.grey.0"
          color="palette.black.0"
          fontSize="1"
          p="1"
        >
          {hoveredCell && hoveredCell.active ? (
            <>
              <Text color="text.grey" fontSize={14}>
                {hoveredCell.originalX}
              </Text>
              <Text
                color={
                  hoveredCell.alert ? getAlertValueColor(hoveredCell.alert) : ""
                }
                bold
                fontSize={14}
              >
                {hoveredCell.alert
                  ? getAlertValueDetails(
                      hoveredCell.alert,
                      hoveredCell.originalY
                    )
                  : hoveredCell.originalY}
              </Text>
            </>
          ) : null}
        </Flex>
      </Hint>
    );
  }

  renderAlertHints() {
    const { formatedDataList } = this.state;
    const hints = formatedDataList.map((formatedData: FormatedData) => {
      return formatedData.readings.map((reading: any) => {
        if (
          reading.alert &&
          reading.alert.alertType !== AlertValueStatus.NormalValue
        ) {
          return (
            <Hint
              value={reading}
              align={{ horizontal: "auto", vertical: "top" }}
            >
              <Box
                height={4}
                width={4}
                borderRadius={"50%"}
                mb={1}
                bg={
                  (Colors as { [key: string]: any })[
                    reading.alert.type === AlertValueStatus.AlertValue
                      ? reading.alert.severity
                      : "OutOfBounds"
                  ]
                }
              ></Box>
            </Hint>
          );
        }
        return null;
      });
    });
    return hints;
  }

  renderHighlight() {
    const { setPlaying, hidePlay } = this.props;
    return (
      <Highlight
        key="Highlight"
        onBrushStart={() => {
          this.setState({ zooming: true });
          setPlaying(false);
        }}
        onBrushEnd={(area: LastDrawLocation) => {
          this.setState({ lastDrawLocation: area, zooming: false });
          hidePlay(true);
        }}
      />
    );
  }

  renderActions() {
    const { lastDrawLocation } = this.state;
    const { setPlaying, hidePlay } = this.props;

    return (
      lastDrawLocation && (
        <Actions
          key="Actions"
          reset={() => {
            this.setState({ lastDrawLocation: null });
            setPlaying(true);
            hidePlay(false);
          }}
        />
      )
    );
  }
}

interface GraphDataViewProps {
  satellite: any;
  ids: number[];
  options: any;
}

export const GraphDataView = ({
  satellite,
  ids,
  options
}: GraphDataViewProps) => {
  const DEFAULT_FROM = 60;
  const [playing, setPlaying] = useState(true);
  const [hidePlay, setHidePlay] = useState(true);
  return (
    <>
      <Flex justifyContent="space-between" overflow="visible">
        {options.label && (
          <Text fontSize={18} my={2}>
            {options.label}
          </Text>
        )}
        {!hidePlay && (
          <Button onClick={() => setPlaying(!playing)}>
            {playing ? <Icon name="Pause" /> : <Icon name="Play" />}
          </Button>
        )}
      </Flex>
      <Telemetry
        satellite={satellite}
        ids={ids}
        interval={
          options && options.updatePeriod
            ? options.updatePeriod
            : config.timer.graph
        }
        params={{
          windowSize: DEFAULT_WINDOW_SIZE,
          from: `${DEFAULT_FROM}`,
          timeUnit: "Seconds"
        }}
        autoUpdates={playing}
        options={options}
      >
        {(data: any) => (
          <GraphData
            {...data}
            setPlaying={setPlaying}
            playing={playing}
            hidePlay={setHidePlay}
            options={options}
          />
        )}
      </Telemetry>
    </>
  );
};

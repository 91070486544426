import {
  TelecommandExecutionActionTypes,
  TelecommandExecutionMode,
  TelecommandListActionTypes
} from "../models";
import { ThunkAction } from "redux-thunk";
import { getSyncTelecommandResponse } from "../services";
import { Dispatch } from "react";
import { TelemetryFrame } from "app/telemetry/models";

// Telecommand executed releated actions

export interface SelectTelecommandAction {
  type: TelecommandExecutionActionTypes.SelectTelecommand;
  telecommandId: string;
  telecommandRecordId: number;
}

export interface SelectTelecommandExecutionModeAction {
  type: TelecommandExecutionActionTypes.SelectExecutionMode;
  executionMode: TelecommandExecutionMode;
  formData?: any;
}

export interface ClearSelectedTelecommandAction {
  type: TelecommandExecutionActionTypes.ClearTelecommand;
}

export type TelecommandExecutionActions =
  | SelectTelecommandAction
  | SelectTelecommandExecutionModeAction
  | ClearSelectedTelecommandAction;

export const selectTelecommand = (
  telecommandId: string,
  telecommandRecordId: number
): SelectTelecommandAction => ({
  telecommandId,
  telecommandRecordId,
  type: TelecommandExecutionActionTypes.SelectTelecommand
});

export const selectTelecommandExecutionMode = (
  executionMode: TelecommandExecutionMode,
  formData?: any
): SelectTelecommandExecutionModeAction => ({
  executionMode,
  formData,
  type: TelecommandExecutionActionTypes.SelectExecutionMode
});

// Telecommand list releated actions

export interface ClearTelecommandReponseAction {
  type: TelecommandListActionTypes.ClearResponse;
}

export interface TelecommandDetailsAction {
  type: TelecommandListActionTypes.TelecommandResponse;
  executedTelecommandId: Number;
  response: any;
}

export type TelecommandListActions =
  | ClearTelecommandReponseAction
  | TelecommandDetailsAction;

export const clearTelecommandResponse = (): ClearTelecommandReponseAction => ({
  type: TelecommandListActionTypes.ClearResponse
});

export type FetchTelecommandResponseActionResult = ThunkAction<
  Promise<TelemetryFrame>,
  {},
  {},
  TelecommandDetailsAction
>;
export const fetchTelecommandResponse = (
  satelliteId: Number,
  executedTelecommandId: Number
): FetchTelecommandResponseActionResult => {
  return async (dispatch: Dispatch<TelecommandDetailsAction>) => {
    try {
      const response = await getSyncTelecommandResponse(
        satelliteId,
        executedTelecommandId
      );
      dispatch({
        type: TelecommandListActionTypes.TelecommandResponse,
        executedTelecommandId: executedTelecommandId,
        response: response.data && response.data[0]
      });
      return Promise.resolve(response.data && response.data[0]);
    } catch (error) {
      console.log(`Error fetching telecommand response`, error);
    }
  };
};

export const clearSelectedTelecommand = (): ClearSelectedTelecommandAction => ({
  type: TelecommandExecutionActionTypes.ClearTelecommand
});

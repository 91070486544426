import React, { useState } from "react";
import { Grid, withStyles, InputLabel } from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { SelectBox } from "app/shared";
import { Button, Text } from "primitives";

const styles = () => {
  return {
    button: { margin: "0 10px" },
    root: { margin: "5px 0 2px 0", zIndex: 5, padding: "5px 0" },
    select: {
      width: "55px",
      margin: "0 0 0 10px"
    },
    text: { color: "#7AB9DB" }
  };
};

interface PaginationSimpleProps {
  nextPageUrl: string | number;
  previousPageUrl: string | number;
  pageSize: number;
  onChange: (url: string | number) => any;
  onPageSizeChange: (pageSize: number) => any;
  classes?: any;
  disabled?: boolean;
}

const options = {
  enumOptions: [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" }
  ]
};

const Pagination = (props: PaginationSimpleProps) => {
  const { nextPageUrl, previousPageUrl, classes, disabled } = props;
  const [pageSize, setPageSize] = useState(props.pageSize);

  const onClick = (url: string | number) => () => {
    props.onChange(url);
  };

  const onPageSizeChange = (size: number) => {
    props.onPageSizeChange(size);
    setPageSize(size);
  };

  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
      className={classes.root}
      style={{ position: "relative" }}
    >
      <InputLabel className={classes.text}>Rows per page:</InputLabel>
      <div className={classes.select}>
        {/*
        // @ts-ignore */}
        <SelectBox
          /*
          // @ts-ignore */
          id="page-size-select"
          options={options}
          onChange={onPageSizeChange}
          value={pageSize}
        />
      </div>
      <Button
        size="small"
        onClick={onClick(previousPageUrl)}
        disabled={
          previousPageUrl === null || previousPageUrl === -1 || disabled
        }
        className={classes.button}
        bg="fill.0"
      >
        <ArrowBack />
        <Text color="text.default">Previous page</Text>
      </Button>
      <Button
        size="small"
        onClick={onClick(nextPageUrl)}
        disabled={!nextPageUrl || disabled}
        className={classes.button}
        bg="fill.0"
      >
        <Text color="text.default">Next page</Text>
        <ArrowForward />
      </Button>
    </Grid>
  );
};

export const PaginationSimple = withStyles(styles)(Pagination);

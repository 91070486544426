import { createMuiTheme } from "@material-ui/core/styles";
// TODO: delete this file when all material-ui is removed

export default createMuiTheme({
  palette: {
    type: "dark"
  },
  overrides: {
    MuiTypography: {
      title: {
        color: "#7AB9DB"
      },
      body1: {
        color: "#7AB9DB"
      },
      caption: {
        color: "#7AB9DB"
      }
    },
    MuiTablePagination: {
      root: {
        color: "#7AB9DB"
      },
      selectRoot: {
        color: "#7AB9DB"
      },
      actions: {
        color: "#7AB9DB"
      }
    },
    MuiInput: {
      root: {
        color: "#7AB9DB"
      },
      disabled: {
        color: "#707070 !important"
      },
      underline: {
        color: "#7AB9DB !important"
      },
      inputMultiline: {
        height: "auto !important"
      }
    },
    MuiFormLabel: {
      root: {
        color: "#7AB9DB"
      }
    },
    MuiSelect: {
      root: {
        color: "#7AB9DB"
      },
      icon: {
        color: "#7AB9DB"
      }
    },
    MuiFormControl: {
      root: {
        color: "#7AB9DB"
      }
    },
    MuiDialogTitle: {
      root: {
        color: "#7AB9DB"
      }
    },
    MuiPaper: {
      root: {
        background: "#2E4162 !important"
      }
    },
    MuiDialogActions: {
      root: {
        margin: "0 !important",
        padding: "8px !important"
      }
    },
    MuiRadio: {
      root: {
        color: "#7AB9DB"
      },
      checked: {
        color: "#7AB9DB !important"
      }
    },
    MuiCheckbox: {
      root: {
        color: "#7AB9DB"
      },
      checked: {
        color: "#7AB9DB !important"
      }
    },
    MuiButtonBase: {
      root: {
        color: "#7AB9DB"
      }
    },
    MuiIconButton: {
      root: {
        color: "#7AB9DB"
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#7AB9DB"
      }
    }
  }
});

import { InputBase, SelectBox, DataSourceArrayField } from "app/shared";
import { DataSource } from "app/dataSource/models";
import {
  DashboardComponentsAll,
  DashboardComponentsNames
} from "app/dashboard/models";
import { SatelliteDefinition } from "app/satelliteDefinition/models";
import { SatelliteInstance } from "app/satellite/models";
import { GroundStation } from "app/groundStation";
import { configOptionsSchema } from "app/dashboard/models/configOptions";

export const schemaGenerator = (
  dataSources: DataSource[],
  selectedSatelliteDefinition: SatelliteDefinition
) => {
  return {
    type: "object",
    required: ["label", "type", "size", "position"],
    properties: {
      label: {
        type: "string",
        description: "Title of the component"
      },
      type: {
        type: "string",
        description: "Widget type",
        title: "Component type",
        enum: DashboardComponentsAll,
        enumNames: DashboardComponentsNames
      },
      dataSources: {
        title: "Data Sources",
        description: "List of data sources that are assigned to the widget",
        type: "array",
        hasRemove: true,
        minItems: 0,
        default: [],
        items: {
          type: "object",
          selectedSatelliteDefinition,
          dataSources,
          properties: {
            id: {
              type: "number"
            },
            label: {
              type: "string"
            }
          }
        }
      }
    }
  };
};

export const configOptionsSchemaGenerator = (
  formData: any,
  satelliteInstances: SatelliteInstance[],
  groundStations: GroundStation[]
) => {
  if (formData && formData.type && configOptionsSchema[formData.type]) {
    const schema = configOptionsSchema[formData.type].schema;
    if (schema && schema.definitions) {
      if (schema.definitions.satellite) {
        schema.definitions.satellite.properties.id.enum = satelliteInstances.map(
          (satellite: SatelliteInstance) => satellite.id
        );
        schema.definitions.satellite.properties.id.enumNames = satelliteInstances.map(
          (satellite: SatelliteInstance) => satellite.label
        );
      }

      const groundStationIDs = groundStations.map(
        (groundstation: GroundStation) => groundstation.groundStationID
      );
      const groundStationNames = groundStations.map(
        (groundstation: GroundStation) => groundstation.groundStationName
      );
      if (schema.definitions.groundstation) {
        schema.definitions.groundstation.properties.id.enum = groundStationIDs;
        schema.definitions.groundstation.properties.id.enumNames = groundStationNames;
      }
      if (schema.definitions.groundstations) {
        schema.definitions.groundstations.items.enum = groundStationIDs;
        schema.definitions.groundstations.items.enumNames = groundStationNames;
      }
    }
    return schema;
  } else {
    return {};
  }
};

export const configOptionsUISchemaGenerator = (formData: any) => {
  if (formData && formData.type && configOptionsSchema[formData.type]) {
    return configOptionsSchema[formData.type].uiSchema;
  } else {
    return {};
  }
};

export const uiSchema = {
  label: { "ui:widget": InputBase },
  type: { "ui:widget": SelectBox },
  dataSources: { "ui:field": DataSourceArrayField }
};

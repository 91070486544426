import React from "react";
import { themeGet } from "styled-system";
import styled from "styled-components";
import { Box, Flex, Icon } from "primitives";
import { theme } from "styles";
import Avatar from "../app/user/components/Avatar";
import DropdownMenu from "./DropdownMenu";
import { DashboardSelector } from "app/dashboard";
import { UserManagementSelector } from "app/user/components/UserManagementSelector";
import { SettingsSelector } from "./SettingsSelector";

const StyledContainer = styled(Flex)`
  background: transparent linear-gradient(0deg, #181821 0%, #27293a 100%) 0% 0%
    no-repeat padding-box;
  transition: width 0.2s ease-in-out;
`;

const Clickable = styled(Flex)`
  cursor: pointer;
`;

const OpenSidebar = styled(Flex)`
  position: absolute;
  top: 40px;
  cursor: pointer;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 12px;
  border-color: transparent transparent transparent
    ${themeGet("colors.palette.brand.0")};
  transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
`;

// TODO: Remove the main menu once all the components have been migrated to the dashboards
const Sidebar = ({ showSideBar, toggleSideBar }: any) => {
  const width = themeGet("sizes.sidebarWidth")({ theme });

  return (
    <>
      <StyledContainer
        width={showSideBar ? width : "0px"}
        flexDirection="column"
        position="relative"
        p={showSideBar ? 3 : 0}
        borderRight={3}
        borderColor="border.1"
        data-testid="Sidebar"
      >
        {showSideBar ? (
          <Clickable overflow={"visible"} onClick={() => toggleSideBar()}>
            <Icon
              color={themeGet("colors.palette.brand.0")}
              name="Hide"
              size={20}
            />
          </Clickable>
        ) : null}

        <Avatar />

        <DropdownMenu title="Dashboards" icon="DashboardMenu">
          <Box ml={2} my={3} position="relative">
            <DashboardSelector />
          </Box>
        </DropdownMenu>

        <DropdownMenu title="Settings" icon="SettingsMenu">
          <Box ml={2} my={3} position="relative">
            <SettingsSelector />
          </Box>
        </DropdownMenu>

        <DropdownMenu title="Admin" icon="AdminMenu">
          <Box ml={2} my={3} position="relative">
            <UserManagementSelector />
          </Box>
        </DropdownMenu>
      </StyledContainer>
      {!showSideBar ? (
        <OpenSidebar opacity={1} onClick={() => toggleSideBar()}></OpenSidebar>
      ) : (
        <OpenSidebar opacity={0} onClick={() => toggleSideBar()}></OpenSidebar>
      )}
    </>
  );
};

export default Sidebar;

import { SelectBox, InputBase } from "app/shared";
import { CheckBox } from "components";

const globeConfigOptionsSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "globe.schema.json",
  title: "Aurora Globe",
  description: "The configuration options for the Aurora's Globe component",
  type: "object",
  definitions: {
    view: {
      $id: "#/definitions/view",
      title: "View",
      description: "Default map view",
      type: "string",
      enum: ["2D", "3D", "Colombus"],
      default: "3D"
    },
    color: {
      $id: "#/definitions/color",
      title: "Color",
      description:
        "Sets the color of the line in hexadecimal format (e.g. #FFFFFF). If none color is provided, a random one will be used",
      type: "string"
    },
    satelliteObject: {
      $id: "#/definitions/satelliteObject",
      title: "Object",
      description: "Selects the object used to show the satellite",
      type: "string",
      enum: ["dot", "satellite"],
      default: "satellite"
    },
    groundstationObject: {
      $id: "#/definitions/groundstationObject",
      title: "Groundstation Object",
      description: "Selects the object used to show the Groundstation",
      type: "string",
      enum: ["dot", "groundstation"],
      default: "dot"
    },
    satellite: {
      $id: "#/definitions/satellite",
      title: "Satellite",
      type: "object",
      properties: {
        id: {
          $id: "#/definitions/satellite/properties/id",
          title: "Satellite id",
          description: "Satellite Id",
          type: "integer"
        },
        orbitColor: {
          $ref: "#/definitions/color"
        },
        object: {
          $ref: "#/definitions/satelliteObject"
        }
      },
      required: ["id", "object"]
    },
    groundstation: {
      $id: "#/definitions/groundstation",
      title: "Groundstation",
      type: "object",
      properties: {
        id: {
          $id: "#/definitions/satellite/properties/id",
          title: "Groundstation id",
          description: "Groundstation Id",
          type: "integer"
        },
        color: {
          $ref: "#/definitions/color"
        },
        object: {
          $ref: "#/definitions/groundstationObject"
        }
      },
      required: ["id", "object"]
    }
  },
  properties: {
    view: {
      $ref: "#/definitions/view"
    },
    highlightOnPassage: {
      $id: "#/properties/highlightOnPassage",
      title: "Highlight On Passage",
      description:
        "Weather the satellite and the corresponding Ground Stations should be highlighted during a passage",
      type: "boolean",
      default: true
    },
    highlightOnSelect: {
      $id: "#/properties/highlightOnSelect",
      title: "Highlight On Select",
      description:
        "Weather the satellite and the corresponding Ground Stations should be highlighted, during the selection of a satellite",
      type: "boolean",
      default: true
    },
    satellites: {
      $id: "#/properties/satellites",
      title: "Satellites",
      description:
        "List of satellites to be present in the globe. If not used, all the satellites will be shown",
      type: "array",
      minItems: 0,
      hasRemove: true,
      items: {
        $ref: "#/definitions/satellite"
      }
    },
    groundstations: {
      $id: "#/properties/groundstations",
      title: "Groundstations",
      description:
        "List of Ground Stations to be present in the globe. If not used, all the Ground Stations will be shown",
      type: "array",
      minItems: 0,
      hasRemove: true,
      items: {
        $ref: "#/definitions/groundstation"
      }
    }
  },
  required: ["view", "highlightOnPassage", "highlightOnSelect"]
};

const globeConfigOptionsDefault = {
  view: "3D"
};

const globeConfigOptionsUISchema = {
  view: { "ui:widget": SelectBox },
  highlightOnPassage: { "ui:widget": CheckBox },
  highlightOnSelect: { "ui:widget": CheckBox },
  satellites: {
    items: {
      id: { "ui:widget": SelectBox },
      orbitColor: { "ui:widget": InputBase },
      object: { "ui:widget": SelectBox }
    }
  },
  groundstations: {
    items: {
      id: { "ui:widget": SelectBox },
      color: { "ui:widget": InputBase },
      object: { "ui:widget": SelectBox }
    }
  }
};

export {
  globeConfigOptionsSchema,
  globeConfigOptionsDefault,
  globeConfigOptionsUISchema
};

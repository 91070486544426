import styled from "styled-components";
import { POSITION, PositionProps as PositionBaseProps } from "styles/constants";
import Box, { BoxProps } from "./Box";

interface PositionProps extends PositionBaseProps, BoxProps {}

const Position = styled(Box)<PositionProps>`
  ${POSITION}
`;

export const Absolute = styled(Position)`
  position: absolute;
`;
Absolute.displayName = "Absolute";

export const Fixed = styled(Position)`
  position: fixed;
`;
Fixed.displayName = "Fixed";

export const Relative = styled(Position)`
  position: relative;
`;
Relative.displayName = "Relative";

export const Sticky = styled(Position)`
  position: sticky;
`;

Sticky.defaultProps = {
  top: 0
};

Sticky.displayName = "Sticky";

import {
  Colors,
  AlertDTO,
  Severity,
  AlertValueStatus,
  AlertValue,
  AlertDetails
} from "app/alert/models";
import { ReadingDTO, ValueDTO } from "app/telemetry/models";

/*
 *Get the most priority alert status color from a given reading
 */
export const getAlertColor = (reading: ReadingDTO | AlertDTO) => {
  if (reading && reading.value) {
    let alertType: string = "NormalValue";
    reading.value.forEach((value: any) => {
      if (!value.alert) {
        return null;
      }
      const alert = value.alert;
      if (alert.type === "OutOfBoundsValue") {
        alertType = "OutOfBounds";
      }
      if (alertType === "NormalValue") {
        if (alert.type === AlertValueStatus.AlertValue) {
          alertType = alert.severity;
        }
      } else {
        if (
          alert.type === AlertValueStatus.AlertValue &&
          alertType !== "OutOfBounds"
        ) {
          alertType =
            alertType === Severity.Warning ? alert.severity : alertType;
        }
      }
    });
    if (alertType === Severity.Warning || alertType === Severity.Critical) {
      return Colors[alertType];
    } else if (alertType === "NormalValue") {
      return Colors.Default;
    } else {
      return Colors.OutOfBounds;
    }
  }
};

/*
 *Get the most priority alert status color from a given reading (array type value)
 *Used in alert table.
 */
export const getAlertColorForArrayValue = (
  reading: ReadingDTO | AlertDTO,
  ignoreValuesWithoutAlert: boolean = false
) => {
  if (reading && reading.value) {
    const arrayValue = reading.value;
    let colors: string[] = [];
    let index = 0;
    arrayValue.forEach((value: ValueDTO | AlertValue, i: number) => {
      /*eslint no-empty: "error"*/
      if (
        ignoreValuesWithoutAlert &&
        value &&
        value.alert &&
        value.alert.type === "NormalValue"
      ) {
        // empty
      } else {
        if (value && value.alert) {
          const alert = value.alert;
          if (!alert) {
            colors[index] = Colors.Default;
          } else if (
            alert &&
            alert.type === AlertValueStatus.AlertValue &&
            alert.severity
          ) {
            colors[index] = Colors[alert.severity];
          } else if (
            alert &&
            alert.type === AlertValueStatus.OutOfBoundsValue
          ) {
            colors[index] = Colors.OutOfBounds;
          } else {
            colors[index] = Colors.Default;
          }
        } else {
          colors[index] = Colors.Default;
        }
      }
      index += 1;
    });
    //Remove empty values from array
    colors = colors.filter((color) => {
      return color;
    });
    return colors;
  }
  return Colors.Default;
};

/*
 *Generate and return the alert text description.
 *Used in alert table.
 *Format Example: Out Of Bounds: 15 -> [-10, 10]
 */
export const getAlertDetails = (alert: AlertDTO) => {
  if (alert && alert.value && alert.value.length > 0) {
    const valueAlerts = alert.value;
    let details = "";
    valueAlerts.forEach((valueAlertAux) => {
      const valueAlert = valueAlertAux.alert;
      if (
        valueAlert.type === AlertValueStatus.AlertValue ||
        valueAlert.type === AlertValueStatus.OutOfBoundsValue
      ) {
        const values =
          valueAlert.type === AlertValueStatus.AlertValue
            ? `[${valueAlert.lowerThreshold};${valueAlert.upperThreshold}]`
            : `[${valueAlert.minValue};${valueAlert.maxValue}]`;
        details += `${
          valueAlert.type === AlertValueStatus.AlertValue
            ? valueAlert.severity
            : "Out Of Bounds"
        }: ${valueAlert.index ? `[${valueAlert.index}]` : ""} ${
          (valueAlert.index || valueAlert.index === 0) &&
          Array.isArray(alert.currentValue)
            ? alert.currentValue[valueAlert.index]
            : alert.currentValue
        } ->  ${values},`;
      }
    });
    return details;
  }
  return null;
};

/**
 *Generate and return the alert value text description.
 *Used in graph widget.
 *Format Example: Out Of Bounds: 15 -> [-10, 10]
 */
export const getAlertStatus = (alert: AlertDTO) => {
  if (alert && alert.value && alert.value.length > 0) {
    const status: string[] = [];
    alert.value.forEach((value: AlertValue) => {
      const valueAlert = value.alert;
      if (valueAlert && valueAlert.type === AlertValueStatus.AlertValue) {
        if (valueAlert.severity && status.indexOf(valueAlert.severity) === -1) {
          return status.push(valueAlert.severity);
        }
      } else if (
        valueAlert &&
        valueAlert.type === AlertValueStatus.OutOfBoundsValue
      ) {
        if (status.indexOf("Out Of Bounds") === -1) {
          return status.push("Out Of Bounds");
        }
      }
    });
    return status.join(",");
  } else {
    return "";
  }
};

/**
 *Generate and return the alert value text description.
 *Used in graph widget.
 *Format Example: Out Of Bounds: 15 -> [-10, 10]
 */
export const getAlertValueDetails = (alert: AlertDetails, value: number) => {
  let details = "";
  if (alert) {
    const values =
      alert.type === AlertValueStatus.AlertValue
        ? `[${alert.lowerThreshold};${alert.upperThreshold}]`
        : `[${alert.minValue};${alert.maxValue}]`;
    details += `${
      alert.type === AlertValueStatus.AlertValue
        ? alert.severity
        : "Out Of Bounds"
    }: ${value} ->  ${values},`;
  } else {
    details = `${value}`;
  }
  return details;
};

/**
 *Return color for a given alert value.
 *Used in graph widget.
 */
export const getAlertValueColor = (alert: any) => {
  if (alert.type === AlertValueStatus.OutOfBoundsValue) {
    return Colors.OutOfBounds;
  }
  if (alert.type === AlertValueStatus.NormalValue) {
    return Colors.Black;
  }
  if (alert.type === AlertValueStatus.AlertValue && alert.severity) {
    return Colors[alert.severity];
  }
};

import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseQuery, SuspenseMutation, Suspense } from "app/network";
import { SaveButton } from "components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { GroundStation, GroundStationConfiguration } from "../models";
import { schemaEdit, uiSchemaEdit } from "../models/schemas";
import {
  fetchGroundStationConfig,
  createGroundStationConfig
} from "../services";
import { SatelliteInstance } from "app/satellite/models";
import { fetchGroundStationsAction } from "../actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

type RouteParams = {
  satelliteID: string;
  groundStationID: string;
};

interface GroundStationEditProps extends RouteComponentProps<RouteParams> {
  fetchGroundStationConfig: (
    satelliteID: number,
    groundStation: number
  ) => Promise<GroundStationConfiguration>;
  fetchGroundStations: () => Promise<GroundStation[]>;
  editGroundStation: (id: number, data: object) => Promise<GroundStation>;
  createGroundStationConfig: (
    data: GroundStationConfiguration
  ) => Promise<GroundStationConfiguration>;
  satellites: SatelliteInstance[];
  groundStations: GroundStation[];
}

interface GroundStationEditState {
  formData: any | null;
  groundStationType: string;
}

export class GroundStationEdit extends Component<
  GroundStationEditProps,
  GroundStationEditState
> {
  state = {
    formData: null,
    groundStationType: ""
  };

  componentDidMount() {
    this.props.fetchGroundStations().finally(() => {
      const { groundStationID } = this.props.match.params;
      if (this.getGroundStationById(parseInt(groundStationID, 10))) {
        this.setState({
          groundStationType: this.getGroundStationById(
            parseInt(groundStationID, 10)
          ).type
        });
      }
    });
  }

  async submit(
    e: React.FormEvent<HTMLInputElement>,
    formData: GroundStationConfiguration
  ) {
    e.preventDefault();

    const { groundStationID, satelliteID, type, configuration } = formData;
    const newGroundStation = {
      groundStationID,
      satelliteID,
      type,
      configuration
    };
    await this.props.createGroundStationConfig(newGroundStation);
    this.props.history.push("/groundstations");
  }

  onChange(form: GroundStationEditState) {
    if (form.formData && form.formData.groundStationID) {
      if (this.getGroundStationById(form.formData.groundStationID)) {
        this.setState({
          groundStationType: this.getGroundStationById(
            form.formData.groundStationID
          ).type
        });
      }
    }
    this.setState({ formData: form.formData });
  }

  getGroundStationById(groundStationID: number): GroundStation {
    return this.props.groundStations.filter((groundStation: GroundStation) => {
      return groundStationID === groundStation.groundStationID;
    })[0];
  }

  query = () => {
    const { satelliteID, groundStationID } = this.props.match.params;
    return this.props.fetchGroundStationConfig(
      parseInt(satelliteID, 10),
      parseInt(groundStationID, 10)
    );
  };

  render() {
    const { formData, groundStationType } = this.state;
    const { satellites, groundStations } = this.props;

    if (!groundStationType) {
      return null;
    }
    return (
      <Suspense>
        <SuspenseQuery query={this.query}>
          {({ response }) => {
            return (
              response && (
                <Box color="text.default">
                  <Flex mb={2}>
                    <Heading display={1}>
                      GroundSation Configuration Edit
                    </Heading>
                  </Flex>
                  <Flex flexDirection="column" bg="fill.0" p={3}>
                    <SuspenseMutation>
                      {({ loading, error, action }) => (
                        <Form
                          formData={formData || response}
                          schema={schemaEdit(
                            satellites || [],
                            groundStations,
                            groundStationType
                          )}
                          uiSchema={uiSchemaEdit(groundStationType)}
                          disabled={loading}
                          onSubmit={(
                            form: { formData: GroundStationConfiguration },
                            e: React.FormEvent<HTMLInputElement>
                          ) => action(() => this.submit(e, form.formData))}
                          onChange={(form: GroundStationEditState) =>
                            this.onChange(form)
                          }
                        >
                          <SaveButton type="submit" disabled={loading}>
                            Save
                          </SaveButton>

                          {error ? (
                            <Box my={2}>
                              <Text color="text.danger">Error...</Text>
                            </Box>
                          ) : null}
                        </Form>
                      )}
                    </SuspenseMutation>
                  </Flex>
                </Box>
              )
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}

const mapStateToProps = (state: any) => ({
  groundStations: state.groundStations.groundStations,
  satellites: state.constellations.dashboard
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchGroundStations: () => dispatch(fetchGroundStationsAction()),
  createGroundStationConfig,
  fetchGroundStationConfig: (satelliteID: number, groundStationID: number) =>
    fetchGroundStationConfig(satelliteID, groundStationID)
});

export const GroundStationEditContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GroundStationEdit)
);

import { connect } from "react-redux";
import { SatelliteDefinitionEdit } from "../components/SatelliteDefinitionEdit";
import { getDataSourceUnits } from "app/dataSource";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  unitsList: state.dataSources.units
});

const mapDispatchToProps = () => {
  return {
    getDataSourceUnits: () => getDataSourceUnits()
  };
};

export const SatelliteDefinitionEditContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SatelliteDefinitionEdit)
);

import React from "react";
import * as icons from "icons";
import { SvgProps } from "primitives/Svg";

export const Icons: { [key: string]: any } = {
  ...icons
};

interface IconProps extends SvgProps {
  name: string;
}

const Icon = ({ name, ...props }: IconProps) => {
  const SvgIcon = Icons[name];
  if (!SvgIcon) {
    throw Error(`Icon ${name} not found`);
  }
  return <SvgIcon {...props} />;
};

Icon.displayName = "Icon";

Icon.defaultProps = {
  color: "inherit"
};

export default Icon;

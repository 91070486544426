import { alertDataProvider, GET_LIST, CREATE } from "app/network";
import qs from "qs";
import { store } from "app/store";
import * as actions from "../actions";
import {
  SelectedAlert,
  GetAlertsResponse,
  AlertResponse,
  AlertDTO,
  GetAlertsDataSourceListResponse,
  AlertsDataSourceListResponse
} from "../models";
import { convertToLocalAlertModel } from "../models/dto";

export const getAlertcount = (satelliteId: number) => {
  store.dispatch(actions.fetchAlertCountStart());

  return alertDataProvider(GET_LIST, "alert/count", {
    query: {
      "satellite-id": satelliteId,
      dismissed: "false"
    }
  })
    .then((response: any) => {
      store.dispatch(actions.fetchAlertCountSuccess(response.data));
    })
    .catch((error: any) => {
      store.dispatch(actions.fetchAlertCountError());
    });
};

export const getAlerts = (
  satelliteId: number,
  pageSize?: number,
  paginationUrl: string = "",
  dismissed: string = "false",
  datasources: number[] | null = null
): Promise<GetAlertsResponse | null> => {
  store.dispatch(actions.fetchAlertsStart());

  let requestParams: any = {
    pagination: { pageSize },
    query: {
      "satellite-id": satelliteId,
      dismissed
    }
  };

  if (datasources) {
    if (datasources.length !== 0) {
      requestParams.query.datasources = datasources.join(",");
    } else {
      store.dispatch(actions.fetchAlertsSuccess([]));
      return new Promise(() => []);
    }
  }

  if (paginationUrl) {
    const url = new URL(paginationUrl);
    requestParams = {
      filter: {
        ...qs.parse(url.search, { ignoreQueryPrefix: true })
      }
    };
  }

  return alertDataProvider(GET_LIST, "alert", requestParams)
    .then((response: any) => {
      //Convert Alert Response Data format to Alert Local Data format
      const data: AlertDTO[] = [];
      response.data.forEach((alert: AlertResponse) => {
        data.push(convertToLocalAlertModel(alert));
      });
      response.data = data;
      //
      store.dispatch(actions.fetchAlertsSuccess(response.data));
      return response;
    })
    .catch((error: Error) => {
      store.dispatch(actions.fetchAlertsError(error.message));
      throw error;
    });
};

export const getAlertsDataSourceList = (
  satelliteId: number
): Promise<GetAlertsDataSourceListResponse | null> => {
  return alertDataProvider(GET_LIST, `alert/${satelliteId}/datasourceAlerts`)
    .then((response: any) => {
      if (response && response.data) {
        const data: AlertsDataSourceListResponse[] = [];
        response.data.forEach((d: any, index: number) => {
          const dataObj: AlertsDataSourceListResponse = {
            alert: convertToLocalAlertModel(d.alert),
            datasource: d.datasource
          };
          data.push(dataObj);
        });
        response.data = data;
        return response;
      }
    })
    .catch((error: Error) => {
      throw error;
    });
};

export const dismissAlerts = (
  satelliteId: number,
  alerts: SelectedAlert[],
  pageSize: number,
  datasources: number[] | null = null
) => {
  store.dispatch(actions.dismissAlertsStart());
  alertDataProvider(CREATE, `alert/dismissAlertRequests`, {
    data: { satelliteId, alerts }
  })
    .then(() => {
      store.dispatch(actions.dismissAlertsSuccess());
      getAlerts(satelliteId, pageSize, "", "false", datasources);
    })
    .catch((error: any) => {
      store.dispatch(
        actions.dismissAlertsError(`Error dismissing alerts: ${error.message}`)
      );
    });
};

export const dismissAllAlerts = (
  satelliteId: number,
  pageSize: number
): Promise<any> => {
  store.dispatch(actions.dismissAlertsStart());
  return alertDataProvider(CREATE, `alert/dismissAll`, {
    data: { satelliteId }
  })
    .then((response: any) => {
      store.dispatch(actions.dismissAlertsSuccess());
      store.dispatch(actions.fetchAlertsSuccess([]));
      store.dispatch(
        actions.fetchAlertCountSuccess({
          numCritical: 0,
          numWarnings: 0,
          numOthers: 0
        })
      );
      return response;
    })
    .catch((error: Error) => {
      // eslint-disable-next-line no-console
      store.dispatch(
        actions.dismissAlertsError(`Error dismissing alerts: ${error.message}`)
      );
    });
};

export const setAlerts = (alerts: AlertDTO[]): Promise<any> => {
  return store.dispatch(actions.setAlerts(alerts));
};

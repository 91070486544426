import React from "react";
import { SelectBox, InputBase } from "app/shared";
import { CheckBox, DateTimePicker } from "components";

const graphConfigOptionsSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "graph.schema.json",
  title: "Aurora Graph",
  description: "The configuration options for the Aurora's Graph component",
  type: "object",
  definitions: {
    time: {
      $id: "#/definitions/time",
      title: "Time",
      description: "Defines the characteristics of the time",
      type: "string",
      format: "date-time",
      examples: ["2011-08-12T20:17:46.384Z"]
    },
    timeUnixTimestamp: {
      $id: "#/definitions/timeUnixTimestamp",
      title: "Time",
      description: "Defines the characteristics of the time",
      type: "integer",
      minimum: 0,
      examples: [1]
    },
    bootcount: {
      $id: "#/definitions/bootcount",
      title: "Bootcount",
      description: "Defines the characteristics bootcount",
      type: "integer",
      minimum: 1
    },
    timeReference: {
      $id: "#/definitions/timeReference",
      title: "Timereference",
      description:
        "Defines the characteristics of the timeReference to be used to retrive the data to be presented",
      type: "object",
      properties: {
        reference: {
          $id: "#/properties/timeReference/properties/reference",
          title: "Reference",
          description:
            'An enumeration for the selection of the time reference to be used for the data querying.\nIf the reference "satelliteBootcountUtc" is selected, the "fromBootcount" and "toBootcount" can be used.\nIf "from" and "to" (and "fromBootcount" and "toBootount" for the case of "satelliteUtcBootcount" is selected), the mos recent value is queried.\n',
          type: "string",
          enum: ["groundUtc", "satelliteUtc", "satelliteBootcountObt"],
          default: "groundUtc"
        }
      },
      dependencies: {
        reference: {
          oneOf: [
            {
              properties: {
                reference: {
                  enum: ["groundUtc", "satelliteUtc"]
                },
                from: {
                  $id: "#/properties/timeReference/properties/from",
                  title: "From",
                  description:
                    "The date-time used to present the data only after this date-time.\nIf not used, this filter is not applied.",
                  $ref: "#/definitions/time"
                },
                to: {
                  $id: "#/properties/timeReference/properties/to",
                  title: "To",
                  description:
                    "The date-time used to present the data only before this date-time.\nIf not used, this filter is not applied.",
                  $ref: "#/definitions/time"
                }
              }
            },
            {
              properties: {
                reference: {
                  enum: ["satelliteBootcountObt"]
                },
                fromBootcount: {
                  $id: "#/properties/timeReference/properties/fromBootcount",
                  title: "From Bootcount",
                  description:
                    'The bootcount used to present the data only after this bootcount. If null, this filter is not applied.\nThis filter only exists if the reference "satelliteBootcountUtc" is selected.',
                  $ref: "#/definitions/bootcount"
                },
                fromBootcountTimestamp: {
                  $id: "#/properties/timeReference/properties/from",
                  title: "From Timestamp (Milis)",
                  description:
                    "The timestamp used to present the data only after this timestamp.\n",
                  $ref: "#/definitions/timeUnixTimestamp",
                  default: 0
                },
                toBootcount: {
                  $id: "#/properties/timeReference/properties/toBootcount",
                  title: "To Bootcount",
                  description:
                    'The bootcount used to present the data only before this bootcount. If null, this filter is not applied.\nThis filter only exists if the reference "satelliteBootcountUtc" is selected.',
                  $ref: "#/definitions/bootcount"
                },
                toBootcountTimestamp: {
                  $id: "#/properties/timeReference/properties/to",
                  title: "To Timestamp (Milis)",
                  description:
                    "The timestamp used to present the data only before this timestamp.\n",
                  $ref: "#/definitions/timeUnixTimestamp",
                  default: 0
                }
              }
            }
          ]
        }
      },
      required: ["reference"]
    },
    updatePeriod: {
      $id: "#/definitions/updatePeriod",
      title: "Update Period",
      description:
        "The period between queries to retrieve the values (milliseconds)",
      type: "integer",
      minimum: 1000,
      default: 2000
    },
    dataSource: {
      $id: "#/definitions/dataSource",
      type: "object",
      title: "Items",
      description: "The data-source item to be added to the component",
      properties: {
        name: {
          $id: "#/definitions/dataSource/properties/name",
          title: "Name",
          description: "The original data-source name",
          type: "string"
        },
        label: {
          $id: "#/definitions/dataSource/properties/label",
          title: "Label",
          description:
            "The label to be given to the data-source. When used, it overrides the original data-source name",
          type: "string"
        },
        id: {
          $id: "#/definitions/dataSource/properties/id",
          title: "Id",
          description: "The id of data-source",
          type: "integer",
          minimum: 1
        },
        indexes: {
          $id: "#/definitions/dataSource/properties/indexes",
          title: "Indexes",
          description:
            "In case of a vector data-source, represents the indexes to be presented. When is a scalar data-source, this field is not used. If it is an array data-source, and this field is not present or is empty, all the indexes should be considered",
          type: "array",
          items: {
            title: "Items",
            description: "An explanation about the purpose of this instance.",
            type: "object",
            properties: {
              index: {
                $id:
                  "#/definitions/dataSource/properties/indexes/properties/index",
                title: "Index",
                description:
                  "The index where this properties will be reflected",
                type: "integer",
                minimum: 0
              },
              scale: {
                $ref: "#/definitions/scale"
              },
              plot: {
                $ref: "#/definitions/plot"
              }
            },
            required: ["index"]
          }
        },
        scale: {
          $ref: "#/definitions/scale"
        },
        plot: {
          $ref: "#/definitions/plot"
        }
      },
      required: ["name", "id"]
    },
    scale: {
      $id: "#/definitions/scale",
      title: "Scale",
      description:
        "Scale to be used. If it is not set, the autoscale is used. If only one of the options is set (min or max), the other will still automatically autoscale",
      type: "object",
      properties: {
        min: {
          $id: "#/definitions/scale/properties/min",
          title: "Minimum",
          description: "The value to be used as fixed minimum",
          type: "number"
        },
        max: {
          $id: "#/definitions/scale/properties/max",
          title: "Maximum",
          description: "The value to be used as fixed maximum",
          type: "number"
        }
      }
    },
    plot: {
      $id: "#/definitions/plot",
      title: "Waveform Plot",
      description: "Properties of the waveform plot",
      type: "object",
      properties: {
        type: {
          $id: "#/definitions/plot/properties/type",
          title: "Type",
          description: "The plot type",
          type: "string",
          enum: ["line", "scatter"],
          default: "line"
        },
        thickness: {
          $id: "#/definitions/plot/properties/thickness",
          title: "Thickness",
          description:
            "Sets the thickness of the plot in pixels. If none is specified, the default system thickness will be used.",
          type: "number",
          minimum: 1,
          default: 1
        },
        color: {
          $id: "#/definitions/line/properties/color",
          title: "Color",
          description:
            "Sets the color of the line in hexadecimal format (e.g. #FFFFFF). If none color is provided, a random one will be used",
          type: "string"
        }
      },
      required: ["type"]
    },
    visibilityMode: {
      $id: "#/definitions/visibilityMode",
      title: "Visibility Mode",
      description: "The visibility mode of the component",
      type: "string",
      enum: ["alwaysVisible", "alwaysHidden", "onHoverVisible"],
      default: "onHoverVisible"
    },
    axisMode: {
      $id: "#/definitions/axisMode",
      title: "Axis Mode",
      description: "The mode of axis presentation",
      type: "string",
      enum: ["single", "perDatasource", "perDatasourceIndex"],
      default: "perDatasourceIndex"
    }
  },
  properties: {
    timeReference: {
      $ref: "#/definitions/timeReference"
    },
    showAlerts: {
      $id: "#/properties/showAlerts",
      title: "Show Alerts",
      description: "Weather the alerts should be shown",
      type: "boolean",
      default: true
    },
    legend: {
      $id: "#/properties/legend",
      title: "Legend",
      description: "Properties of the Legend",
      type: "object",
      properties: {
        visibilityMode: {
          $ref: "#/definitions/visibilityMode"
        }
      },
      required: ["visibilityMode"]
    },
    axis: {
      $id: "#/properties/axis",
      title: "Axis",
      description: "Properties of the Axis",
      type: "object",
      properties: {
        visibilityMode: {
          $ref: "#/definitions/visibilityMode"
        },
        axisMode: {
          $ref: "#/definitions/axisMode"
        }
      },
      required: ["visibilityMode", "axisMode"]
    },
    grid: {
      $id: "#/properties/grid",
      title: "Grid",
      description: "Properties of the Grid",
      type: "object",
      properties: {
        visibilityMode: {
          $ref: "#/definitions/visibilityMode"
        },
        size: {
          $id: "#/properties/grid/properties/size",
          title: "Size",
          description: "The grid size.",
          type: "object",
          properties: {
            x: {
              $id: "#/properties/grid/properties/size/properties/x",
              title: "Vertical",
              description: "Number of Vertical Lines",
              type: "number",
              minimum: 1,
              default: 10
            },
            y: {
              $id: "#/properties/grid/properties/size/properties/y",
              title: "Horizontal",
              description: "Number of Horizontal Lines",
              type: "number",
              minimum: 1,
              default: 10
            }
          },
          required: ["x", "y"]
        },
        thickness: {
          $id: "#/properties/grid/properties/thickness",
          title: "Thickness",
          description: "The grid thickness. If not set, the grid is disabled",
          type: "number",
          minimum: 0,
          default: 1
        }
      },
      required: ["visibilityMode"]
    },
    scale: {
      $id: "#/definitions/scale",
      title: "Scale",
      description:
        "Scale to be used. If it is not provided, the scale used is Autoscale. If only one selectected, the other will still automatically autoscale",
      type: "object",
      properties: {
        min: {
          $id: "#/definitions/scale/properties/min",
          title: "Minimum",
          description: "The value to be used as fixed minimum",
          type: "number"
        },
        max: {
          $id: "#/definitions/scale/properties/max",
          title: "Maximum",
          description: "The value to be used as fixed maximum",
          type: "number"
        }
      }
    },
    plot: {
      $id: "#/definitions/plot",
      title: "Waveform Plot",
      description: "Properties of the waveform plot",
      type: "object",
      properties: {
        type: {
          $id: "#/definitions/plot/properties/type",
          title: "Type",
          description: "The plot type",
          type: "string",
          enum: ["line", "scatter"],
          default: "line"
        },
        thickness: {
          $id: "#/definitions/plot/properties/thickness",
          title: "Thickness",
          description:
            "Sets the thickness of the plot in pixels. If none is specified, the default system thickness will be used.",
          type: "number",
          minimum: 1,
          default: 1
        },
        color: {
          $id: "#/definitions/line/properties/color",
          title: "Color",
          description:
            "Sets the color of the line. If none color is provided, a random one will be used",
          type: "string"
        }
      },
      required: ["type"]
    },
    updatePeriod: {
      $ref: "#/definitions/updatePeriod"
    },
    dataSources: {
      $id: "#/properties/dataSources",
      title: "DataSources",
      description: "List of data-sources to be added to the component",
      type: "array",
      minItems: 1,
      items: {
        $ref: "#/definitions/dataSource"
      }
    }
  },
  required: [
    "timeReference",
    "showAlerts",
    "legend",
    "axis",
    "grid",
    "updatePeriod",
    "dataSources"
  ]
};

const graphConfigOptionsDefault = {
  timeReference: {
    reference: "groundUtc"
  },
  showAlerts: true,
  legend: {
    visibilityMode: "onHoverVisible"
  },
  axis: {
    visibilityMode: "onHoverVisible",
    axisMode: "perDatasourceIndex"
  },
  grid: {
    visibilityMode: "onHoverVisible",
    size: {},
    thickness: 1
  },
  scale: {},
  plot: {
    type: "line",
    thickness: 1
  },
  updatePeriod: 2000,
  dataSources: []
};

const graphConfigOptionsUISchema = {
  timeReference: {
    reference: { "ui:widget": SelectBox },
    from: { "ui:widget": DateTimePicker },
    to: { "ui:widget": DateTimePicker },
    fromBootcountTimestamp: {
      "ui:widget": (props: any) => <InputBase maxLength={12} {...props} />
    },
    toBootcountTimestamp: {
      "ui:widget": (props: any) => <InputBase maxLength={12} {...props} />
    },
    fromBootcount: { "ui:widget": InputBase },
    toBootcount: { "ui:widget": InputBase }
  },
  showAlerts: { "ui:widget": CheckBox },
  legend: { visibilityMode: { "ui:widget": SelectBox } },
  axis: {
    visibilityMode: { "ui:widget": SelectBox },
    axisMode: { "ui:widget": "hidden" }
  },
  grid: {
    visibilityMode: { "ui:widget": SelectBox },
    size: { x: { "ui:widget": InputBase }, y: { "ui:widget": InputBase } },
    thickness: { "ui:widget": InputBase }
  },
  scale: { min: { "ui:widget": InputBase }, max: { "ui:widget": InputBase } },
  plot: {
    type: { "ui:widget": SelectBox },
    thickness: { "ui:widget": InputBase },
    color: { "ui:widget": "hidden" }
  },
  updatePeriod: { "ui:widget": InputBase },
  dataSources: { "ui:widget": "hidden" }
};

export {
  graphConfigOptionsSchema,
  graphConfigOptionsDefault,
  graphConfigOptionsUISchema
};

import React from "react";
import { Label, Box, Text } from "primitives";

export const FieldTemplate = (props) => {
  const {
    id,
    classNames,
    label,
    help,
    description,
    errors,
    children,
    hidden
  } = props;

  //Deleted duplicated description
  //TODO: Understand why DashboardComponentsConfiguration doesn't work without this
  children[0].props.schema.description = undefined;
  return (
    <Box mb={1} mt={1} className={classNames}>
      {!hidden && (
        <>
          <Label htmlFor={id}>{label}</Label>
          <Box minHeight={45}>
            <Text fontSize={14} color="text.grey">
              {description}
            </Text>
            {children}
            <Text color="text.danger">{errors}</Text>
            {help}
          </Box>
        </>
      )}
    </Box>
  );
};

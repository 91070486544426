import { connect } from "react-redux";
import { SystemDefinitionEdit } from "../components/SystemDefinitionEdit";

const mapStateToProps = (state) => ({
  unitsList: state.dataSources.units
});

const mapDispatchToProps = () => {
  return {};
};

export const SystemDefinitionEditContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemDefinitionEdit);

import React, { Component } from "react";
import { Box, Heading, Flex, Text } from "primitives";
import { Form } from "app/shared";
import { SuspenseMutation } from "app/network";
import { schemaCreate, uiSchemaCreate } from "../schemas";
import { createSatelliteDefinition } from "../services";
import { SaveButton } from "components";

export class SatelliteDefinitionCreate extends Component {
  submit = (e, { formData }) => {
    e.preventDefault();
    return createSatelliteDefinition(formData).then(() => {
      this.props.history.push("/satellite-definition");
    });
  };

  render() {
    return (
      <SuspenseMutation>
        {({ loading, error, action }) => {
          return (
            <Box color="text.default" data-testid="SatelliteDefinitionCreate">
              <Flex mb={2}>
                <Heading display={1}>Satellite definition</Heading>
              </Flex>
              <Flex flexDirection="column" bg="fill.0" p={3}>
                <Form
                  schema={schemaCreate}
                  uiSchema={uiSchemaCreate}
                  onSubmit={(data, e) => action(() => this.submit(e, data))}
                >
                  <SaveButton type="submit" disabled={loading}>
                    Save
                  </SaveButton>

                  {error ? (
                    <Box my={2}>
                      <Text color="text.danger">Error...</Text>
                    </Box>
                  ) : null}
                </Form>
              </Flex>
            </Box>
          );
        }}
      </SuspenseMutation>
    );
  }
}

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "app/store";
import { BrowserRouter } from "react-router-dom";
import "react-sortable-tree/style.css";
import Keycloak from "keycloak-js";
import { Ion, BingMapsApi } from "cesium";

const render = () =>
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById("app-root")
  );

// TODO: a nice error screen
const FailedToInitialize = <div>Failed to initialize</div>;

//Keycloak init
const KEYCLOACK_CONFIG_DIR_PATH = "/keycloack-config/";
const kc: any = Keycloak(
  `${KEYCLOACK_CONFIG_DIR_PATH}${process.env.REACT_APP_KEYCLOACK_CONFIG_FILE}`
);
kc.init({
  onLoad: "login-required",
  promiseType: "native",
  checkLoginIframe: false
}).then(
  (authenticated: boolean) => {
    if (authenticated) {
      store.getState().keycloak = kc;
      render();
    }
  },
  () => {
    ReactDOM.render(FailedToInitialize, document.getElementById("app-root"));
  }
);
//

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//Cesium Tokens
Ion.defaultAccessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2NWVhZjFlYi1kMjliLTQyMDgtOTNjYy1kZTRkMjE1Y2I3ZjIiLCJpZCI6NTIwOSwic2NvcGVzIjpbImFzciIsImdjIl0sImlhdCI6MTU0Njg2MjE3M30.HWd6secriKGDmYkwFVfAXjAx22bkDf0Y1aMDshy2lXs";
BingMapsApi.defaultKey =
  "Ah1Bzqm9NI3er6856lLuy5WToe1FubTtlalfi1qy590x1XT12rK9qAEpO0FyUDhD";

if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept("./App", () => render());
  }
}

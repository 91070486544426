import React, { Component } from "react";
import { TabInput, Form } from "app/shared";
import { SystemTreeInput } from "app/systemDefinition";
import { FrameDefinitionEditContainer } from "app/frameDefinition";
import { Box, Heading, Flex, Text } from "primitives";
import { getSatelliteDefinition, updateSatelliteDefinition } from "../services";
import { SuspenseQuery, SuspenseMutation, Suspense } from "app/network";
import { schemaEdit, uiSchemaEdit } from "../schemas";
import { SaveButton } from "components";

const tabLabels = ["Details", "Systems and Datasources"];

export class SatelliteDefinitionEdit extends Component {
  state = {
    formData: null
  };

  componentDidMount() {
    this.props.getDataSourceUnits({ page: 1, perPage: 100 }); // ask for a list of units
  }

  submit(e, { formData }) {
    e.preventDefault();
    return updateSatelliteDefinition(formData).then(() => {
      this.props.history.push("/satellite-definition");
    });
  }

  query = () => {
    const { id } = this.props.match.params;
    return getSatelliteDefinition(id);
  };

  renderDetails = (response) => {
    const { formData } = this.state;
    return (
      <Flex flexDirection="column" bg="fill.0" p={3}>
        <SuspenseMutation>
          {({ loading, error, action }) => (
            <Form
              formData={formData ? formData : response}
              schema={schemaEdit}
              uiSchema={uiSchemaEdit}
              disabled={loading}
              onChange={(form, event) =>
                this.setState({ formData: form.formData })
              }
              onSubmit={(data, e) => action(() => this.submit(e, data))}
            >
              <SaveButton type="submit" disabled={loading}>
                Save
              </SaveButton>

              {error ? (
                <Box my={2}>
                  <Text color="text.danger">Error...</Text>
                </Box>
              ) : null}
            </Form>
          )}
        </SuspenseMutation>
      </Flex>
    );
  };

  render() {
    return (
      <Suspense>
        <SuspenseQuery query={this.query}>
          {({ response }) => {
            return (
              response && (
                <Flex
                  flexDirection="column"
                  height="100%"
                  color="text.default"
                  data-testid="SatelliteDefinitionEdit"
                  overflow="visible"
                >
                  <Flex mb={2} overflow="visible">
                    <Heading display={1}>Satellite definition</Heading>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    overflow="visible"
                    height="100%"
                    mt={2}
                    p={3}
                  >
                    <TabInput
                      record={response}
                      tabs={tabLabels}
                      containers={(value, record) => (
                        <>
                          {value === 0 && this.renderDetails(response)}
                          {value === 1 && (
                            <SystemTreeInput record={record} height="100%" />
                          )}
                          {value === 2 && (
                            <FrameDefinitionEditContainer record={record} />
                          )}
                        </>
                      )}
                    />
                  </Flex>
                </Flex>
              )
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}

import React from "react";
import { Switch, Route } from "react-router-dom";
import { GroundStationEditContainer } from "./components/GroundStationEdit";
import { GroundStationConfigListContainer } from "./components/GroundStationConfigList";
import { GroundStationConfigCreateContainer } from "./components/GroundStationConfigCreate";

export const GroundStationsRouters = () => (
  <>
    <Switch>
      <Route
        path="/groundstations"
        exact
        component={GroundStationConfigListContainer}
      />
      <Route
        path="/groundstations/create"
        exact
        component={GroundStationConfigCreateContainer}
      />
      <Route
        path="/configurations/:satelliteID/:groundStationID"
        exact
        component={GroundStationEditContainer}
      />
    </Switch>
  </>
);

import React, { useState } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { TypographyProps, CommonProps, BorderProps } from "styles/constants";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

interface HTMLInput extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange: any;
  value?: any;
  position?: string;
}

interface InputProps
  extends TypographyProps,
    CommonProps,
    BorderProps,
    TestableProps {}

interface DivProps extends React.HTMLAttributes<HTMLDivElement> {
  position?: string | undefined;
}

const DateTimeStyled = styled.div<DivProps>`
  .rdtPicker{
    position: ${(props: any) => {
      return props.position || "relative";
    }} !important;
  }
  input {
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid white;
    padding-bottom: 5px;
    font-size: 16px;
    color: ${themeGet("colors.palette.brand.0")}
  }
  .rdtPicker {
    background-color: ${themeGet("colors.palette.blue.3")}
    border-color: ${themeGet("colors.palette.brand.0")}
  }
`;

const DateTimePicker = (props: HTMLInput & InputProps) => {
  const { onChange, value, position, placeholder } = props;
  const [close, setClose] = useState(false);
  return (
    <DateTimeStyled position={position}>
      <Datetime
        timeFormat="HH:mm"
        inputProps={{ placeholder }}
        utc={true}
        closeOnSelect={close}
        defaultValue={value ? new Date(value) : ""}
        onFocus={() => setClose(false)}
        onChange={(newValue) => {
          if (typeof newValue === "string") {
            onChange(newValue);
          } else {
            onChange(newValue.toDate());
          }
          setClose(true);
        }}
      />
    </DateTimeStyled>
  );
};

DateTimePicker.displayName = "DateTimePicker";

DateTimePicker.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: DateTimePicker.displayName
};

export default DateTimePicker;

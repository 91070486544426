import { AlertResponse, AlertDTO, AlertValueStatus } from "app/alert/models";

/*
 * "Data Transfer Object" Function
 */

export const convertToLocalAlertModel = (alert: AlertResponse): AlertDTO => {
  const dto: AlertDTO = {
    id: alert.id,
    satelliteId: alert.satelliteId,
    dataSourceId: alert.dataSourceId,
    dataSourceName: alert.dataSourceName,
    receiveTimestamp: alert.receiveTimestamp,
    sendTimestamp: alert.sendTimestamp,
    bootCount: alert.bootCount,
    onboardTimeMillis: alert.onboardTimeMillis,
    value: [],
    currentValue: alert.value,
    dismissed: alert.dismissed
  };
  alert.valueStatus.triggeredParameters.forEach((triggeredParam) => {
    const newValue = {
      index: triggeredParam.index,
      alert: {
        type: triggeredParam.type,
        index: triggeredParam.index
      }
    };
    if (triggeredParam.type === AlertValueStatus.AlertValue) {
      newValue.alert = { ...newValue.alert, ...triggeredParam.alertLevel };
    } else if (triggeredParam.type === AlertValueStatus.OutOfBoundsValue) {
      newValue.alert = { ...newValue.alert, ...triggeredParam.valueBoundary };
    }
    dto.value.push(newValue);
  });
  return dto;
};

import React from "react";
import styled, { css } from "styled-components";
import { Text } from "primitives";
import { TextProps } from "primitives/Text";
import { DATA_TEST_ID_ATTR_NAME } from "components/testable/models";

interface LabelProps extends TextProps {
  hidden?: boolean;
  htmlFor?: string;
  cursor?: string;
  width?: string;
}

const accessiblyHide = (props: LabelProps) =>
  props.hidden
    ? css({
        position: "absolute",
        width: "1px",
        height: "1px",
        clip: "rect(1px, 1px, 1px, 1px)"
      })
    : null;

const LabelStyled = styled(Text)<LabelProps>`
  display: block;
  text-transform: uppercase;
  width: ${(props) => (props.width ? props.width : "100%")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  ${accessiblyHide}
`;

const Label = (props: LabelProps) => <LabelStyled as="label" {...props} />;

Label.displayName = "Label";

Label.defaultProps = {
  fontSize: 6,
  fontWeight: "normal",
  color: "text.label",
  margin: 0,
  [DATA_TEST_ID_ATTR_NAME]: Label.displayName
};

export default Label;

import React, { Component } from "react";
import { Text, Flex, Box, Absolute, Relative } from "primitives";
import { AddButton, EditButton, DeleteButton, Collapsible } from "components";
import {
  schemaGenerator,
  uiSchema,
  configOptionsSchemaGenerator,
  configOptionsUISchemaGenerator
} from "../models/component";
import { Form } from "app/shared";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { fetchGroundStations, GroundStation } from "app/groundStation";

interface DashboardComponentsConfigurationState {
  selectedItemIndex: number | null;
  groundStations: GroundStation[];
}

export class DashboardComponentsConfiguration extends Component<
  any,
  DashboardComponentsConfigurationState
> {
  private gridParentContainer: any;
  state: DashboardComponentsConfigurationState = {
    selectedItemIndex: null,
    groundStations: []
  };
  constructor(props: any) {
    super(props);
    this.gridParentContainer = React.createRef();
  }

  async componentWillMount() {
    const groundStations = await fetchGroundStations();
    this.setState({ groundStations });
  }

  generateLayout() {
    const {
      schema: { formData }
    } = this.props;
    const dashboardSize = formData.size;
    const items = formData.components;
    if (!items) {
      return [];
    }
    const result = items.map((item: any, index: number) => {
      return {
        i: index.toString(),
        x: item.position.col - 1,
        y: item.position.row - 1,
        w: item.size.col,
        h: item.size.row,
        maxW: dashboardSize.col - item.position.col + 1
      };
    });
    return result;
  }

  generateLayoutItems() {
    const {
      schema: { formData }
    } = this.props;
    const items = formData.components;
    if (!items) {
      return null;
    }
    return items.map((item: any, index: number) => {
      return (
        <Flex key={index.toString()} flexDirection="column">
          <Flex p={2} flex={1} width="100%" justifyContent="flex-end">
            <EditButton
              maxHeight={30}
              ml={2}
              onClick={() => this.setState({ selectedItemIndex: index })}
            ></EditButton>
            <DeleteButton
              maxHeight={30}
              ml={2}
              onClick={() => this.deleteItem(index)}
            ></DeleteButton>
          </Flex>
          <Flex flex={3} flexDirection="column" alignItems="center">
            <Text>
              #{index}
              {item.label ? ` - ${item.label}` : ""}
            </Text>
            {item.type ? <Text bold>{item.type}</Text> : null}
          </Flex>
        </Flex>
      );
    });
  }

  addItem() {
    const {
      schema: { formData }
    } = this.props;
    let items = formData.components;
    if (!items) {
      items = [];
    }
    const newItem = {
      label: "",
      type: "",
      size: {
        row: 1,
        col: 1
      },
      position: {
        row: 1,
        col: 1
      },
      dataSources: []
    };
    items.push(newItem);
    this.props.onChange(items);
  }

  deleteItem(index: number) {
    const {
      schema: { formData }
    } = this.props;
    const items = formData.components;
    items.splice(index, 1);
    this.props.onChange(items);
  }

  updateItemLabel(index: number, label: string) {
    const {
      schema: { formData }
    } = this.props;
    const items = formData.components;
    items[index].label = label;
  }

  updatePositionsAndSizes(newLayout: any) {
    const {
      schema: { formData }
    } = this.props;
    const items = formData.components;
    items.forEach((item: any, index: number) => {
      items[index].position.row = newLayout[index].y + 1;
      items[index].position.col = newLayout[index].x + 1;
      items[index].size.row = newLayout[index].h;
      items[index].size.col = newLayout[index].w;
    });
    this.props.onChange(items);
  }

  onFormChange(form: any) {
    const { selectedItemIndex } = this.state;
    if (selectedItemIndex !== null) {
      const {
        schema: { formData }
      } = this.props;
      const items = formData.components;
      const updatedItem = form.formData;
      if (
        items[selectedItemIndex].type &&
        items[selectedItemIndex].type !== updatedItem.type
      ) {
        updatedItem.configOptions = null;
      }
      items[selectedItemIndex] = updatedItem;
      this.props.onChange(items);
    }
  }

  onConfigOptionsChange(form: any) {
    const { selectedItemIndex } = this.state;
    if (selectedItemIndex !== null) {
      const {
        schema: { formData }
      } = this.props;
      const items = formData.components;
      const updatedConfigOptions = form.formData;
      items[selectedItemIndex].configOptions = updatedConfigOptions;
      this.props.onChange(items);
    }
  }

  render() {
    const {
      schema: {
        formData,
        dataSources,
        selectedSatelliteDefinition,
        satelliteInstances
      }
    } = this.props;

    const { groundStations } = this.state;

    if (!formData) return null;
    const dashboardSize = formData.size;
    if (!dashboardSize || !dashboardSize.row || !dashboardSize.col) {
      return <Text>Dashboard size not yet defined.</Text>;
    }

    const { selectedItemIndex } = this.state;
    const layout = this.generateLayout();

    const schema = schemaGenerator(dataSources, selectedSatelliteDefinition);
    const configOptionsSchema = configOptionsSchemaGenerator(
      selectedItemIndex !== null ? formData.components[selectedItemIndex] : {},
      satelliteInstances,
      groundStations
    );
    const configOptionsUISchema = configOptionsUISchemaGenerator(
      selectedItemIndex !== null ? formData.components[selectedItemIndex] : {}
    );

    return (
      <Flex flexDirection="column">
        <Relative
          id="layout-grid-container"
          pt={"24px"}
          ref={this.gridParentContainer}
        >
          <Absolute zIndex={1} right={0} top={0}>
            <Box bg="palette.blue.4" ml="auto">
              <AddButton onClick={() => this.addItem()}>Add</AddButton>
            </Box>
          </Absolute>
          <GridLayout
            className="layout"
            layout={layout}
            cols={dashboardSize.col}
            rowHeight={100}
            width={
              this.gridParentContainer.current
                ? this.gridParentContainer.current.offsetWidth
                : 0
            }
            onDragStop={(newLayout: any) => {
              this.updatePositionsAndSizes(newLayout);
            }}
            onResizeStop={(newLayout: any) => {
              this.updatePositionsAndSizes(newLayout);
            }}
          >
            {this.generateLayoutItems()}
          </GridLayout>
        </Relative>
        <Flex bg="palette.blue.4" p={3}>
          {selectedItemIndex !== null ? (
            <Flex flexDirection="column">
              <Text fontSize={18}>Editing #{selectedItemIndex}</Text>
              <Form
                formData={formData.components[selectedItemIndex]}
                schema={schema}
                uiSchema={uiSchema}
                onChange={(form: any) => this.onFormChange(form)}
              >
                <button type="submit" className="hidden">
                  Save
                </button>
              </Form>
              <Collapsible label="Config Options">
                <Box pl={"10px"}>
                  <Form
                    formData={
                      formData.components[selectedItemIndex].configOptions
                    }
                    schema={configOptionsSchema}
                    uiSchema={configOptionsUISchema}
                    onChange={(form: any) => this.onConfigOptionsChange(form)}
                  >
                    <button type="submit" className="hidden">
                      Save
                    </button>
                  </Form>
                </Box>
              </Collapsible>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    );
  }
}

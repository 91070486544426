import { TelecommandExecutionActions } from "../actions";
import {
  TelecommandExecutionActionTypes,
  TelecommandExecutionStoreState
} from "../models";

const initialState: TelecommandExecutionStoreState = {
  telecommandId: undefined,
  telecommandRecordId: undefined,
  telecommandExecutionMode: undefined,
  telecommandFormData: null,
  telecommandPayload: undefined
};

const telecommandExecutionReducer = (
  state = initialState,
  action: TelecommandExecutionActions
): TelecommandExecutionStoreState => {
  switch (action.type) {
    case TelecommandExecutionActionTypes.SelectTelecommand:
      return {
        ...initialState,
        telecommandId: action.telecommandId,
        telecommandRecordId: action.telecommandRecordId
      };
    case TelecommandExecutionActionTypes.SelectExecutionMode:
      return {
        ...state,
        telecommandExecutionMode: action.executionMode,
        telecommandFormData: action.formData
      };
    case TelecommandExecutionActionTypes.ClearTelecommand:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default telecommandExecutionReducer;

import ace from "ace-builds/src-noconflict/ace";

ace.define(
  "ace/theme/aurora",
  ["require", "exports", "module", "ace/lib/dom"],
  (require: any, exports: any, module: any) => {
    exports.isDark = true;
    exports.cssClass = "ace-aurora";
    exports.cssText = `\
    .ace-aurora .ace_gutter {\
    background: #e8e8e8;\
    color: #AAA;\
    }\
    .ace-aurora  {\
    background: #27293A;\
    color: #7AB9DB;\
    }\
    .ace-aurora .ace_keyword {\
    color: #6E8093;\
    font-weight: bold;\
    }\
    .ace-aurora .ace_string {\
    color: #D14;\
    }\
    .ace-aurora .ace_variable.ace_class {\
    color: teal;\
    }\
    .ace-aurora .ace_constant.ace_numeric {\
    color: #099;\
    }\
    .ace-aurora .ace_constant.ace_buildin {\
    color: #0086B3;\
    }\
    .ace-aurora .ace_support.ace_function {\
    color: #0086B3;\
    }\
    .ace-aurora .ace_comment {\
    color: #998;\
    font-style: italic;\
    }\
    .ace-aurora .ace_variable.ace_language  {\
    color: #0086B3;\
    }\
    .ace-aurora .ace_paren {\
    font-weight: bold;\
    }\
    .ace-aurora .ace_boolean {\
    font-weight: bold;\
    }\
    .ace-aurora .ace_string.ace_regexp {\
    color: #009926;\
    font-weight: normal;\
    }\
    .ace-aurora .ace_variable.ace_instance {\
    color: teal;\
    }\
    .ace-aurora .ace_constant.ace_language {\
    font-weight: bold;\
    }\
    .ace-aurora .ace_cursor {\
    color: #fff;\
    }\
    .ace-aurora.ace_focus .ace_marker-layer .ace_active-line {\
    background: #4D4D4D;\
    }\
    .ace-aurora .ace_marker-layer .ace_active-line {\
    background: transparent;\
    }\
    .ace-aurora .ace_marker-layer .ace_selection {\
    background: #b5d5ff;\
    }\
    .ace-aurora.ace_multiselect .ace_selection.ace_start {\
    box-shadow: 0 0 3px 0px white;\
    }\
    .ace-aurora.ace_nobold .ace_line > span {\
    font-weight: normal !important;\
    }\
    .ace-aurora .ace_marker-layer .ace_step {\
    background: #fcff00;\
    }\
    .ace-aurora .ace_marker-layer .ace_stack {\
    background: #a4e565;\
    }\
    .ace-aurora .ace_marker-layer .ace_bracket {\
    margin: -1px 0 0 -1px;\
    border: 1px solid #c0c0c0;\
    }\
    .ace-aurora .ace_gutter-active-line {\
    background-color : #fff;\
    }\
    .ace-aurora .ace_marker-layer .ace_selected-word {\
    background: #fafaff;\
    border: 1px solid #c8c8fa;\
    }\
    .ace-aurora .ace_invisible {\
    color: #BFBFBF\
    }\
    .ace-aurora .ace_print-margin {\
    width: 1px;\
    background: transparent;\
    }\
    .ace-aurora .ace_indent-guide {\
    }`;

    var dom = require("../lib/dom");
    dom.importCssString(exports.cssText, exports.cssClass);
  }
);
(() => {
  ace.require(["ace/theme/aurora"], (m: any) => {
    if (typeof module == "object" && typeof exports == "object" && module) {
      module.exports = m;
    }
  });
})();

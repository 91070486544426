import React, { useCallback } from "react";
import { Text, Icon, Flex, Box } from "primitives";
import { SelectDataSources } from "app/shared";
import Sortable from "react-sortablejs";
import { DataSource } from "app/dataSource/models";
import styled from "styled-components";

interface DataSourceArrayFieldProps {
  onChange: (formData: any) => void;
  schema: any;
  formData: any;
}

interface DataSourceIdLabel {
  datasourceId: number;
  label: string | null;
}

const SortableStyled = styled(Sortable)`
  display: flex;
  flex-wrap: wrap;
`;

export const DataSourceArrayField = ({
  onChange,
  schema,
  formData
}: DataSourceArrayFieldProps) => {
  const { items } = schema;
  const getDataSourceName = useCallback(
    (id: number) => {
      const ds = items.dataSources.find(
        (datasource: DataSource) => datasource.id === id
      );
      if (ds) {
        return ds.name;
      } else {
        return "";
      }
    },
    [items]
  );

  const changeOrder = useCallback(
    (datasources: string[]) => {
      const newFormData: DataSourceIdLabel[] = [];
      datasources.forEach((dsId) => {
        const ds = formData.find(
          (item: DataSourceIdLabel) => item.datasourceId === Number(dsId)
        );
        newFormData.push(ds);
      });
      onChange(newFormData);
    },
    [onChange, formData]
  );

  const remove = useCallback(
    (id: number) => () => {
      onChange(
        formData.filter((item: DataSourceIdLabel) => item.datasourceId !== id)
      );
    },
    [onChange, formData]
  );

  const getSelectedSystems = (
    datasources: DataSourceIdLabel[],
    satelliteDefinition: any
  ) => {
    const selectedSystems: number[] = [];
    const iterateSystem = (system: any) => {
      if (system.dataSources) {
        system.dataSources.forEach((ds: any) => {
          const aux: DataSourceIdLabel | undefined = datasources.find(
            (datasource: DataSourceIdLabel) => datasource.datasourceId === ds.id
          );
          if (aux) {
            if (selectedSystems.indexOf(system.id) === -1) {
              selectedSystems.push(system.id);
            }
          }
        });
      }
      if (system.systems) {
        system.systems.forEach((systemAux: any) => {
          const iterationResult = iterateSystem(systemAux);
          if (iterationResult) {
            selectedSystems.push(system.id);
          }
        });
      }
      if (selectedSystems.indexOf(system.id) !== -1) {
        return true;
      } else {
        return false;
      }
    };
    iterateSystem(satelliteDefinition);
    return selectedSystems;
  };

  return (
    <Box my={2}>
      {items && items.selectedSatelliteDefinition ? (
        <SelectDataSources
          satelliteDefinition={items.selectedSatelliteDefinition}
          selectedDataSources={formData}
          selectedSystems={getSelectedSystems(
            formData,
            items.selectedSatelliteDefinition
          )}
          showConfig={true}
          onChange={({ dataSources }: any) => {
            onChange(dataSources);
          }}
        />
      ) : (
        <Text>No satellite definition selected</Text>
      )}
      <Flex flexWrap="wrap" mt={2}>
        <SortableStyled onChange={(order: string[]) => changeOrder(order)}>
          {formData.map((datasource: DataSourceIdLabel) => (
            <Flex
              border={1}
              mr={1}
              mb={1}
              p={1}
              key={`${datasource.datasourceId}-vls`}
              alignItems="center"
              bg="fill.1"
              fontSize={4}
              flex="1"
              data-id={datasource.datasourceId}
              justifyContent="space-between"
              minWidth="120px"
            >
              <Text m={1}>{`#${datasource.datasourceId} ${
                datasource.label
                  ? datasource.label
                  : getDataSourceName(datasource.datasourceId)
              }`}</Text>
              <Box overflow="visible" onClick={remove(datasource.datasourceId)}>
                <Icon name="Delete" size="14" color="text.danger" />
              </Box>
            </Flex>
          ))}
        </SortableStyled>
      </Flex>
    </Box>
  );
};

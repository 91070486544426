import React, { useState } from "react";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import { Switch, Route } from "react-router-dom";
import PageVisibility from "react-page-visibility";
import { theme } from "styles";
import { MainLayout, Header, Sider, Content, Footer } from "layout";
import { Navbar, Sidebar, FooterBar } from "navigation";
import { Box } from "primitives";
import { GlobalStyles } from "styles";
import "./App.css";
import { DashboardRouters } from "app/dashboard/routes";
import { ConstellationRouters } from "app/constellation/routes";
import { SatelliteDefinitionRouters } from "app/satelliteDefinition/routes";
import { AlertRouters } from "app/alert/routes";
import { ProcedureRouters } from "app/procedure/routes";
// TODO: remove MuiThemeProvider when we remove all material-ui dependencies
import { MuiThemeProvider } from "@material-ui/core/styles";
import materialTheme from "config/theme";
import { ScriptRouters } from "app/scripting/routes";
import { GroundStationsRouters } from "app/groundStation/routes";
import { ResourceRouters } from "app/resources/routes";
import { SatelliteFileDownloadRouters } from "./app/satelliteFileDownload/routes";
import { setRefreshData, unsetRefreshData } from "app/app/actions";
import { Feedback } from "app/app/components/Feedback";

const App: React.FC = (props: any) => {
  const [showSideBar, setSideBarVisibility] = useState(
    JSON.parse(localStorage.getItem("showSideBar") || "true")
  );

  const changeSideBarVisibility = () => {
    localStorage.setItem("showSideBar", JSON.stringify(!showSideBar));
    setSideBarVisibility(!showSideBar);
  };

  return (
    <PageVisibility
      onChange={(isVisible: boolean) =>
        isVisible
          ? props.setRefreshData(isVisible)
          : props.unsetRefreshData(isVisible)
      }
    >
      <Switch>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={materialTheme}>
            <>
              <GlobalStyles />
              <MainLayout>
                <Header bg="fill.1">
                  <Navbar />
                </Header>
                <Sider bg="fill.1" color="text.default">
                  <Sidebar
                    showSideBar={showSideBar}
                    toggleSideBar={() => changeSideBarVisibility()}
                  />
                </Sider>
                <Route
                  render={({ location }) => {
                    if (location.pathname === "/") {
                      return (
                        <Content overflow="hidden">
                          <Box p={2} height="100%" overflow="hidden">
                            <DashboardRouters />
                          </Box>
                        </Content>
                      );
                    } else {
                      return (
                        <Content overflow="auto">
                          <Box p={2}>
                            <AlertRouters />
                            <DashboardRouters />
                            <ConstellationRouters />
                            <SatelliteDefinitionRouters />
                            <ScriptRouters />
                            <ProcedureRouters />
                            <GroundStationsRouters />
                            <ResourceRouters />
                            <SatelliteFileDownloadRouters />
                          </Box>
                        </Content>
                      );
                    }
                  }}
                />

                <Footer bg="fill.2">
                  <FooterBar />
                </Footer>

                <Feedback />
              </MainLayout>
            </>
          </MuiThemeProvider>
        </ThemeProvider>
      </Switch>
    </PageVisibility>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setRefreshData: () => dispatch(setRefreshData()),
    unsetRefreshData: () => dispatch(unsetRefreshData())
  };
};

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default AppContainer;

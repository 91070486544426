import React, { Component } from "react";
import { getExecTelecommands } from "../services";
import { Pagination } from "app/shared";
import { PaginationParams } from "app/shared/list/components/Pagination";
import { TelecommandsListTable } from "./TelecommandsListTable";
import { DashboardComponents } from "app/dashboard/models";
import { Box, Text } from "primitives";
import { connect } from "react-redux";
import { selectTelecommand, selectTelecommandExecutionMode } from "../actions";
import { execTelecommand } from "../services";
import {
  TelecommandExecutionMode,
  AuroraTelecommandPayload,
  TelecommandPayload,
  TelecommandFormData,
  ExecutedTelecommand
} from "../models";
import { DataProviderResponse } from "app/network/models";
import { SatelliteInstance } from "app/satellite/models";
import { constants } from "config";
import { Passage } from "app/visibilityWindow/models";

interface TelecommandsListBaseProps {
  options: any;
  satellite: SatelliteInstance;
  selectedPassage?: Passage;
  dispatchTelecommandSelectAction: (
    telecommandId: string,
    telecommandRecordId: number
  ) => void;
  dispatchTelecommandExecutionModeAction: (
    executionMode: TelecommandExecutionMode,
    formData: TelecommandFormData
  ) => void;
}

interface TelecommandsListBaseState {
  executedTelecommands: ExecutedTelecommand[] | null;
  totalInitial: number;
  error: boolean;
  pagination: PaginationParams;
}

/**
 * Primary component to display the telecommand list. Connects the list table with the pagination.
 */
class TelecommandsListBase extends Component<
  TelecommandsListBaseProps,
  TelecommandsListBaseState
> {
  private interval: number | null;
  private willUnmount: boolean;
  // any for now until we add typings to dashboard.
  static defaultProps: any;

  constructor(props: TelecommandsListBaseProps) {
    super(props);
    this.interval = null;
    this.willUnmount = false;
    this.state = {
      executedTelecommands: null,
      totalInitial: 0,
      error: false,
      pagination: {
        page: props.options.page || 1,
        perPage: props.options.perPage || 10
      }
    };

    this.resendTelecommand = this.resendTelecommand.bind(this);
    this.reuseTelecommand = this.reuseTelecommand.bind(this);
  }

  /**
   * Sets the pagination for the telecommands list. If no pagination info is provided
   * it defaults back to null
   * @param pagination - object with page and perPage keys or null
   * @param cb - function to be executed once the pagination has been stored in the compontent state
   */
  setPagination(pagination: PaginationParams | null, cb: () => void) {
    const paginationParams = pagination || {
      page: this.props.options.page || 1,
      perPage: this.props.options.perPage || 10
    };

    // TODO: is this the best way to express this or is there a way to easily transform the callback into a promise?
    this.setState(
      {
        pagination: paginationParams
      },
      cb
    );
  }

  getExecTelecommandsList() {
    const { satellite } = this.props;
    const pagination = this.state.pagination;
    this.clearInterval();
    return getExecTelecommands(satellite.id, pagination)
      .then((response: DataProviderResponse) => {
        if (!this.willUnmount) {
          this.setState({
            executedTelecommands: response.data,
            totalInitial: response.total || 0
          });
        }

        return response;
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.interval = setTimeout(
          () => this.getExecTelecommandsList(),
          constants.timer.defaultGetExecutedTelecommands
        );
      });
  }

  resendTelecommand(
    satId: number,
    telecommandExecutionPayload: AuroraTelecommandPayload
  ) {
    const { selectedPassage } = this.props;
    if (selectedPassage && selectedPassage.passageID) {
      telecommandExecutionPayload.setPassageId(selectedPassage.passageID);

      execTelecommand(satId, telecommandExecutionPayload.toOutputModel())
        .then(() => {
          // Reset pagination
          this.setPagination(null, () => this.getExecTelecommandsList());
        })
        // eslint-disable-next-line no-console
        .catch((err: any) => console.error(err));
    } else {
      // eslint-disable-next-line no-console
      console.error("Error sending telecommand: no ground station id defined"); // TODO: we might want to show an alert to the user
    }
  }

  reuseTelecommand(
    telecommandRecordId: number,
    telecommandId: string,
    telecommandExecutionPayload: TelecommandPayload
  ) {
    this.props.dispatchTelecommandSelectAction(
      telecommandId,
      telecommandRecordId
    );
    const formData = AuroraTelecommandPayload.build(
      telecommandExecutionPayload
    ).toFormData();

    this.props.dispatchTelecommandExecutionModeAction(
      TelecommandExecutionMode.Form,
      formData
    );
  }

  componentDidMount() {
    this.interval = setTimeout(() => this.getExecTelecommandsList(), 0);
  }

  componentWillUnmount() {
    this.clearInterval();
    this.willUnmount = true;
  }

  clearInterval() {
    if (this.interval) {
      clearTimeout(this.interval);
    }
  }

  render() {
    const { executedTelecommands, totalInitial } = this.state;
    const { options } = this.props;
    return (
      <>
        {options.label && (
          <Text fontSize={18} m="10px 0">
            {options.label}
          </Text>
        )}
        <Box color="text.default" bg="fill.0" data-tesid="TelecommandsList">
          {executedTelecommands && (
            <>
              <TelecommandsListTable
                executedTelecommands={executedTelecommands}
                resendTelecommandAction={this.resendTelecommand}
                reuseTelecommandAction={this.reuseTelecommand}
              />
              <Pagination
                onChange={(pagination: PaginationParams) =>
                  this.setPagination(pagination, () => {
                    this.getExecTelecommandsList();
                  })
                }
                total={totalInitial}
              />
            </>
          )}
        </Box>
      </>
    );
  }
}

TelecommandsListBase.defaultProps = {
  ...DashboardComponents.TelecommandsList
};

const mapStateToProps = (state: any) => {
  return {
    selectedPassage: state.visibilityWindow.selectedPassage
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatchTelecommandSelectAction: (
      telecommandId: string,
      telecommandRecordId: number
    ) => dispatch(selectTelecommand(telecommandId, telecommandRecordId)),

    dispatchTelecommandExecutionModeAction: (
      executionMode: TelecommandExecutionMode,
      formData: TelecommandFormData
    ) => dispatch(selectTelecommandExecutionMode(executionMode, formData))
  };
};

export const TelecommandsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(TelecommandsListBase);

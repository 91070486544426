import React, { FC, Component } from "react";
import styled from "styled-components";
import { Button, Box, Grid, Text, Flex, Icon } from "primitives";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import {
  ExecutionId,
  Execution,
  ExecutionState,
  Script,
  ExecutionStateUtils,
  ScriptExecutionListItem,
  ExecuteScriptCommand
} from "app/scripting/models";
import { AnyAction } from "redux";
import {
  executeScript,
  selectScriptExecution,
  terminateExecution,
  fetchRunningScriptAction
} from "app/scripting/actions";
import { ProcedureStep, Procedure as ProcedureModel } from "../models";
import { fetchProceduresAction, fetchProcedureAction } from "../actions";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  CustomSelect as Select
} from "components";
import { fetchScript } from "app/scripting/services";
import { SatelliteInstance } from "app/satellite/models";
import { Passage } from "app/visibilityWindow/models";
import config from "config/constants";

interface ExecutionAwareProcedureOptionProps {
  isRunning: boolean;
  label: string;
}

/**
 * Custom option renderer for the list of procedures
 */
const ExecutionAwareProcedureOption: FC<ExecutionAwareProcedureOptionProps> = (
  props: ExecutionAwareProcedureOptionProps
) => {
  return (
    <Flex alignItems="center">
      <Box pr={2}>
        {props.isRunning ? (
          <Icon name="Alert" color="text.danger" size={10} />
        ) : (
          <Box width={10} />
        )}
      </Box>
      <Box>{props.label}</Box>
    </Flex>
  );
};

interface ExecutedStep {
  stepName: string;
  executionId: ExecutionId;
  executionState?: ExecutionState;
}

/***
 * Component execution indicador: Component that shows a step execution status in a color
 * coded way.
 */
const IndicatorLight = styled(Box)``;
IndicatorLight.defaultProps = {
  size: "20px",
  borderRadius: "50%",
  margin: "0 auto"
};

interface ExecutionStatusProps {
  stepStatus?: ExecutedStep;
}

const ExecutionStateIndicator: FC<ExecutionStatusProps> = ({ stepStatus }) => {
  if (stepStatus && stepStatus.executionState) {
    const state = stepStatus.executionState;
    if (ExecutionStateUtils.isTerminatedSuccessfully(state)) {
      return <IndicatorLight bg="palette.green.1" />;
    } else if (ExecutionStateUtils.isFailed(state)) {
      return <IndicatorLight bg="palette.red.1" />;
    } else if (
      ExecutionStateUtils.isRunning(state) ||
      ExecutionStateUtils.isBlocked(state)
    ) {
      return <IndicatorLight bg="palette.orange.0" />;
    } else {
      return <IndicatorLight bg="palette.grey.2" />;
    }
  } else {
    return <IndicatorLight bg="palette.grey.2" />;
  }
};

/***
 * Procedure selector: Select that allows the user to choose which procedue to execute.
 */
interface ProcedureSelectorProps {
  procedures: ProcedureModel[];
  proceduresWithScriptExecutions: ScriptExecutionListItem[];
  selectedProcedure?: ProcedureModel | any;
  onSelect: (selectedProcedure: ProcedureModel) => void;
}

class ProcedureSelector extends Component<ProcedureSelectorProps> {
  constructor(props: ProcedureSelectorProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate(nextProps: ProcedureSelectorProps) {
    const selectedProcedureChanged =
      (nextProps.selectedProcedure && !this.props.selectedProcedure) ||
      (this.props.selectedProcedure && !nextProps.selectedProcedure) ||
      (this.props.selectedProcedure &&
        nextProps.selectedProcedure &&
        this.props.selectedProcedure.id !== nextProps.selectedProcedure.id) ||
      false;

    return (
      nextProps.procedures.length !== this.props.procedures.length ||
      nextProps.proceduresWithScriptExecutions.length !==
        this.props.proceduresWithScriptExecutions.length ||
      selectedProcedureChanged
    );
  }

  render() {
    const {
      procedures,
      proceduresWithScriptExecutions,
      selectedProcedure
    } = this.props;

    const options =
      procedures &&
      procedures.map((procedure: ProcedureModel) => {
        const procedureHasExecutions = proceduresWithScriptExecutions.some(
          (exec) => exec.procedure.id === procedure.id
        );
        return {
          value: procedure.id,
          label: procedure.name,
          isRunning: procedureHasExecutions
        };
      });

    const selectedValue = selectedProcedure && {
      ...selectedProcedure,
      value: selectedProcedure.id,
      label: selectedProcedure.name
    };

    return (
      <Select
        placeholder="Select Procedure"
        onChange={this.handleChange}
        customOption={ExecutionAwareProcedureOption}
        value={(selectedProcedure && selectedValue) || ""}
        border={1}
        options={options}
        color="text.default"
      />
    );
  }

  private handleChange(e: any) {
    const procedure = this.props.procedures.find(
      (prodecure) => prodecure.id === parseInt(e.value, 10)
    );
    if (procedure) {
      this.props.onSelect(procedure);
    }
  }
}

/***
 * Procedure execution control: component that is on the footer of the main component and allows the
 * user to stop an execution or proceed to the next step in the procedure if we're operating in
 * stepwise execution mode.
 */
interface ProcedureExecutionControlProps {
  stepwiseExecution: boolean;
  currentScriptExecution?: Execution;
  nextStep?: ProcedureStep;
  stopExecutionAction: () => void;
  executeNextStep: () => void;
}

class ProcedureExecutionControl extends Component<
  ProcedureExecutionControlProps
> {
  shouldComponentUpdate(nextProps: ProcedureExecutionControlProps) {
    const { currentScriptExecution, nextStep, stepwiseExecution } = this.props;

    const currentExecutionChanged =
      (nextProps.currentScriptExecution && !currentScriptExecution) ||
      (currentScriptExecution && !nextProps.currentScriptExecution) ||
      (currentScriptExecution &&
        nextProps.currentScriptExecution &&
        currentScriptExecution.id !== nextProps.currentScriptExecution.id) ||
      false;

    const nextStepChanged =
      (nextProps.nextStep && !nextStep) ||
      (nextStep && !nextProps.nextStep) ||
      (nextStep &&
        nextProps.nextStep &&
        nextStep.name !== nextProps.nextStep.name) ||
      false;

    const executionStateChanged =
      (currentScriptExecution &&
        nextProps.currentScriptExecution &&
        ExecutionStateUtils.isActive(currentScriptExecution.state) !==
          ExecutionStateUtils.isActive(
            nextProps.currentScriptExecution.state
          )) ||
      false;

    return (
      nextProps.stepwiseExecution !== stepwiseExecution ||
      currentExecutionChanged ||
      nextStepChanged ||
      executionStateChanged
    );
  }

  render() {
    const {
      nextStep,
      currentScriptExecution,
      stepwiseExecution,
      executeNextStep,
      stopExecutionAction
    } = this.props;
    const hasNextStep = Boolean(nextStep);
    const hasExecutedScripts = Boolean(currentScriptExecution);
    const isLastStep = !hasNextStep && hasExecutedScripts;
    const executionInProgress =
      hasExecutedScripts &&
      currentScriptExecution &&
      ExecutionStateUtils.isActive(currentScriptExecution.state);

    return (
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        p={1}
      >
        <Box>
          <Button
            size="small"
            disabled={!executionInProgress}
            onClick={stopExecutionAction}
          >
            <Icon name="Stop" />
          </Button>
        </Box>
        {stepwiseExecution && (
          <Box>
            <Button
              size="small"
              onClick={executeNextStep}
              disabled={executionInProgress || isLastStep}
            >
              <Icon name="Play" />
            </Button>
          </Box>
        )}
      </Flex>
    );
  }
}

/***
 * Procedure steps: Component that displays a list of all the steps in a procedure.
 */
interface ProcedureStepsProps {
  currentScriptExecution?: Execution;
  procedure: ProcedureModel;
  stepwiseExecution: boolean;
  executedSteps: ExecutedStep[];
  onSelectStep: (step: ProcedureStep) => Promise<void>;
  onReplayStep: (step: ProcedureStep) => Promise<void>;
  isStepExecuted: (step: ProcedureStep) => boolean;
}

class ProcedureSteps extends Component<ProcedureStepsProps> {
  constructor(props: ProcedureStepsProps) {
    super(props);
    this.isStepActive = this.isStepActive.bind(this);
  }

  private isStepActive(step: ProcedureStep): boolean {
    const { currentScriptExecution, executedSteps } = this.props;
    if (currentScriptExecution) {
      const idx = executedSteps.findIndex(
        (candidateStep) =>
          step.name === candidateStep.stepName &&
          currentScriptExecution.id === candidateStep.executionId
      );
      return idx !== -1;
    } else {
      return false;
    }
  }

  private findStepExecution(step: ProcedureStep): ExecutedStep | undefined {
    const { executedSteps } = this.props;
    const idx = executedSteps.findIndex((it) => it.stepName === step.name);
    if (idx !== -1) {
      return executedSteps[idx];
    } else {
      return undefined;
    }
  }

  render() {
    const {
      procedure,
      stepwiseExecution,
      onSelectStep,
      isStepExecuted,
      onReplayStep
    } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="75%">Step</TableCell>
            <TableCell colSpan={2}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {procedure.steps.map((step, i) => (
            <TableRow key={i}>
              <TableCell width="75%">
                <Button
                  onClick={async () => await onSelectStep(step)}
                  width={1}
                  variant={this.isStepActive(step) ? "info" : "default"}
                  disabled={stepwiseExecution}
                >
                  <Text caps>{step.name}</Text>
                </Button>
              </TableCell>
              <TableCell width="15%">
                {!stepwiseExecution && isStepExecuted(step) && (
                  <Button
                    size="small"
                    onClick={async () => await onReplayStep(step)}
                  >
                    <Icon name="Replay" width="16px" height="16px" />
                  </Button>
                )}
              </TableCell>
              <TableCell width="10%">
                <ExecutionStateIndicator
                  stepStatus={this.findStepExecution(step)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

/***
 * Component that allows the user to choose a procedure and execute its steps. The user can run in "stepwise" mode, meaning
 * that the scripts will be executed one after the other by just pressing the "next" button. In free mode, the user is able to
 * run the scripts in any order.
 */
interface ProcedureProps {
  name: string;
  procedures: ProcedureModel[];
  runningScripts: ScriptExecutionListItem[];
  selectedProcedure: ProcedureModel;
  selectedSatellite: SatelliteInstance;
  selectedPassage?: Passage;
  currentScriptExecution?: Execution;
  getRunningScripts: () => Promise<ScriptExecutionListItem[]>;
  execute: (params: ExecuteScriptCommand) => Promise<Execution>;
  fetchScript: (id: string) => Promise<Script>;
  getDetails: (executionId: ExecutionId) => Promise<Execution>;
  stopExecution: (executionId: ExecutionId) => Promise<void>;
  getProcedures: () => Promise<ProcedureModel[]>;
  getProcedure: (procedureId: number) => Promise<ProcedureModel | null>;
}

interface ProcedureExecutionState {
  executedSteps: ExecutedStep[];
  stepwiseExecution: boolean;
  nextStep?: ProcedureStep;
}

class BaseProcedure extends Component<ProcedureProps, ProcedureExecutionState> {
  private runningScriptsTimer?: number = undefined;

  constructor(props: ProcedureProps) {
    super(props);
    this.state = {
      executedSteps: [],
      stepwiseExecution: true,
      nextStep: props.selectedProcedure && props.selectedProcedure.steps[0]
    };
  }

  componentDidMount() {
    this.props.getProcedures();
    this.runningScriptsLoop();
  }

  /**
   * This loop keeps track of any incoming updates to the running scripts in background
   */
  private async runningScriptsLoop() {
    const runningScripts = await this.props.getRunningScripts();

    // set the current script execution if none is set
    if (!this.props.currentScriptExecution && this.props.selectedProcedure) {
      const candidates = runningScripts
        .filter((it) => it.procedure.id === this.props.selectedProcedure.id)
        .sort((a, b) =>
          a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0
        );
      if (candidates.length > 0) {
        await this.props.getDetails(candidates[0].id);
      }
    }

    this.runningScriptsTimer = setTimeout(() => {
      this.runningScriptsLoop();
    }, config.timer.runningScripts);
  }

  componentWillUnmount() {
    if (this.runningScriptsTimer) {
      clearTimeout(this.runningScriptsTimer);
      this.runningScriptsTimer = undefined;
    }
  }

  /***
   * When the script is running we will need to update the status indicador light, the next state, and the steps themselves.
   * In order to keep the amount of updates under control, we have put in place a series of guards.
   */
  componentDidUpdate(prevProps: ProcedureProps) {
    // Execution status indicator light update
    const {
      currentScriptExecution,
      selectedProcedure,
      runningScripts
    } = this.props;
    const { executedSteps } = this.state;

    if (currentScriptExecution) {
      const steps = executedSteps.map((it) => it.executionId);
      const stepIdx = steps.indexOf(currentScriptExecution.id);
      if (stepIdx !== -1) {
        const updatedState = executedSteps.concat([]);
        if (
          updatedState[stepIdx].executionState !== currentScriptExecution.state
        ) {
          updatedState[stepIdx].executionState = currentScriptExecution.state;
          this.setState({ executedSteps: updatedState });
        }
      }
    }

    // Execution status
    const isFirstTimeSelection =
      !prevProps.selectedProcedure && selectedProcedure;
    const selectedProcedureChanged =
      prevProps.selectedProcedure &&
      selectedProcedure &&
      prevProps.selectedProcedure.id !== selectedProcedure.id;

    if (isFirstTimeSelection || selectedProcedureChanged) {
      // Check if there are any running scripts that are part of this procedure.
      const candidates = runningScripts
        .filter((it) => it.procedure.id === selectedProcedure.id)
        .sort((a, b) =>
          a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
        )
        .map((prevExec) => {
          const targetStep = selectedProcedure.steps.find(
            (step) => step.scriptId === prevExec.scriptId
          );
          if (targetStep) {
            return {
              stepName: targetStep.name,
              executionId: prevExec.id,
              executionState: prevExec.state
            };
          } else {
            return {
              stepName: "",
              executionId: prevExec.id,
              executionState: prevExec.state
            };
          }
        })
        .filter((it) => it.stepName !== "");

      // If there are candidate executions, we take those into account
      const firstStep = selectedProcedure.steps[0];
      const bestCandidate =
        candidates.length > 0
          ? selectedProcedure.steps.find(
              (s) => s.name === candidates[0].stepName
            )
          : undefined;

      // set the component state so that the executed step index and the next step matches our expectation.
      this.setState({
        executedSteps: candidates,
        nextStep: bestCandidate ? this.findNextStep(bestCandidate) : firstStep
      });
    }
  }

  private async selectStep(step: ProcedureStep) {
    const stepAlreadyExecuted = this.state.executedSteps.find(
      (execution) => execution.stepName === step.name
    );
    if (stepAlreadyExecuted) {
      await this.props.getDetails(stepAlreadyExecuted.executionId);
    } else {
      await this.executeStep(step);
    }
  }

  private async executeStep(step: ProcedureStep) {
    // Ensure that we're in a passage and that there is a satellite selected
    const passage = this.props.selectedPassage;
    const procedureId = this.props.selectedProcedure.id;
    const selectedSatellite = this.props.selectedSatellite;

    if (selectedSatellite) {
      const satelliteDefinitionId =
        selectedSatellite.satelliteDefinitionSummary.satelliteDefinitionId;
      const satelliteId = selectedSatellite.id;
      const script = await this.props.fetchScript(step.scriptId.toString());
      const execution = await this.props.execute({
        scriptId: script.id,
        procedureId,
        satelliteId,
        satelliteDefinitionId,
        passageId: passage ? passage.passageID : null
      });
      const stepDetails = {
        stepName: step.name,
        executionId: execution.id,
        executedState: execution.state
      };

      // Update the component state by concatenating the step name
      const updatedState = this.state.executedSteps.filter(
        (es) => es.stepName !== step.name
      );

      this.setState({
        executedSteps: updatedState.concat([stepDetails]),
        nextStep: this.findNextStep(step)
      });
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `Missing required parameter to execute script: PassageId is: ${JSON.stringify(
          passage
        )} / Selected satellite is: ${JSON.stringify(selectedSatellite)}`
      );
    }
  }

  private async replayStep(step: ProcedureStep) {
    // Ensure there is no script already executing
    const candidateExecutions = this.state.executedSteps.filter(
      (es) => es.stepName === step.name
    );
    if (candidateExecutions.length > 1) {
      console.warn(
        `More than one candidate execution for step: ${
          step.name
        }: ${JSON.stringify(candidateExecutions)}`
      );
    }
    const currentStatus =
      candidateExecutions.length > 0
        ? candidateExecutions[0].executionState
        : null;
    const scriptAlreadyExecuting =
      currentStatus && ExecutionStateUtils.isActive(currentStatus);
    if (!scriptAlreadyExecuting) {
      await this.executeStep(step);
    } else {
      await this.stopCurrentExecution();
      await this.executeStep(step);
    }
    // TODO: we should give some feedback to the user
  }

  render() {
    const {
      procedures,
      runningScripts,
      selectedProcedure,
      currentScriptExecution
    } = this.props;
    const { nextStep, stepwiseExecution, executedSteps } = this.state;
    return (
      <>
        <Text fontSize={18} m="10px 0">
          {this.props.name}
        </Text>
        <Grid
          gridTemplateRows="0.5fr 5fr 0.5fr"
          height="100%"
          bg="fill.0"
          overflow="visible"
        >
          <Box overflow="visible" color="text.default">
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              p={1}
              overflow="visible"
            >
              <Box overflow="visible" width="90%">
                <ProcedureSelector
                  procedures={procedures}
                  proceduresWithScriptExecutions={runningScripts}
                  selectedProcedure={selectedProcedure}
                  onSelect={(procedure) =>
                    this.props.getProcedure(procedure.id)
                  }
                />
              </Box>
              <Box overflow="visible" ml={2}>
                <Button
                  size="small"
                  onClick={() => this.toggleStepwiseExecution()}
                >
                  <Text>
                    {stepwiseExecution ? (
                      <Icon name="Lock" width="16px" height="16px" />
                    ) : (
                      <Icon name="Unlock" width="16px" height="16px" />
                    )}
                  </Text>
                </Button>
              </Box>
            </Flex>
          </Box>

          <Box overflow="visible">
            {selectedProcedure && (
              <ProcedureSteps
                currentScriptExecution={currentScriptExecution}
                procedure={selectedProcedure}
                stepwiseExecution={stepwiseExecution}
                executedSteps={executedSteps}
                onSelectStep={async (step: ProcedureStep) => {
                  await this.selectStep(step);
                }}
                onReplayStep={async (step: ProcedureStep) => {
                  await this.replayStep(step);
                }}
                isStepExecuted={(step: ProcedureStep) =>
                  this.isStepExecuted(step)
                }
              />
            )}
          </Box>
          <Box overflow="visible">
            {selectedProcedure && (
              <ProcedureExecutionControl
                stopExecutionAction={async () => {
                  await this.stopCurrentExecution();
                }}
                currentScriptExecution={currentScriptExecution}
                nextStep={nextStep}
                executeNextStep={async () => {
                  await this.executeNextStep(nextStep);
                }}
                stepwiseExecution={stepwiseExecution}
              />
            )}
          </Box>
        </Grid>
      </>
    );
  }

  private async stopCurrentExecution() {
    const { currentScriptExecution, stopExecution } = this.props;
    if (currentScriptExecution) {
      await stopExecution(currentScriptExecution.id);
    }
  }

  private async executeNextStep(nextStep?: ProcedureStep) {
    if (nextStep) {
      await this.executeStep(nextStep);
    }
  }

  private toggleStepwiseExecution() {
    const current = this.state.stepwiseExecution;
    this.setState({ stepwiseExecution: !current });
  }

  private isStepExecuted(step: ProcedureStep): boolean {
    const { currentScriptExecution } = this.props;
    const { executedSteps } = this.state;
    if (currentScriptExecution) {
      const idx = executedSteps.findIndex(
        (candidateStep) => step.name === candidateStep.stepName
      );
      return idx !== -1;
    } else {
      return false;
    }
  }

  private findNextStep(step: ProcedureStep): ProcedureStep | undefined {
    const { selectedProcedure } = this.props;
    if (selectedProcedure) {
      const idx = selectedProcedure.steps.findIndex(
        (candidateStep) => step.name === candidateStep.name
      );
      const nextIdx = idx + 1;
      if (idx !== -1 && nextIdx < selectedProcedure.steps.length) {
        return selectedProcedure.steps[nextIdx];
      }
    }
  }
}

const mapStateToProps = (state: any) => ({
  procedures: state.procedures.procedures,
  runningScripts: state.scriptExecution.runningScripts,
  selectedPassage: state.visibilityWindow.selectedPassage,
  selectedProcedure: state.procedures.selectedProcedure,
  selectedSatellite: state.constellations.dashboard.find(
    (satellite: SatelliteInstance) => satellite.visible
  ),
  currentScriptExecution: state.scriptExecution.currentExecution
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchScript,
  getRunningScripts: (): Promise<ScriptExecutionListItem[]> =>
    dispatch(fetchRunningScriptAction()),
  getProcedures: () => dispatch(fetchProceduresAction()),
  getProcedure: (id: number): Promise<ProcedureModel | null> =>
    dispatch(fetchProcedureAction(id)),
  execute: (cmd: ExecuteScriptCommand): Promise<Execution> => {
    // Redux thunk and associated types are messed up -> calling dispatch returns the ThunkAction's result type,
    // therefore we can safely cast this, but the type juggling looks super ugly...
    const res = dispatch(executeScript(cmd)) as any;
    return res as Promise<Execution>;
  },
  getDetails: (executionId: ExecutionId): Promise<Execution> => {
    const res = dispatch(selectScriptExecution(executionId)) as any;
    return res as Promise<Execution>;
  },
  stopExecution: (executionId: ExecutionId): Promise<void> => {
    const res = dispatch(terminateExecution(executionId)) as any;
    return res as Promise<void>;
  }
});

export const Procedure = connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseProcedure);

/* eslint-disable */
export const UPDATE_ONE = "UPDATE_ONE";
export const UPLOAD = "UPLOAD";
export const GET_MANY = "GET_MANY";
export const GET_LIST = "GET_LIST";
export const GET_ONE = "GET_ONE";
export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const DELETE_MANY = "DELETE_MANY";
export const DELETE_LIST = "DELETE_LIST";

export enum OperationType {
    UPDATE_ONE = "UPDATE_ONE",
    UPLOAD = "UPLOAD",
    GET_MANY = "GET_MANY",
    GET_LIST = "GET_LIST",
    GET_ONE = "GET_ONE",
    CREATE = "CREATE",
    UPDATE = "UPDATE",
    DELETE = "DELETE",
    DELETE_MANY = "DELETE_MANY",
    DELETE_LIST = "DELETE_LIST"
}

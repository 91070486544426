import React, { Component } from "react";
import {
  Dialog as DialogRA,
  DialogTitle,
  DialogContent,
  withStyles
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { generateReference } from "app/shared/utils";

const styles = (theme) => ({
  MuiDialogTitle: {
    paddingBottom: 0
  },
  MuiDialogContent: {
    padding: 0
  },
  button: {
    marginRight: theme.spacing.unit
  },
  deleteButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent"
      }
    }
  }
});

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reference: generateReference(),
      error: false
    };
  }

  handleOpenDialog = () => {
    this.props.showDialog(this.state.reference);
  };

  handleCloseDialog = () => {
    this.props.hideDialog();
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleAction = () => {
    if (this.props.onAction) {
      this.props.onAction();
    }
  };

  opened() {
    return (
      this.props.dialog.show &&
      this.props.dialog.reference === this.state.reference
    );
  }

  render() {
    const {
      classes,
      record,
      maxWidth,
      fullScreen,
      title,
      children,
      button,
      actions
    } = this.props;

    return (
      <>
        {button({ title, classes, handleOpenDialog: this.handleOpenDialog })}
        <DialogRA
          fullScreen={fullScreen}
          fullWidth={true}
          open={this.opened()}
          maxWidth={maxWidth}
          onClose={this.handleCloseDialog}
        >
          <DialogTitle classes={{ root: classes.MuiDialogTitle }}>
            {title} {record && <span>#{record.id}</span>}
          </DialogTitle>
          <DialogContent classes={{ root: classes.MuiDialogContent }}>
            {children}
          </DialogContent>
          {actions({
            title,
            classes,
            handleAction: this.handleAction,
            handleCloseDialog: this.handleCloseDialog
          })}
        </DialogRA>
      </>
    );
  }
}

export const DialogBase = withStyles(styles)(Dialog);

export const searchDataSourceById = (systems: any, id: number) => {
  let datasource = null;
  systems.forEach((system: any) => {
    if (system.dataSources) {
      system.dataSources.forEach((ds: any) => {
        if (ds.id === id) {
          datasource = ds;
        }
      });
    }
    if (system.systems.length > 0) {
      return searchDataSourceById(system.systems, id);
    }
  });
  return datasource;
};

export const searchTree = (tree: any, target: any) => {
  if (tree.id === target) {
    return tree;
  }
  if (tree.children) {
    for (const child of tree.children) {
      const res: any = searchTree(child, target);
      if (res) {
        return res;
      }
    }
  }
};

export const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};

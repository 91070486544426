import React from "react";
import { themeGet } from "styled-system";
import { theme } from "styles";
import { Flex } from "primitives";
import styled from "styled-components";
const year = new Date().getFullYear();

const FooterBarStyled = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  ${themeGet("mediaQueries.md")} {
    flex-direction: row;
    align-items: space-between;
    justify-content: flex-end;
  }
`;

FooterBarStyled.defaultProps = {
  height: themeGet("sizes.footerHeight")({ theme }),
  color: "text.label",
  fontSize: 3,
  borderTop: 3,
  borderColor: "border.1",
  px: 3
};

const FooterBar = () => {
  return (
    <FooterBarStyled>
      <Flex fontSize={2}>
        &copy; {year} AURORA Mission Control Platform powered by D-ORBIT
        Portugal
      </Flex>
    </FooterBarStyled>
  );
};
export default FooterBar;

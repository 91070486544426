import HttpError from "./HttpError";
import { stringify } from "qs";

export const fetchJson = (url, options) => {
  if (!options) {
    throw Error("Invalid HTTP options");
  }
  return fetch(url, options)
    .then((response) =>
      response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError((json && json.message) || statusText, status, json)
        );
      }
      return { status, headers, body, json };
    });
};

export const queryParameters = stringify;

import React, { FormEvent, useState, FC, useEffect } from "react";
import { Form } from "app/shared";
import { clone } from "utils";
import { SaveButton, CancelButton } from "components";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import {
  configOptionsSchemaGenerator,
  configOptionsUISchemaGenerator
} from "app/dashboard/models/component";
import { GroundStation } from "app/groundStation";

interface EditDashboardConfigurationModalProps {
  type: string;
  modalOpen: boolean;
  formData: any;
  updateConfigOptions: (formData: any) => void;
  closeModal: () => void;
  groundStations?: GroundStation[] | undefined;
}

const EditDashboardConfigurationModal: FC<
  EditDashboardConfigurationModalProps
> = (props) => {
  const {
    type,
    modalOpen,
    groundStations,
    formData,
    updateConfigOptions,
    closeModal
  } = props;
  const [data, setData] = useState<any>(clone(formData));

  useEffect(() => setData(clone(formData)), [modalOpen, formData]);

  const configOptionsSchema = configOptionsSchemaGenerator(
    { type },
    [],
    groundStations ? groundStations : []
  );
  const configOptionsUISchema = configOptionsUISchemaGenerator({ type });

  const submit = (e: FormEvent, { formData: formDataToSubmit }: any) => {
    e.preventDefault();
    updateConfigOptions(formDataToSubmit);
    closeModal();
  };

  return (
    <Dialog
      fullWidth={true}
      open={modalOpen}
      maxWidth="md"
      style={{ overflow: "hidden" }}
    >
      <DialogTitle>Edit Configuration Options</DialogTitle>
      <DialogContent>
        <Form
          id="edit-config-options"
          formData={JSON.parse(JSON.stringify(data))}
          schema={configOptionsSchema}
          uiSchema={configOptionsUISchema}
          onChange={(form: any, e: React.FormEvent<HTMLInputElement>) => {
            setData(form.formData);
          }}
          onSubmit={(form: any, e: React.FormEvent<HTMLInputElement>) => {
            return submit(e, form);
          }}
        >
          <></>
        </Form>
      </DialogContent>
      <DialogActions>
        <SaveButton type="submit" form="edit-config-options" mr={1}>
          Save
        </SaveButton>
        <CancelButton onClick={() => closeModal()}>Cancel</CancelButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditDashboardConfigurationModal;

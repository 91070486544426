import React, { Component, ChangeEvent } from "react";
import { TextField } from "@material-ui/core";
import { convertOnboardTime } from "app/telemetry/utils";
import moment, { Moment } from "moment";
import { DatePicker } from "components";

interface OnboardTimeFilterProps {
  classes: {
    onboardTimeInput: string;
  };
  timeFilterChanged: (from: string, to: string) => void;
}

interface OnboardTimeFilterState {
  fromBoot: string;
  toBoot: string;
  fromMillis: string;
  toMillis: string;
}

const FROM_BOOT_NAME = "fromBoot";
const TO_BOOT_NAME = "toBoot";
const FROM_MILLIS_NAME = "fromMillis";
const TO_MILLIS_NAME = "toMillis";

export class OnboardTimeFilter extends Component<
  OnboardTimeFilterProps,
  OnboardTimeFilterState
> {
  constructor(props: OnboardTimeFilterProps) {
    super(props);
    this.state = {
      fromBoot: "",
      toBoot: "",
      fromMillis: "",
      toMillis: ""
    };
  }

  render() {
    return (
      <>
        <TextField
          label="From boot"
          name={FROM_BOOT_NAME}
          InputLabelProps={{
            shrink: true
          }}
          className={this.props.classes.onboardTimeInput}
          onChange={(e) => this.onChangeOnboardTimeHandler(e)}
        />
        <TextField
          label="To boot"
          name={TO_BOOT_NAME}
          InputLabelProps={{
            shrink: true
          }}
          className={this.props.classes.onboardTimeInput}
          onChange={(e) => this.onChangeOnboardTimeHandler(e)}
        />
        <TextField
          label="From OBT (millis)"
          name={FROM_MILLIS_NAME}
          InputLabelProps={{
            shrink: true
          }}
          className={this.props.classes.onboardTimeInput}
          onChange={(e) => this.onChangeOnboardTimeHandler(e)}
        />
        <TextField
          label="To OBT (millis)"
          name={TO_MILLIS_NAME}
          InputLabelProps={{
            shrink: true
          }}
          className={this.props.classes.onboardTimeInput}
          onChange={(e) => this.onChangeOnboardTimeHandler(e)}
        />
      </>
    );
  }

  private onChangeOnboardTimeHandler(
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) {
    switch (e.target.name) {
      case FROM_BOOT_NAME:
        this.setState({ fromBoot: e.target.value }, () =>
          this.notifyOfChange()
        );
        break;
      case FROM_MILLIS_NAME:
        this.setState({ fromMillis: e.target.value }, () =>
          this.notifyOfChange()
        );
        break;
      case TO_BOOT_NAME:
        this.setState({ toBoot: e.target.value }, () => this.notifyOfChange());
        break;
      case TO_MILLIS_NAME:
        this.setState({ toMillis: e.target.value }, () =>
          this.notifyOfChange()
        );
        break;
    }
  }

  private notifyOfChange() {
    const { fromBoot, toBoot, fromMillis, toMillis } = this.state;
    if (fromBoot && toBoot && fromMillis && toMillis) {
      try {
        const from = convertOnboardTime(fromBoot, fromMillis);
        const to = convertOnboardTime(toBoot, toMillis);
        this.props.timeFilterChanged(from, to);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(
          "Cannot set the string representation of the onboard time."
        );
      }
    }
  }
}

interface DateTimeFilterProps {
  timeFilterChanged: (from: string, to: string) => void;
}

interface DateTimeFilterState {
  from?: Moment;
  to?: Moment;
}

const FROM_DATE_NAME = "fromDate";
const TO_DATE_NAME = "toDate";

export class DateTimeFilter extends Component<
  DateTimeFilterProps,
  DateTimeFilterState
> {
  constructor(props: DateTimeFilterProps) {
    super(props);
    this.state = {
      from: undefined,
      to: undefined
    };
  }

  render() {
    return (
      <>
        <DatePicker
          label="From"
          name="fromDate"
          placeholder={"Select a date"}
          handleChange={(e: { name: string; value: Date }) =>
            this.onChangeDateField(e)
          }
        />
        <DatePicker
          label="To"
          name="toDate"
          placeholder={"Select a date"}
          handleChange={(e: { name: string; value: Date }) =>
            this.onChangeDateField(e)
          }
        />
      </>
    );
  }

  private onChangeDateField(e: { name: string; value: Date }) {
    switch (e.name) {
      case FROM_DATE_NAME:
        this.setState({ from: moment.utc(e.value) }, () => {
          this.notifyOfChange();
        });
        break;
      case TO_DATE_NAME:
        this.setState({ to: moment.utc(e.value) }, () => {
          this.notifyOfChange();
        });
        break;
    }
  }

  private notifyOfChange() {
    const { from, to } = this.state;
    const iso8601Format = "YYYY-MM-DDTHH:mm:ss[Z]";
    if (from && to) {
      if (from.isValid() && to.isValid()) {
        this.props.timeFilterChanged(
          from.format(iso8601Format),
          to.format(iso8601Format)
        );
      } else {
        // eslint-disable-next-line no-console
        console.warn("Invalid dates");
      }
    }
  }
}

import {
  tableConfigOptionsSchema,
  tableConfigOptionsDefault,
  tableConfigOptionsUISchema
} from "./RealTimeTable";
import {
  labelConfigOptionsSchema,
  labelConfigOptionsDefault,
  labelConfigOptionsUISchema
} from "./Label";
import {
  graphConfigOptionsSchema,
  graphConfigOptionsDefault,
  graphConfigOptionsUISchema
} from "./RealTimeGraph";
import {
  globeConfigOptionsSchema,
  globeConfigOptionsDefault,
  globeConfigOptionsUISchema
} from "./Globe";
import {
  telecommandsExecutionConfigOptionsSchema,
  telecommandsExecutionConfigOptionsDefault,
  telecommandsExecutionConfigOptionsUISchema
} from "./TelecommandsExecution";
import {
  passageCountdownConfigOptionsSchema,
  passageCountdownConfigOptionsDefault,
  passageCountdownConfigOptionsUISchema
} from "./PassageCountdown";

export const configOptionsSchema: {
  [key: string]: { schema: any; default: any; uiSchema: any };
} = {
  RealTimeTable: {
    schema: tableConfigOptionsSchema,
    default: tableConfigOptionsDefault,
    uiSchema: tableConfigOptionsUISchema
  },
  Label: {
    schema: labelConfigOptionsSchema,
    default: labelConfigOptionsDefault,
    uiSchema: labelConfigOptionsUISchema
  },
  RealTimeGraph: {
    schema: graphConfigOptionsSchema,
    default: graphConfigOptionsDefault,
    uiSchema: graphConfigOptionsUISchema
  },
  Globe: {
    schema: globeConfigOptionsSchema,
    default: globeConfigOptionsDefault,
    uiSchema: globeConfigOptionsUISchema
  },
  TelecommandsExecution: {
    schema: telecommandsExecutionConfigOptionsSchema,
    default: telecommandsExecutionConfigOptionsDefault,
    uiSchema: telecommandsExecutionConfigOptionsUISchema
  },
  PassageCountdown: {
    schema: passageCountdownConfigOptionsSchema,
    default: passageCountdownConfigOptionsDefault,
    uiSchema: passageCountdownConfigOptionsUISchema
  }
};

import { GET_LIST, GET_ONE, CREATE, UPDATE, DELETE } from "app/network";
import { dataProvider, telemetryDataProvider } from "app/network";
import * as actions from "../actions";
import { store } from "app/store";

/**
 * @param {object} record, constellation
 * @param {object} values, satellite
 */
export const createSatelliteInstance = (record, values) => {
  const { satelliteDefinition, ...data } = values;
  data.constellationId = record.id;
  data.satelliteDefinition = satelliteDefinition;

  return dataProvider(CREATE, `satellite`, {
    data
  });
};

/**
 * @param {object} record, current satellite
 * @param {object} values, update to the satellite
 */
export const editSatelliteInstance = (record, values) => {
  const { id, satelliteDefinitionId, ...data } = values;
  data.satelliteDefinition = satelliteDefinitionId;
  delete data.organizationId;
  data.constellationId = record.id;
  return dataProvider(UPDATE, `satellite`, {
    id: record.id,
    data
  });
};

/**
 * @param {object} record, satellite to be deleted
 */
export const deleteSatelliteInstance = (record) => {
  return dataProvider(DELETE, `satellite`, {
    id: record.id
  });
};

/**
 * @param {number} id
 */
export const getSatellite = (id) => {
  store.dispatch(actions.fetchSatelliteStart());

  dataProvider(GET_ONE, `satellite`, { id })
    .then(({ data }) => {
      store.dispatch(actions.fetchSatelliteSuccess(data));
    })
    .catch((error) => {
      // store.dispatch(showNotification(error.message, "error"));
      store.dispatch(actions.fetchSatelliteError());
    });
};

/**
 *
 * @param {object} selectSystemPayload
 */
export const selectSystem = (selectSystemPayload) =>
  store.dispatch(actions.selectSystemStart(selectSystemPayload));

/**
 *
 * @param {object} dataSources
 */
export const selectDataSources = (dataSources) =>
  store.dispatch(actions.selectDataSourcesStart(dataSources));

/**
 *
 * @param {object} satellite
 * @param {object} filter
 */
export const createExportRequest = ({ satellite, filter }) => {
  return telemetryDataProvider(
    GET_LIST,
    `satellite/${satellite.id}/exportRequest`,
    {
      filter
    }
  );
};

export const getExportRequest = ({ satelliteId, requestId }) => {
  return telemetryDataProvider(
    GET_LIST,
    `satellite/${satelliteId}/exportRequest/${requestId}`
  );
};

export const getSatelliteDefinitions = () =>
  dataProvider(GET_LIST, `satellite-definition`);

/**
 * @param {number} satelliteId
 */
export const getFrames = (satelliteId, pagination) => {
  return telemetryDataProvider(GET_LIST, `satellite/${satelliteId}/frame`, {
    query: pagination
  });
};

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { Text, Flex, Absolute } from "primitives";
import { Feedback as FeedbackInterface, FeedbackStatus } from "app/app/models";
import { clearFeedback } from "app/app/actions";

interface FeedbackBaseProps {
  feedback: FeedbackInterface;
  hideFeedback: any;
}

interface FeedbackBaseContentProps {
  status: FeedbackStatus;
}

const Container = styled(Absolute)`
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
`;

const Close = styled(Absolute)`
  cursor: pointer;
`;

const ShowHideDetails = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`;

const Content = styled(Flex)`
  background-color: ${(props: FeedbackBaseContentProps) =>
    props.status === FeedbackStatus.SUCCESS
      ? themeGet("colors.palette.green.1")
      : props.status === FeedbackStatus.WARNING
      ? themeGet("colors.palette.orange.1")
      : themeGet("colors.palette.red.1")};
`;

const FeedbackBase = ({ feedback, hideFeedback }: FeedbackBaseProps) => {
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(FeedbackStatus.SUCCESS);
  const [details, setDetails] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (title && !feedback.title) {
      setTimeout(() => {
        setTitle("");
        setDetails("");
        setStatus(FeedbackStatus.SUCCESS);
        setShowDetails(false);
      }, 1000);
    } else {
      setTitle(feedback.title);
      setStatus(feedback.status);
      if (feedback.details) {
        setDetails(feedback.details);
      }
    }
  }, [feedback, title]);

  if (!title) return null;

  return (
    <Container
      zIndex={1}
      width={"300px"}
      right={"20px"}
      bottom={"40px"}
      boxShadow="0 2px 10px -10px"
      opacity={feedback.title ? 1 : 0}
    >
      <Content
        position="relative"
        flexDirection="column"
        status={status}
        p={3}
        borderRadius={2}
      >
        <Close
          onClick={() => hideFeedback()}
          right={"10px"}
          top={0}
          overflow="visible"
        >
          <Text color="white" fontSize="22px" bold>
            ×
          </Text>
        </Close>
        <Text mt={"2px"} color="white" fontSize="18px" bold>
          {title}
        </Text>
        <Flex flexDirection="column">
          {details && showDetails ? (
            <Text color="white" fontSize="16px">
              {details}
            </Text>
          ) : null}
          {details ? (
            showDetails ? (
              <ShowHideDetails
                color="white"
                onClick={() => setShowDetails(false)}
              >
                Hide details
              </ShowHideDetails>
            ) : (
              <ShowHideDetails
                color="white"
                onClick={() => setShowDetails(true)}
              >
                Show details
              </ShowHideDetails>
            )
          ) : null}
        </Flex>
      </Content>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  feedback: state.app.feedback
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    hideFeedback: () => dispatch(clearFeedback())
  };
};

export const Feedback = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FeedbackBase)
);

export interface GroundStation {
  coordinates: {
    elevation: number;
    latitude: number;
    longitude: number;
  };
  groundStationID: number;
  groundStationName: string;
  providerID: number;
  status: boolean;
  type: GroundStationType.MQTT;
}

enum GroundStationType {
  MQTT = "MQTT",
  VPN = "VPN",
  API = "API"
}

export interface GroundStationConfiguration {
  providerID?: number;
  providerName?: string;
  satelliteID: number;
  groundStationID: number;
  groundStationName?: string;
  type: GroundStationType.MQTT | GroundStationType.VPN | GroundStationType.API;
  configuration: {
    VPN: {
      IP: string;
      port: number;
      username: string;
      password: string;
    };
    MQTT: {
      IP: string;
      port: number;
      topic: string;
      username: string;
      password: string;
    };
    API: {
      username: string;
      password: string;
    };
  };
}

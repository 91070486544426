//Check if datasource config contains a given index
const dataSourceConfigContainsIndex = (
  options: any,
  dataSourceId: number,
  index: number
) => {
  let containsIndex = true;
  if (options && options.dataSources) {
    const dataSourceOptions = options.dataSources.find(
      (ds: any) => ds.id === dataSourceId
    );
    if (dataSourceOptions && dataSourceOptions.indexes) {
      containsIndex = dataSourceOptions.indexes.find(
        (i: any) => i.index === index
      );
    }
  }
  return containsIndex;
};

//Config Options - Get Time Reference label
const configOptionsGetTimeReferenceLabel = (options: any) => {
  const timeReference =
    options && options.timeReference && options.timeReference.reference
      ? options.timeReference.reference
      : null;
  switch (timeReference) {
    case "satelliteUtc":
      return "SATELLITE UTC";
    case "groundUtc":
      return "GROUND UTC";
    case "satelliteBootcountObt":
      return "ONBOARD TIME";
    default:
      return "GROUND UTC";
  }
};

//Config Options - Get Time Reference Value
const configOptionsGetTimeReferenceValue = (
  options: any,
  reading: any,
  reference: string = ""
) => {
  const timeReference =
    options && options.timeReference && options.timeReference.reference
      ? options.timeReference.reference
      : reference;
  switch (timeReference) {
    case "satelliteUtc":
      return reading.sendTimestamp;
    case "groundUtc":
      return reading.receiveTimestamp;
    case "satelliteBootcountObt":
      return `[${reading.bootCount}] ${reading.onboardTimeMillis}`;
    default:
      return reading.receiveTimestamp;
  }
};

export {
  dataSourceConfigContainsIndex,
  configOptionsGetTimeReferenceLabel,
  configOptionsGetTimeReferenceValue
};

/**
 * https://github.com/frontend-collective/react-sortable-tree
 * https://github.com/frontend-collective/react-sortable-tree/blob/master/src/node-renderer-default.js
 * Override the default component (NodeRendererDefault) for rendering nodes (but keep the scaffolding generator).
 * This is a last resort for customization - most custom styling should be able to be solved with generateNodeProps,
 * a theme or CSS rules. If you must use it, is best to copy the component in node-renderer-default.js
 * to use as a base, and customize as needed.
 */
import React, { Component } from "react";
import "./nodeRendererDefault.css";
import { Box } from "primitives";

class NodeRendererDefault extends Component {
  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      node,
      body,
      path,
      treeIndex,
      selected
    } = this.props;

    let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };

    return (
      <div style={{ height: "100%" }}>
        {toggleChildrenVisibility && node.children && node.children.length > 0 && (
          <div>
            <button
              type="button"
              className={`${
                node.expanded ? "rst__collapseButton" : "rst__expandButton"
              }`}
              style={buttonStyle}
              onClick={() =>
                toggleChildrenVisibility({
                  node,
                  path,
                  treeIndex
                })
              }
            />

            {node.expanded && (
              <div
                style={{ width: scaffoldBlockPxWidth }}
                className="rst__lineChildren"
              />
            )}
          </div>
        )}

        <div className="rst__rowWrapper">
          <div className="rst__row">
            <Box
              border={1}
              minWidth="400px"
              borderColor={selected ? "status.warning" : ""}
            >
              {body}
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

NodeRendererDefault.defaultProps = {
  toggleChildrenVisibility: null,
  body: null,
  selected: false
};

export default NodeRendererDefault;

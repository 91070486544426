import React, { useState } from "react";
import styled from "styled-components";
import { DateTimePicker } from "components";

interface DatePickerProps {
  selected?: Date;
  handleChange: any;
  name: string;
  label: string;
  placeholder?: string;
}

const Label = styled.label`
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
`;

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const DatePicker = (props: DatePickerProps) => {
  const [startDate, setStartDate] = useState(props.selected);

  const handleChange = (name: string, value: any) => {
    setStartDate(value);
    props.handleChange({ name, value });
  };

  return (
    <Container>
      <Label>{props.label}</Label>
      <DateTimePicker
        value={startDate || ""}
        onChange={(date: any) => handleChange(props.name, date)}
        position="absolute"
        {...props}
      />
    </Container>
  );
};

DatePicker.defaultProps = {
  dateFormat: "yyyy/MM/dd, HH:mm"
};

export default DatePicker;

export const generateReference = () => {
  return Math.random()
    .toString(36)
    .substr(2, 5);
};

export const compareLists = (l1, l2) => {
  return (
    l1.length === l2.length &&
    l1.sort().every((value, index) => {
      return value === l2.sort()[index];
    })
  );
};

export const isArrayOfObjects = (value) => {
  return (
    Array.isArray(value) &&
    value.every((v) => typeof v === "object" && v.constructor === Object)
  );
};

export const arrayMove = (arr, oldIndex, newIndex) => {
  while (oldIndex < 0) {
    oldIndex += arr.length;
  }
  while (newIndex < 0) {
    newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const getActiveSatellite = (satellites) => {
  return satellites.filter((satellite) => {
    return satellite.visible;
  })[0];
};

//  This is supposed to be temporary
export const isSatelliteOnOrganization = (satelliteID, satellites) => {
  return satellites.filter((satellite) => {
    return satellite.id === satelliteID;
  });
};

export const getSatelliteName = (satelliteID, satellites) => {
  return satellites.filter((satellite) => {
    return satellite.id === satelliteID;
  })[0].label;
};

export const getPaddingForCustomAspectRatio = (
  containerRef,
  desiredAspectRatio
) => {
  const width =
    containerRef && containerRef.current && containerRef.current.clientWidth;
  const height =
    containerRef && containerRef.current && containerRef.current.clientHeight;
  const aspectRatio = width && height && width > height ? width / height : null;

  if (!aspectRatio || aspectRatio <= desiredAspectRatio) return 0;

  const desiredWidth = desiredAspectRatio * height;
  const newWidthPercentage = (desiredWidth * 100) / width;

  return 100 - newWidthPercentage;
};

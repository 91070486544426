import React, { PureComponent, ChangeEvent, KeyboardEvent } from "react";
import { Flex, Button, Grid } from "primitives";
import { InputField } from "components";
import { TelecommandSpec, TelecommandFormData } from "../models";
import { TelecommandSendConfirmation } from "./TelecommandExecutionHelpers";
import ReactJson from "react-json-view";
import objectPath from "object-path";

interface TcCreationTerminalProps {
  telecommandSpec: TelecommandSpec;
  onSubmitTelecommandHandler: (data: any) => Promise<any>;
  automaticFill: boolean;
  sendConfirmation: boolean;
  preventMultiSend: boolean;
}

interface InternalTerminalState {
  payload: TelecommandFormData;
  commandString: string;
  commandArgs: string[];
  requiredArgs: string[];
  loading: boolean;
  modalOpen: boolean;
}

export class TelecommandTerminal extends PureComponent<
  TcCreationTerminalProps,
  InternalTerminalState
> {
  private tc = {};
  private curNode = null;

  constructor(props: TcCreationTerminalProps) {
    super(props);
    const defaultPayload = this.getDefaultValuesFromSpec(props.telecommandSpec);
    const payload = this.filterPayloadParams(
      props.telecommandSpec,
      defaultPayload
    );
    this.state = {
      payload: payload,
      commandString: props.telecommandSpec
        ? `${props.telecommandSpec.id} `
        : "",
      commandArgs: this.getInputSuggestionsFromPayload(payload),
      requiredArgs: this.getRequiredArgsFromSpec(props.telecommandSpec),
      loading: false,
      modalOpen: false
    };
  }

  componentWillUpdate(
    nextProps: TcCreationTerminalProps,
    nextState: InternalTerminalState
  ) {
    if (nextState.commandString !== this.state.commandString) {
      this.updatePayload(nextState.commandString);
    }
  }

  commandInputOnChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({ commandString: event.target.value });
  }

  commandInputOnKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    //If user presses TAB, autocomplete last input string
    if (event.key === "Tab") {
      event.preventDefault();
      const { commandString, commandArgs } = this.state;
      const lastString: string | undefined = commandString
        .trim()
        .split(" ")
        .pop();
      const startsWithMatches: string[] = [];
      const otherMatches: string[] = [];
      commandArgs.forEach((arg: string) => {
        if (
          lastString &&
          arg.startsWith(lastString) &&
          commandString.indexOf(arg) === -1
        ) {
          startsWithMatches.push(arg);
        } else if (
          lastString &&
          arg.indexOf(lastString) !== -1 &&
          commandString.toLowerCase().indexOf(arg.toLowerCase()) === -1
        ) {
          otherMatches.push(arg);
        }
      });
      //Has only one "startWith" match
      if (lastString && startsWithMatches.length === 1) {
        let updatedCommandString: string = commandString
          .trim()
          .concat(startsWithMatches[0].replace(lastString, ""))
          .concat("=");
        this.setState({ commandString: updatedCommandString });
      }
      //Has more than one "startWith" match
      else if (lastString && startsWithMatches.length > 1) {
        const startsWithMatchesSharedStart: string = this.sharedStart(
          startsWithMatches
        );
        let updatedCommandString: string = commandString
          .trim()
          .concat(startsWithMatchesSharedStart.replace(lastString, ""));
        this.setState({ commandString: updatedCommandString });
      }
      //Has other matches
      else if (lastString && otherMatches.length > 0) {
        let updatedCommandString: string = commandString
          .trim()
          .replace(new RegExp(`${lastString}$`), otherMatches[0])
          .concat("=");
        this.setState({ commandString: updatedCommandString });
      }
    }
    //If user presses ENTER, send telecommand
    if (event.key === "Enter") {
      this.sendTelecommand();
    }
  }

  updatePayload(commandString: string) {
    const { telecommandSpec } = this.props;
    const { requiredArgs } = this.state;
    let updatedPayload = {
      ...this.getDefaultValuesFromSpec(telecommandSpec)
    };
    commandString.split(" ").forEach((stringValue: string, index: number) => {
      //comand arg format ex: command key1=value1 key2=value2
      if (stringValue.indexOf("=") !== -1) {
        const commandKeyValue: any[] = stringValue.split("=");
        if (commandKeyValue[0] && commandKeyValue[1]) {
          const path = commandKeyValue[0];
          const value = commandKeyValue[1];
          if (path.indexOf(".") !== -1) {
            objectPath.set(
              updatedPayload,
              path,
              this.convertArgDataToCorrectDataType(
                telecommandSpec,
                path,
                value,
                updatedPayload
              )
            );
          } else {
            updatedPayload[path] = this.convertArgDataToCorrectDataType(
              telecommandSpec,
              path,
              value,
              updatedPayload
            );
          }
        }
      }
      //comand arg format ex: command value1 value2
      else if (index > 0) {
        const requiredArg = requiredArgs[index - 1];
        if (requiredArg) {
          objectPath.set(
            updatedPayload,
            requiredArg,
            this.convertArgDataToCorrectDataType(
              telecommandSpec,
              requiredArg,
              stringValue,
              updatedPayload
            )
          );
        }
      }
    });

    updatedPayload = this.filterPayloadParams(telecommandSpec, updatedPayload);
    this.setState({
      payload: updatedPayload,
      commandArgs: this.getInputSuggestionsFromPayload(updatedPayload)
    });
  }

  sendTelecommand() {
    const { payload } = this.state;
    const newPayload = { ...payload };
    const { onSubmitTelecommandHandler, preventMultiSend } = this.props;
    const cleanData = this.cleanPayload(newPayload);
    const formatedData = this.formatGroups(cleanData);
    this.setState({ loading: true, modalOpen: false });
    onSubmitTelecommandHandler(formatedData);
    if (!preventMultiSend) {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }
  }

  render() {
    const { sendConfirmation } = this.props;
    const { payload, commandString, loading, modalOpen } = this.state;
    return (
      <Grid data-testid="TelecommandTerminal" mt={2} mb={2} pl={4} pr={4}>
        <ReactJson
          src={payload}
          theme="monokai"
          name="payload"
          style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 5 }}
        />
        <Flex mt={3} alignItems="center">
          <InputField
            id="command-input"
            required={false}
            value={commandString}
            onChange={(e: any) => this.commandInputOnChange(e)}
            onKeyDown={(e: any) => this.commandInputOnKeyDown(e)}
            autoFocus={true}
            multiline={true}
            onFocus={(e) => {
              //Place cursor at the end of the input text
              const val = e.target.value;
              e.target.value = "";
              e.target.value = val;
            }}
          />
          <Button
            disabled={loading}
            ml={2}
            onClick={() =>
              sendConfirmation
                ? this.setState({ modalOpen: true })
                : this.sendTelecommand()
            }
            maxHeight={40}
          >
            Send
          </Button>
        </Flex>
        <TelecommandSendConfirmation
          cancel={() => this.setState({ modalOpen: false })}
          sendTelecommand={() => this.sendTelecommand()}
          modalOpen={modalOpen}
        />
      </Grid>
    );
  }

  //fill payload with default values
  private getDefaultValuesFromSpec(schema: TelecommandSpec) {
    const { automaticFill } = this.props;
    const payload: any = {};
    if (schema && schema.args) {
      schema.args.forEach((arg: any) => {
        if (automaticFill && arg.default) {
          if (isNaN(arg.default) || arg.argType === "Enum") {
            payload[arg.id] = arg.default;
          } else {
            payload[arg.id] = parseFloat(arg.default);
          }
        } else if (arg.groupSpec) {
          const getGroupArgs = (groupSpec: any) => {
            const groupArgs: any = {};
            groupSpec.forEach((groupArg: any) => {
              if (automaticFill && groupArg.default) {
                if (isNaN(groupArg.default) || groupArg.argType === "Enum") {
                  groupArgs[groupArg.id] = groupArg.default;
                } else {
                  groupArgs[groupArg.id] = parseFloat(groupArg.default);
                }
              } else if (groupArg.groupSpec) {
                groupArgs[groupArg.id] = {
                  ...getGroupArgs(groupArg.groupSpec)
                };
              } else {
                groupArgs[groupArg.id] = null;
              }
            });
            return groupArgs;
          };
          payload[arg.id] = { ...getGroupArgs(arg.groupSpec) };
        } else {
          payload[arg.id] = null;
        }
      });
    }
    return payload;
  }

  //filter payload parameters
  private filterPayloadParams(
    schema: any,
    payload: TelecommandFormData,
    path: string[] = []
  ) {
    this.tc = payload;
    /* eslint-disable no-unused-vars */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getParent = this.getParent;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getAbsolute = this.getAbsolute;
    /* eslint-enable no-unused-vars */
    const args = schema.args ? schema.args : schema;
    args.forEach((arg: any) => {
      if (arg.filter) {
        this.curNode = objectPath.get(this.tc, path)
          ? objectPath.get(this.tc, path)[arg.id]
          : null;
        // eslint-disable-next-line no-eval
        if (!eval(arg.filter)) {
          const auxPath: any = [].concat(path as any);
          auxPath.push(arg.id);
          objectPath.del(payload, auxPath);
        }
      }
      if (arg.groupSpec) {
        const auxPath: any = [].concat(path as any);
        auxPath.push(arg.id);
        this.filterPayloadParams(arg.groupSpec, payload, auxPath);
      }
    });
    return payload;
  }

  //get argument auto-complete suggestions from payload
  private getInputSuggestionsFromPayload(payload: TelecommandFormData) {
    const args: string[] = [];
    const keys: string[] = Object.keys(payload);
    if (keys) {
      keys.forEach((arg: string) => {
        const getGroupArgs = (groupPayload: any, commandStart: string) => {
          Object.keys(groupPayload).forEach((groupArg: any) => {
            if (
              typeof groupPayload[groupArg] === "object" &&
              groupPayload[groupArg]
            ) {
              getGroupArgs(
                groupPayload[groupArg],
                `${commandStart}.${groupArg}`
              );
            } else {
              args.push(`${commandStart}.${groupArg}`);
            }
          });
        };
        if (typeof payload[arg] === "object" && payload[arg]) {
          getGroupArgs(payload[arg], arg);
        } else {
          args.push(arg);
        }
      });
    }
    return args;
  }

  //get required arguments
  private getRequiredArgsFromSpec(schema: TelecommandSpec) {
    const args: string[] = [];
    if (schema && schema.args) {
      schema.args.forEach((arg: any) => {
        const getGroupArgs = (groupSpec: any, commandStart: string) => {
          groupSpec.forEach((groupArg: any) => {
            if (groupArg.groupSpec) {
              getGroupArgs(
                groupArg.groupSpec,
                `${commandStart}.${groupArg.id}`
              );
            } else if (groupArg.default === undefined) {
              args.push(`${commandStart}.${groupArg.id}`);
            }
          });
        };
        if (arg.groupSpec) {
          getGroupArgs(arg.groupSpec, arg.id);
        } else if (arg.default === undefined) {
          args.push(arg.id);
        }
      });
    }
    return args;
  }

  //check for the longest shared start between strings, used for auto-complete
  private sharedStart(array: string[]) {
    var A = array.concat().sort(),
      a1 = A[0],
      a2 = A[A.length - 1],
      L = a1.length,
      i = 0;
    while (i < L && a1.charAt(i) === a2.charAt(i)) i++;
    return a1.substring(0, i);
  }

  //remove not filled arguments
  private cleanPayload(payload: any) {
    const keys = Object.keys(payload);
    keys.forEach((key: string) => {
      if (payload[key] === null) {
        delete payload[key];
      } else if (typeof payload[key] === "object") {
        payload[key] = this.cleanPayload(payload[key]);
        const childKeys = Object.keys(payload[key]);
        if (childKeys.length === 0) {
          delete payload[key];
        }
      }
    });
    return payload;
  }

  //Format group arguments to the expected payload type
  private formatGroups(payload: any, path: any[] = []) {
    const keys = Object.keys(payload);
    keys.forEach((key: string) => {
      if (
        (!Array.isArray(payload[key]) || path.length > 0) &&
        typeof payload[key] === "object"
      ) {
        const formatedArray: any[] = [];
        const groupKeys = Object.keys(payload[key]);
        groupKeys.forEach((groupKey: string) => {
          if (typeof payload[key][groupKey] === "object") {
            const auxPath: any[] = [].concat(path as any);
            auxPath.push(key);
            auxPath.push(groupKey);
            if (formatedArray.length === 0) {
              formatedArray.push({
                [groupKey]: [this.formatGroups(payload[key][groupKey], auxPath)]
              });
            } else {
              formatedArray[0][groupKey] = this.formatGroups(
                payload[key][groupKey],
                auxPath
              );
            }
          } else {
            formatedArray.push({ [groupKey]: payload[key][groupKey] });
          }
        });
        payload[key] = formatedArray;
      }
    });
    return payload;
  }

  //get an argument type given the spec and the argument path
  private getArgTypeFromSpec(spec: any, stringPath: string) {
    const splittedStringPath = stringPath.split(".");
    const splittedStringPathLength = splittedStringPath.length;
    let args: any = spec.args;
    let argType = "";
    splittedStringPath.forEach((auxPath: string, index: number) => {
      const arg = args.find((auxArg: any) => auxArg.id === auxPath);
      if (arg && splittedStringPathLength - index - 1 <= 0) {
        argType = arg.argType;
      } else if (arg && arg.groupSpec) {
        args = arg.groupSpec;
      }
    });
    return argType;
  }

  //get an argument data type given the spec and the argument path
  private getDataTypeFromSpec(spec: any, stringPath: string) {
    const splittedStringPath = stringPath.split(".");
    const splittedStringPathLength = splittedStringPath.length;
    let args: any = spec.args;
    let dataType = "string";
    splittedStringPath.forEach((auxPath: string, index: number) => {
      const arg = args.find((auxArg: any) => auxArg.id === auxPath);
      if (arg && splittedStringPathLength - index - 1 <= 0) {
        dataType = arg.dataType;
      } else if (arg && arg.groupSpec) {
        args = arg.groupSpec;
      }
    });
    return dataType;
  }

  //get an argument (array) size given the spec and the argument path
  private getArgSizeFromSpec(spec: any, stringPath: string) {
    const splittedStringPath = stringPath.split(".");
    const splittedStringPathLength = splittedStringPath.length;
    let args: any = spec.args;
    let size = 0;
    splittedStringPath.forEach((auxPath: string, index: number) => {
      const arg = args.find((auxArg: any) => auxArg.id === auxPath);
      if (arg && splittedStringPathLength - index - 1 <= 0) {
        size = arg.size;
      } else if (arg && arg.groupSpec) {
        args = arg.groupSpec;
      }
    });
    return size;
  }

  //Convert argument data to correct data type
  private convertArgDataToCorrectDataType(
    telecommandSpec: TelecommandSpec,
    path: string,
    value: any,
    payload: any
  ) {
    const argType = this.getArgTypeFromSpec(telecommandSpec, path);
    const dataType = this.getDataTypeFromSpec(telecommandSpec, path);
    let arraySize: number | string = this.getArgSizeFromSpec(
      telecommandSpec,
      path
    );
    if (typeof arraySize === "string" && (arraySize as any).charAt(0) === "@") {
      const auxArraySize: string = (arraySize as any).slice(1);
      if (payload[auxArraySize] && !isNaN(payload[auxArraySize])) {
        arraySize = payload[auxArraySize];
      }
    }
    if (argType === "Array") {
      return value
        .split(",")
        .splice(0, arraySize)
        .map((valueElement: any) => {
          const auxValue =
            isNaN(valueElement) || dataType === "string"
              ? valueElement
              : parseFloat(valueElement);
          return auxValue;
        });
    } else {
      return isNaN(value) || argType === "Enum" || dataType === "string"
        ? value
        : parseFloat(value);
    }
  }

  /*
   * AUX Functions for telecommand params filter
   */

  _getParent: any = (obj: any, value: any, parent: any) => {
    let p = null;
    if (obj === value) {
      p = parent;
    } else if (Array.isArray(value)) {
      for (let val of value) {
        p = this._getParent(obj, val, parent);
        if (p != null) {
          break;
        }
      }
    } else if (value && typeof value === "object") {
      /* eslint-disable no-unused-vars */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, val] of Object.entries(value)) {
        p = this._getParent(obj, val, value);
        if (p != null) {
          break;
        }
      }
      /* eslint-enable no-unused-vars */
    }
    return p;
  };

  getParent = (node = null) => {
    let p = null;
    if (node != null) {
      p = this._getParent(node, this.tc, null);
    } else {
      p = this._getParent(this.curNode, this.tc, null);
    }
    return p;
  };

  getAbsolute = (path: string) => {
    const getAbsoluteResult = objectPath.get(this.tc, path);
    if (getAbsoluteResult || getAbsoluteResult === 0) {
      return getAbsoluteResult;
    }
    const auxPath: any = path.split(".");
    auxPath.splice(1, 0, 0);
    return objectPath.get(this.tc, auxPath);
  };
}

import {
  dataProvider,
  CREATE,
  GET_LIST,
  GET_ONE,
  UPDATE,
  DELETE
} from "app/network";
import { store } from "app/store";
import * as actions from "../actions";

export const getConstellations = () => {
  store.dispatch(actions.fetchConstellationsStart());

  return dataProvider(GET_LIST, `constellation`)
    .then(({ data }) => {
      store.dispatch(actions.fetchConstellationsSuccess(data));
      return data;
    })
    .catch((error) => {
      // store.dispatch(showNotification(error.message, "error"));
      store.dispatch(actions.fetchConstellationsError());
    });
};

export const getConstellation = (id) => {
  store.dispatch(actions.fetchConstellationStart());

  return dataProvider(GET_ONE, `constellation`, { id })
    .then(({ data }) => {
      store.dispatch(actions.fetchConstellationSuccess(data));
      addSatellitesToDashboard(data.satelliteInstances);
      return data;
    })
    .catch((error) => {
      // store.dispatch(showNotification(error.message, "error"));
      store.dispatch(actions.fetchConstellationError());
    });
};

export const createConstellation = (data) => {
  return dataProvider(CREATE, `constellation`, { data });
};

export const updateConstellation = (data) => {
  const { id, ...rest } = data;
  return dataProvider(UPDATE, `constellation`, {
    id,
    data: { name: rest.name }
  });
};

export const deleteConstellation = ({ id }) => {
  return dataProvider(DELETE, `constellation`, { id });
};

export const selectConstellation = (constellation) =>
  store.dispatch(actions.selectConstellation(constellation));

export const clearConstellation = () =>
  store.dispatch(actions.clearConstellation());

export const addSatelliteToDashboard = (satellite) =>
  store.dispatch(actions.addSatelliteToDashboard(satellite));

export const addSatellitesToDashboard = (satellites) => {
  store.dispatch(actions.addSatellitesToDashboard(satellites));
  // getSatellitesOrbits();
};

export const removeSatelliteFromDashboard = (id) =>
  store.dispatch(actions.removeSatelliteFromDashboard(id));

export const cleanSatelliteFromDashboard = () =>
  store.dispatch(actions.cleanSatelliteFromDashboard());

export const setSatelliteVisibility = (id) =>
  store.dispatch(actions.setSatelliteVisibility(id));

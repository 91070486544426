import React, { Component } from "react";
import { DropdownMenuItem } from "navigation/DropdownMenu";

export class UserManagementSelector extends Component {
  onChange() {
    // eslint-disable-next-line no-console
    console.log("TODO: Add action");
  }

  render() {
    return <DropdownMenuItem>User management</DropdownMenuItem>;
  }
}

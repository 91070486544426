import * as ActionTypes from "../models/constants";

/* dashboards */
export const addDashboard = (payload) => ({
  payload,
  type: ActionTypes.ADD_DASHBOARD
});

export const addDashboards = (payload) => ({
  payload,
  type: ActionTypes.ADD_DASHBOARDS
});

export const updateDashboard = (payload) => ({
  payload,
  type: ActionTypes.UPDATE_DASHBOARD
});

export const removeDashboard = (payload) => ({
  payload,
  type: ActionTypes.REMOVE_DASHBOARD
});

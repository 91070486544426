import React, { useCallback, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { useMutation } from "app/network";
import { Form } from "app/shared";
import { Text, Flex, Box } from "primitives";
import useToggle from "components/toggler/hooks/useToggle";
import { editDataSource } from "../services";
import { addDatasource } from "app/systemDefinition/services";
import { DataSource } from "app/dataSource/models";
import {
  dataSourceSchemaGenerator,
  dataSourceUiSchema
} from "../models/schemas";
import { AddButton, EditButton, CancelButton, SaveButton } from "components";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const selectUnitsList = createSelector(
  (state: any) => state.dataSources.units,
  (units) => units
);

interface DataSourceEditProps {
  dataSource: DataSource | null;
  onChange: (record: any) => void;
  satelliteDefinitionId?: number;
  record?: any;
}

export const DataSourceEdit = ({
  dataSource,
  onChange,
  satelliteDefinitionId,
  record
}: DataSourceEditProps) => {
  const [toggled, onToggle] = useToggle({ defaultToggled: false });
  const [formData, setFormData] = useState(dataSource);
  const { response, loading, error, action } = useMutation();
  const unitsList = useSelector(selectUnitsList);
  const schema = dataSourceSchemaGenerator(unitsList);
  const formatData = (data: DataSource) => {
    return {
      ...data,
      units: data.units && data.units.unit ? data.units : null
    };
  };

  const submit = useCallback(
    (e, form) => {
      e.preventDefault();
      setFormData(form.formData);
      const formatedData = formatData(form.formData);
      if (formatedData.id) {
        return editDataSource(dataSource, formatedData);
      } else {
        return addDatasource(satelliteDefinitionId, record.id, formatedData)
          .then((responseData: any) => {
            const newDataSource = responseData.data;
            record.dataSources.push(newDataSource);
            onToggle();
            onChange(record);
            setFormData(null);
          })
          .catch((requestError: any) => {
            //alert
          });
      }
    },
    [dataSource, onToggle, onChange, record, satelliteDefinitionId]
  );

  useEffect(() => {
    if (response) {
      const { data } = response;
      onToggle();
      if (onChange) {
        onChange(data);
      }
    }
  }, [response, onChange, onToggle]);

  return (
    <>
      {dataSource ? (
        <EditButton onClick={onToggle} />
      ) : (
        <AddButton onClick={onToggle} mr={1}>
          {" "}
          Datasource{" "}
        </AddButton>
      )}
      {toggled && (
        <Dialog fullWidth={true} open={toggled} maxWidth="md">
          <DialogTitle>
            {dataSource
              ? `Edit DataSource #${dataSource.id} ${dataSource.name}`
              : `Create Datasource`}
          </DialogTitle>
          <DialogContent>
            <Form
              id="datasource-edit"
              formData={formData}
              schema={schema}
              uiSchema={dataSourceUiSchema}
              disabled={loading}
              onSubmit={(form: any, e: React.FormEvent<HTMLInputElement>) =>
                action(() => submit(e, form))
              }
            >
              <></>
            </Form>
          </DialogContent>
          <DialogActions>
            <Flex justifyItems="flex-end" alignItems="center">
              {error && (
                <Box mx={2}>
                  <Text color="text.danger">Error...</Text>
                </Box>
              )}
              <SaveButton
                form="datasource-edit"
                type="submit"
                mr={1}
                disabled={loading}
              >
                Save
              </SaveButton>

              <CancelButton onClick={onToggle} disabled={loading}>
                Cancel
              </CancelButton>
            </Flex>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

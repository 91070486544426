import { connect } from "react-redux";
import { ConstellationEdit } from "../components/ConstellationEdit";
import { getConstellation, updateConstellation } from "app/constellation";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  selected: state.constellations.selected
});

const mapDispatchToProps = () => {
  return {
    getConstellation: (id) => getConstellation(id),
    updateConstellation: (data) => updateConstellation(data)
  };
};

export const ConstellationEditContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConstellationEdit)
);

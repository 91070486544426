import {
  FETCH_ALERT_START,
  FETCH_ALERT_SUCCESS,
  FETCH_ALERT_ERROR,
  SET_ALERTS
} from "./../models/constants";
import { AlertState } from "../models";

const initialState: AlertState = {
  loading: false,
  error: { status: false, msg: "" },
  alerts: []
};

export const alertsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_ALERT_START:
      return { ...state, loading: true };
    case FETCH_ALERT_ERROR:
      return { ...initialState, error: { status: true, msg: action.error } };
    case FETCH_ALERT_SUCCESS:
      return { ...initialState, alerts: action.payload };
    case SET_ALERTS:
      return { ...initialState, alerts: action.payload };
    default:
      return state;
  }
};

import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducers } from "./reducers";

const initialState = {};
export const history = createBrowserHistory();

const middleware = applyMiddleware(thunk);
const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(middleware)
);
export default store;

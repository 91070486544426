import { combineReducers } from "redux";
import constellationReducer from "app/constellation/reducers";
import satelliteReducer from "app/satellite/reducers";
import { dialogReducer } from "app/shared/dialog";
import dataSourcesReducer from "app/dataSource/reducers";
import dashboardReducer from "app/dashboard/reducers";
import appReducer from "app/app/reducers";
import alertReducer from "app/alert/reducers";
import { scriptExecutionReducer, scriptReducer } from "app/scripting/reducers";
import telecommandExecutionReducer from "app/telecommand/reducers/telecommandExecution";
import telecommandListReducer from "app/telecommand/reducers/telecommandList";
import { procedureReducer } from "app/procedure/reducers";
import { passageReducer } from "app/visibilityWindow";
import { groundStationReducer } from "app/groundStation/reducers";
import { resourceReducer } from "app/resources/reducers";

export const reducers = combineReducers({
  alert: alertReducer,
  constellations: constellationReducer,
  satellite: satelliteReducer,
  dialog: dialogReducer,
  dashboard: dashboardReducer,
  dataSources: dataSourcesReducer,
  groundStations: groundStationReducer,
  telecommandExecution: telecommandExecutionReducer,
  telecommandList: telecommandListReducer,

  // TODO: Potential refactor
  scripts: scriptReducer,
  scriptExecution: scriptExecutionReducer,
  procedures: procedureReducer,

  resources: resourceReducer,
  visibilityWindow: passageReducer,
  app: appReducer,
  keycloak: (keycloak = {}) => keycloak
});

import React from "react";
import { Switch, Route } from "react-router-dom";
import { ResourceListContainer } from "./components/ResourcesList";
import { ResourceCreateContainer } from "./components/ResourceCreate";
import { ResourceEditContainer } from "./components/ResourceEdit";

export const ResourceRouters = () => (
  <>
    <Switch>
      <Route
        path="/resources/create"
        exact
        component={ResourceCreateContainer}
      />
      <Route path="/resources/:id" component={ResourceEditContainer} />
      <Route path="/resources" component={ResourceListContainer} />
    </Switch>
  </>
);

import React from "react";
import { Icon, Box, Flex } from "primitives";

interface TableSortProps {
  id: string;
  name: string;
  sortBy: string | null;
  isSortAscending: boolean;
  setSort: (name: string) => void;
}

const TableSort = (props: TableSortProps) => {
  const { id, name, sortBy, isSortAscending, setSort } = props;
  return (
    <Flex onClick={() => setSort(id)} overflow={"visible"}>
      {name}
      <Box ml={1} cursor="pointer">
        <Icon
          name={!isSortAscending ? "ArrowDown" : "ArrowUp"}
          size="14"
          color={sortBy === id ? "palette.white.0" : "palette.grey.1"}
        />
      </Box>
    </Flex>
  );
};

export { TableSort };

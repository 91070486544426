import {
  ADD_DASHBOARD,
  ADD_DASHBOARDS,
  UPDATE_DASHBOARD,
  SELECT_DASHBOARD,
  REMOVE_DASHBOARD
} from "../models/constants";

const initialState = {
  list: [],
  selected: undefined
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DASHBOARD:
      return {
        ...state,
        list: state.list.concat(action.payload)
      };
    case ADD_DASHBOARDS:
      return {
        ...state,
        list: action.payload
      };
    case UPDATE_DASHBOARD:
      return updateDashboard(state, action);
    case REMOVE_DASHBOARD:
      return removeDashboard(state, action);
    case SELECT_DASHBOARD:
      return {
        ...state,
        selected: action.payload
      };
    default:
      return state;
  }
};

const updateDashboard = (state, action) => {
  return {
    ...state,
    list: state.list.map((dashboard) => {
      if (dashboard.id === action.payload.id) {
        dashboard = action.payload;
      }
      return dashboard;
    })
  };
};

const removeDashboard = (state, action) => {
  return {
    ...state,
    list: state.list.filter((dashboard) => dashboard.id !== action.payload.id)
  };
};

export default reducer;

import React, { Component, ChangeEvent } from "react";
import { Box, Heading, Flex } from "primitives";
import { Table, TableHead, TableBody, TableRow, TableCell } from "components";
import { Link } from "react-router-dom";
import { PaginationSimple } from "app/shared";
import { AddButton, EditButton, InputField } from "components";
import { SuspenseQuery, Suspense } from "app/network";
import { connect } from "react-redux";
import { Procedure } from "../models";
import { fetchProceduresAction } from "../actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ProcedureDeleteContainer } from "./ProcedureDelete";

interface ProcedureListState {
  pageSize: number;
  page: number;
  searchText: string;
}

interface ProcedureListProps {
  fetchProcedures: () => Promise<Procedure[]>;
}

export class ProcedureList extends Component<
  ProcedureListProps,
  ProcedureListState
> {
  state = {
    pageSize: 25,
    page: 0,
    searchText: ""
  };

  onChangeSearch(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const { value } = event.currentTarget;
    this.setState({ searchText: value, page: 0 });
  }

  filterProcedures(resources: Procedure[]) {
    const { pageSize, page, searchText } = this.state;
    const minIndex = page * pageSize;
    const maxIndex = minIndex + pageSize;
    const result: Procedure[] = resources
      .filter((resource: Procedure) =>
        resource.name
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase())
      )
      .slice(minIndex, maxIndex);
    return result;
  }

  render() {
    const { pageSize, page, searchText } = this.state;
    return (
      <Suspense>
        <SuspenseQuery query={this.props.fetchProcedures}>
          {({ response, reload }) => {
            const procedures = this.filterProcedures(response);
            return (
              <Box data-testid="ProcedureList">
                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                  <Heading display={1}>Procedures</Heading>
                  <Flex alignItems="flex-end">
                    <Box mr={4}>
                      <InputField
                        id="search-input"
                        label="search"
                        autoFocus={true}
                        value={searchText}
                        onChange={(
                          e:
                            | ChangeEvent<HTMLInputElement>
                            | ChangeEvent<HTMLTextAreaElement>
                        ) => this.onChangeSearch(e)}
                      />
                    </Box>
                    <Link to="/procedures/create">
                      <AddButton>Create</AddButton>
                    </Link>
                  </Flex>
                </Flex>
                <Table>
                  <TableHead>
                    <TableRow bg="fill.0">
                      <TableCell width="50">ID</TableCell>
                      <TableCell width="auto">Name</TableCell>
                      <TableCell width="50" />
                      <TableCell width="50" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {procedures.map((procedure: Procedure) => (
                      <TableRow key={procedure.id}>
                        <TableCell>#{procedure.id}</TableCell>
                        <TableCell>{procedure.name}</TableCell>
                        <TableCell>
                          <Link to={`/procedures/${procedure.id}`}>
                            <EditButton>Edit</EditButton>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <ProcedureDeleteContainer
                            procedure={procedure}
                            onChange={() => reload()}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <PaginationSimple
                  pageSize={pageSize}
                  nextPageUrl={procedures.length >= pageSize ? page + 1 : 0}
                  previousPageUrl={page - 1}
                  onChange={(newPage: any) =>
                    this.setState({
                      page: Number(newPage),
                      pageSize
                    })
                  }
                  onPageSizeChange={(newPageSize: any) =>
                    this.setState({
                      page: 0,
                      pageSize: Number(newPageSize)
                    })
                  }
                />
              </Box>
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchProcedures: () => dispatch(fetchProceduresAction())
});

export const ProcedureListContainer = connect(
  null,
  mapDispatchToProps
)(ProcedureList);

import { InputBase, SelectBox } from "app/shared";
import { DataSource } from "app/dataSource/models";
import { DashboardComponentsConfiguration } from "app/dashboard/components/DashboardComponentsConfiguration";
import {
  DashboardComponentsAll,
  DashboardComponentsNames
} from "app/dashboard/models";
import { SatelliteDefinition } from "app/satelliteDefinition/models";
import { SatelliteInstance } from "app/satellite/models";

export const schemaGenerator = (
  dataSources: DataSource[],
  satelliteDefinitions: any[],
  defaultSatelliteDefinition: number,
  selectedSatelliteDefinition: SatelliteDefinition,
  formData: any,
  satelliteInstances: SatelliteInstance[]
) => {
  return {
    type: "object",
    title: "Dashboard",
    required: [
      "name",
      "satDefinitionId",
      "size",
      "type",
      "permissions",
      "components"
    ],
    properties: {
      satDefinitionId: {
        type: "number",
        title: "Satellite definition",
        enum: satelliteDefinitions.map((satelliteDefinition) =>
          satelliteDefinition.id
            ? satelliteDefinition.id
            : satelliteDefinition.satelliteDefinitionId
        ),
        enumNames: satelliteDefinitions.map(
          (satelliteDefinition) =>
            `${satelliteDefinition.name} #${
              satelliteDefinition.id
                ? satelliteDefinition.id
                : satelliteDefinition.satelliteDefinitionId
            }`
        ),
        default: defaultSatelliteDefinition
      },
      name: {
        type: "string"
      },
      description: {
        type: ["null", "string"]
      },
      size: {
        type: "object",
        properties: {
          row: {
            type: "number"
          },
          col: {
            type: "number"
          }
        }
      },
      type: {
        type: "string",
        title: "Dashboard type",
        enum: ["PerSatellite"]
      },
      permissions: {
        type: "string",
        title: "Dashboard permission",
        enum: ["Private", "Public"]
      },
      components: {
        type: "array",
        formData: formData,
        selectedSatelliteDefinition,
        dataSources,
        satelliteInstances,
        default: [],
        items: {
          type: "object",
          required: ["type", "size", "position"],
          properties: {
            label: {
              type: "string",
              default: ""
            },
            type: {
              type: "string",
              title: "Component type",
              enum: DashboardComponentsAll,
              enumNames: DashboardComponentsNames
            },
            position: {
              type: "object",
              properties: {
                row: {
                  type: "number"
                },
                col: {
                  type: "number"
                }
              }
            },
            size: {
              type: "object",
              properties: {
                row: {
                  type: "number"
                },
                col: {
                  type: "number"
                }
              }
            },
            dataSources: {
              title: "Data Sources",
              type: "array",
              hasRemove: true,
              minItems: 0,
              default: [],
              items: {
                type: "object",
                properties: {
                  id: {
                    type: "number"
                  },
                  label: {
                    type: ["string", "null"]
                  }
                }
              }
            }
          }
        }
      }
    }
  };
};

export const uiSchema = {
  name: { "ui:widget": InputBase },
  satDefinitionId: { "ui:widget": SelectBox },
  description: { "ui:widget": InputBase },
  size: {
    row: { "ui:widget": InputBase },
    col: { "ui:widget": InputBase }
  },
  type: { "ui:widget": SelectBox },
  permissions: { "ui:widget": SelectBox },
  components: { "ui:field": DashboardComponentsConfiguration }
};

import React, { Component } from "react";
import { Badge } from "components";
import { Icon, Link } from "primitives";
import { AlertCounterProps, AlertCounterState, Colors } from "../models";
import { getActiveSatellite } from "app/shared/utils";
import config from "config/constants";

export class AlertCounter extends Component<
  AlertCounterProps,
  AlertCounterState
> {
  private interval: number = 0;
  private updateAlerts: any;

  constructor(props: AlertCounterProps) {
    super(props);
    this.state = {
      alertColor: "status.info",
      alertNumber: "0"
    };
    this.updateAlertsInfo = this.updateAlertsInfo.bind(this);
  }

  componentWillUpdate(nextProps: AlertCounterProps) {
    if (nextProps.alertCounter !== this.props.alertCounter) {
      let total = 0;
      let color = Colors.Default;
      const {
        alertCounter: { numCritical, numWarnings, numOthers }
      } = nextProps;

      if (numCritical) {
        color = Colors.Critical;
      } else if (numWarnings) {
        color = Colors.Warning;
      } else if (numOthers) {
        color = Colors.OutOfBounds;
      }

      if (Object.values(nextProps.alertCounter).length > 0) {
        total = Object.values(nextProps.alertCounter).reduce((a, b) => a + b);
      }

      this.setState({
        alertNumber: total > 0 ? total : `${total}`,
        alertColor: color
      });
    }

    if (nextProps.satellites !== this.props.satellites) {
      this.updateAlertsInfo();
    }

    const { refreshData } = this.props;
    if (refreshData !== nextProps.refreshData && !nextProps.refreshData) {
      clearTimeout(this.interval);
    }
    if (refreshData !== nextProps.refreshData && nextProps.refreshData) {
      this.interval = setTimeout(
        (this.updateAlerts = () => {
          this.updateAlertsInfo().finally(() => {
            this.interval = setTimeout(this.updateAlerts, config.timer.alerts);
          });
        }),
        config.timer.alerts
      );
    }
  }

  componentDidMount() {
    this.interval = setTimeout(
      (this.updateAlerts = () => {
        this.updateAlertsInfo().finally(() => {
          this.interval = setTimeout(this.updateAlerts, config.timer.alerts);
        });
      }),
      config.timer.alerts
    );
  }

  componentWillUnmount() {
    clearTimeout(this.interval);
  }

  updateAlertsInfo(): Promise<any> {
    const activeSatellite = getActiveSatellite(this.props.satellites);
    if (activeSatellite) {
      return this.props.getAlertcount(activeSatellite["id"]);
    }
    return Promise.resolve();
  }

  render() {
    return (
      <Link to="/alerts">
        <Badge count={this.state.alertNumber} data-testid="AlertCounter">
          <Icon name="Alert" color={this.state.alertColor} size={15} />
        </Badge>
      </Link>
    );
  }
}

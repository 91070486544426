import React, { Component } from "react";
import { SatelliteInstances } from "app/satellite";
import { Box, Heading, Flex, Label } from "primitives";
import { SaveButton, InputField } from "components";

export class ConstellationEdit extends Component {
  state = {};

  componentDidMount() {
    this.getConstellation();
  }

  getConstellation() {
    const { id } = this.props.match.params;
    this.props.getConstellation(id).then((data) => {
      this.setState({ ...data });
    });
  }

  onChange = (event) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value });
  };

  submit = (e) => {
    e.preventDefault();
    const { satelliteInstances, ...data } = this.state;
    this.props.updateConstellation(data).then(() => {
      this.props.history.push("/constellation");
    });
  };

  render() {
    const { selected } = this.props;
    if (!selected) return null;

    return (
      <Box color="text.default" data-testid="ConstellationEdit">
        <Flex mb={2}>
          <Heading display={1}>{`Edit Constellation`}</Heading>
        </Flex>
        <Flex flexDirection="column" bg="fill.0" p={3} mb={2}>
          <form onSubmit={(e) => this.submit(e)}>
            <Flex flexDirection="column" mb={3}>
              <Label>ID</Label>
              <InputField disabled={true} defaultValue={selected.id} />
            </Flex>
            <Flex flexDirection="column" mb={3}>
              <Label>Name:</Label>
              <InputField
                name="name"
                fullWidth
                defaultValue={selected.name}
                required={true}
                onChange={(e) => this.onChange(e)}
              />
            </Flex>
            <Flex justifyContent="flex-start" mb={2}>
              <SaveButton type="submit">Save</SaveButton>
            </Flex>
          </form>
        </Flex>
        <Flex flexDirection="column" bg="fill.0" p={3}>
          <SatelliteInstances
            constellation={selected}
            onChange={() => this.getConstellation()}
          />
        </Flex>
      </Box>
    );
  }
}

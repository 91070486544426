import React, { Component } from "react";
import { getFrames } from "../../../satellite/services";
import { SatelliteFramesListTable } from "./SatelliteFramesListTable";
import { PaginationSimple, SelectBox } from "app/shared";
import { Box, Text, Flex } from "primitives";
import config from "config/constants";
import { AuroraTelemetryFrame } from "app/telemetry/models";
import { PaginationParams } from "app/shared/list/components/Pagination";
import { SatelliteInstance } from "app/satellite/models";
import { connect } from "react-redux";

interface SatelliteFramesListProps {
  satellite: SatelliteInstance;
  fetchFrames: (
    satelliteId: number,
    pagination: PaginationParams,
    from: string
  ) => Promise<any>;
  options: any;
  refreshData: boolean;
}

interface SatelliteFramesListState {
  list: AuroraTelemetryFrame[];
  pagination: PaginationParams;
  auxPaginationTimestamps: string[];
  disabledPagination: boolean;
  from: number;
}

const timeFilterOptions = {
  enumOptions: [
    { value: 1, label: "1 Hour" },
    { value: 24, label: "24 Hours" },
    { value: 168, label: "7 Days" },
    { value: 336, label: "14 Days" }
  ]
};

const initialState: SatelliteFramesListState = {
  list: [],
  pagination: { page: 1, perPage: 10 },
  auxPaginationTimestamps: [],
  disabledPagination: false,
  from: 24
};

class BaseSatelliteFramesList extends Component<
  SatelliteFramesListProps,
  SatelliteFramesListState
> {
  private interval?: number;
  private willUnmount: boolean;
  constructor(props: any) {
    super(props);
    this.willUnmount = false;
    this.state = {
      ...initialState
    };
  }

  componentDidMount() {
    this.loadData(0);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearTimeout(this.interval);
    }
    this.willUnmount = true;
  }

  componentWillUpdate(
    nextProps: SatelliteFramesListProps,
    nextState: SatelliteFramesListState
  ) {
    const { pagination, disabledPagination, from } = this.state;
    if (
      (nextState.pagination.page !== pagination.page ||
        nextState.pagination.perPage !== pagination.perPage ||
        nextState.from !== from) &&
      !disabledPagination
    ) {
      this.setState({ disabledPagination: true }, () => this.getFramesList());
    }

    const { refreshData } = this.props;
    if (refreshData !== nextProps.refreshData && !nextProps.refreshData) {
      clearTimeout(this.interval);
    }
    if (refreshData !== nextProps.refreshData && nextProps.refreshData) {
      this.loadData(0);
    }
  }

  private loadData(timer = config.timer.table) {
    const { disabledPagination } = this.state;
    this.interval = setTimeout(() => {
      !disabledPagination &&
        this.getFramesList().finally(() => {
          this.loadData();
        });
    }, timer);
  }

  private getFramesList() {
    const { satellite, fetchFrames } = this.props;
    let { pagination, auxPaginationTimestamps, from } = this.state;
    if (auxPaginationTimestamps[pagination.page - 1]) {
      pagination.to = auxPaginationTimestamps[pagination.page - 1];
    }
    const currentDate: Date = new Date();
    const fromDate: string = new Date(
      currentDate.setHours(currentDate.getHours() - from)
    ).toISOString();

    return fetchFrames(satellite.id, pagination, fromDate)
      .then((response: any) => {
        const data = response.data;
        if (data && data[data.length - 1]) {
          auxPaginationTimestamps[pagination.page] =
            data[data.length - 1].timestamp;
        }
        if (!this.willUnmount) {
          this.setState({
            list: data,
            pagination: pagination,
            auxPaginationTimestamps,
            disabledPagination: false
          });
        }

        return response;
      })
      .catch((error: Error) => {
        console.log("Error: ", error.message);
      });
  }

  renderFilters() {
    const { from } = this.state;
    return (
      <Flex justifyContent="flex-end" alignItems="center">
        <Text mr={1}>Last</Text>
        <Box>
          {/*
        // @ts-ignore */}
          <SelectBox
            /*
          // @ts-ignore */
            id="time-filter-select"
            options={timeFilterOptions}
            onChange={(newFrom: number) =>
              this.setState({ ...initialState, from: newFrom })
            }
            value={from}
          />
        </Box>
      </Flex>
    );
  }

  render() {
    const { list, pagination, disabledPagination } = this.state;
    const { options } = this.props;

    return (
      <>
        {options.label && (
          <Text fontSize={18} m="10px 0">
            {options.label}
          </Text>
        )}
        {list && (
          <Box bg="fill.0" data-testid="BaseSatelliteFramesList">
            {this.renderFilters()}
            <SatelliteFramesListTable list={list} />
            <PaginationSimple
              pageSize={pagination.perPage}
              nextPageUrl={
                list.length >= pagination.perPage ? pagination.page + 1 : 0
              }
              previousPageUrl={pagination.page > 0 ? pagination.page - 1 : 0}
              onChange={(paginationUrl: any) =>
                this.setState({
                  pagination: {
                    page: paginationUrl,
                    perPage: pagination.perPage
                  },
                  disabledPagination: true
                })
              }
              onPageSizeChange={(size: any) =>
                this.setState({
                  pagination: { page: pagination.page, perPage: size }
                })
              }
              disabled={disabledPagination}
            />
          </Box>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  refreshData: state.app.refreshData
});

const mapDispatchToProps = () => {
  return {
    fetchFrames: (
      satelliteId: number,
      pagination: PaginationParams,
      from: string
    ): Promise<any> => {
      return getFrames(satelliteId, {
        pageNumber: pagination.page,
        pageSize: pagination.perPage,
        from: from,
        to: pagination.to
      });
    }
  };
};

export const SatelliteFramesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseSatelliteFramesList);

import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import ReactFitText from "react-fittext";
import { getPaddingForCustomAspectRatio } from "app/shared/utils";
import { fetchGroundStations, GroundStation } from "app/groundStation";
import { Passage } from "app/visibilityWindow/models";
import { DashboardComponents } from "app/dashboard/models";
import { Flex, Text, Icon, Box, Absolute } from "primitives";
import EditDashboardConfigurationModal from "app/dashboard/components/EditDashboardConfigurationModal";

interface PassageCountdownBaseProps {
  options: any;
  type: string;
  passages: Passage[];
  nextPassages: Passage[];
  updateConfigOptions: (configOptions: any) => void;
}

interface PassageCountdownBaseState {
  shouldRender: boolean;
  isHovering: boolean;
  time: number;
  modalOpen: boolean;
  groundStations: GroundStation[];
}

const REFRESH_INTERVAL = 500;

/**
 * Primary component to display the visibility windows.
 */
class PassageCountdownBase extends Component<
  PassageCountdownBaseProps,
  PassageCountdownBaseState
> {
  static defaultProps: any;
  private interval: any;
  private containerRef: any;
  private padding: number;

  constructor(props: PassageCountdownBaseProps) {
    super(props);

    this.state = {
      shouldRender: false,
      isHovering: false,
      time: Date.now(),
      modalOpen: false,
      groundStations: []
    };
    this.containerRef = React.createRef();
    this.padding = 0;
  }

  async componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      REFRESH_INTERVAL
    );

    const groundStations = await fetchGroundStations();
    this.setState({ groundStations, shouldRender: true });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getPassage() {
    const { passages, nextPassages, options } = this.props;
    const allPassages = [...passages, ...nextPassages];
    const { groundstations } = options;

    if (!groundstations || groundstations.length === 0) return allPassages[0];
    //Filter passages by groundstations defined in the widget config options
    return allPassages.filter(
      (passage: Passage) =>
        groundstations.indexOf(passage.groundStationID) > -1 &&
        moment(moment()).isBefore(passage.los)
    )[0];
  }

  renderPassageCountdown() {
    const { options } = this.props;
    const passage: Passage | undefined = this.getPassage();
    const isCurrentPassage = passage && moment(moment()).isAfter(passage.aos);
    const { isHovering } = this.state;
    const { gsVisibilityMode, closeApproachTime } = options;

    const showGroundStation =
      gsVisibilityMode === "alwaysVisible" ||
      (gsVisibilityMode === "onHoverVisible" && isHovering);
    this.padding = getPaddingForCustomAspectRatio(
      this.containerRef,
      showGroundStation ? 5 : 3
    );
    return (
      <Flex
        width="100%"
        onMouseEnter={() => !isHovering && this.setState({ isHovering: true })}
        onMouseLeave={() => isHovering && this.setState({ isHovering: false })}
        flexDirection="column"
        overflow="visible"
        position="relative"
      >
        <Absolute right={0}>
          <Box
            cursor="pointer"
            padding={2}
            onClick={() => this.setState({ modalOpen: true })}
          >
            <Icon name={"SettingsMenu"} size={25} />
          </Box>
        </Absolute>
        <Flex
          pl={this.padding ? `${this.padding / 2}%` : 0}
          pr={this.padding ? `${this.padding / 2}%` : 0}
          flexDirection="row"
          flex={1}
          borderBottom="1"
          overflow="visible"
        >
          <Flex
            flexDirection="column"
            flex={2}
            alignItems="center"
            justifyContent="center"
            overflow="visible"
          >
            <ReactFitText minFontSize={20} compressor={3}>
              <Text textAlign="center" width="100%" color="palette.white.0">
                {isCurrentPassage ? `Passage ends in` : `Next passage in`}
              </Text>
            </ReactFitText>
            <ReactFitText minFontSize={25}>
              {passage ? (
                <Text
                  color={
                    isCurrentPassage
                      ? "palette.green.0"
                      : Math.abs(moment().diff(passage.aos)) <=
                        closeApproachTime
                      ? "palette.orange.0"
                      : "palette.red.1"
                  }
                  textAlign="center"
                  width="100%"
                >
                  {isCurrentPassage
                    ? moment
                        .duration(moment(passage.los).diff(moment()))
                        .format("HH:mm:ss")
                    : moment
                        .duration(moment(passage.aos).diff(moment()))
                        .format("HH:mm:ss")}
                </Text>
              ) : (
                <Text width="100%" textAlign="center" color="palette.grey.2">
                  - - - - - - -
                </Text>
              )}
            </ReactFitText>
          </Flex>
          {showGroundStation ? (
            <Flex
              justifyContent="center"
              flexDirection="column"
              flex={1}
              alignItems="center"
            >
              <ReactFitText minFontSize={20} compressor={2}>
                <Text
                  textAlign="center"
                  width="100%"
                  color="palette.white.0"
                >{`Ground Station`}</Text>
              </ReactFitText>
              <Flex>
                <ReactFitText minFontSize={25}>
                  <Text
                    textAlign="center"
                    width="100%"
                    color={!passage ? "palette.grey.2" : ""}
                  >
                    {passage ? passage.groundStationName : "- - - - - - -"}
                  </Text>
                </ReactFitText>
              </Flex>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    );
  }

  render() {
    const { options, type, updateConfigOptions } = this.props;
    const { modalOpen, groundStations } = this.state;
    return (
      <Flex
        color="text.default"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        data-testid="PassageCountdown"
        overflow="visible"
        flex={1}
        ref={this.containerRef}
      >
        {options.label && (
          <Text fontSize={18} bold>
            {options.label}
          </Text>
        )}
        <Flex flex={1} mt={1} p={2} width="100%" bg="fill.1">
          {this.state.shouldRender && this.renderPassageCountdown()}
          <EditDashboardConfigurationModal
            type={type}
            modalOpen={modalOpen}
            groundStations={groundStations}
            formData={options}
            updateConfigOptions={updateConfigOptions}
            closeModal={() => this.setState({ modalOpen: false })}
          />
        </Flex>
      </Flex>
    );
  }
}

PassageCountdownBase.defaultProps = {
  ...DashboardComponents.VisibilityWindows
};

const mapStateToProps = (state: any) => {
  return {
    passages: state.visibilityWindow.passages,
    nextPassages: state.visibilityWindow.nextPassages
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const PassageCountdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(PassageCountdownBase);

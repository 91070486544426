import { connect } from "react-redux";
import { DatasourceHistoryTable } from "../../telemetry/components/dashboard/DatasourceHistoryTable";
import { getConstellation, clearConstellation } from "app/constellation";
import { getSatelliteDefinition } from "app/satelliteDefinition";
import { getSatellite, selectDataSources } from "../services";

const mapStateToProps = (state) => ({
  dashboard: state.satellite.dashboard,
  satellite: state.satellite.dashboard.satellite,
  satelliteDefinition: state.satellite.dashboard.satelliteDefinition,
  selectedDataSources: state.satellite.dashboard.selectedDataSources
});

const mapDispatchToProps = () => {
  return {
    getConstellation: (id) => getConstellation(id),
    clearConstellation: () => clearConstellation(),
    getSatellite: (id) => getSatellite(id),
    getSatelliteDefinition: (id) => getSatelliteDefinition(id),
    selectDataSources: (ids) => selectDataSources(ids)
  };
};

export const DatasourceHistoryTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasourceHistoryTable);

import React, { Component } from "react";
import { Box, Heading, Flex, Text } from "primitives";
import { Link } from "react-router-dom";
import { Select } from "@material-ui/core";
import { SuspenseQuery, Suspense } from "app/network";
import { AddButton, EditButton } from "components";
import { DashboardDelete } from "./DashboardDelete";
import { SatelliteInstance } from "app/satellite/models";
import { DataProviderResponse } from "app/network/models";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getQueryParam } from "utils/url";

interface DashboardListProps {
  getUserDashboardsLists: (
    satelliteDefinitionId: number | string | null
  ) => Promise<DataProviderResponse>;
  updateUserDashboardsLists: (
    satelliteDefinitionId: number | string | null,
    data: any
  ) => Promise<DataProviderResponse>;
  getUserDashboards: (
    satelliteDefinitionId: number | string | null
  ) => Promise<DataProviderResponse>;
  addDashboards: (dashboards: any) => Promise<DataProviderResponse>;
  selectedSatellite: any;
  constellationSelected: any;
}

interface DashboardListState {
  selectedSatelliteDefinitionId: number | string | null;
  satelliteDefinitions: any[];
  data: any;
}

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (
  source: any[],
  destination: any[],
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "#50678A" : "#34435a",
  ...draggableStyle
});

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? "#6a7e9c" : "#54657f",
  padding: grid,
  width: 400,
  height: "100%",
  margin: "10px 20px"
});

export class DashboardList extends Component<
  DashboardListProps,
  DashboardListState
> {
  state = {
    selectedSatelliteDefinitionId: null,
    satelliteDefinitions: [],
    data: null
  };

  query = (): Promise<DataProviderResponse> => {
    const { selectedSatelliteDefinitionId } = this.state;
    return this.props.getUserDashboardsLists(selectedSatelliteDefinitionId);
  };

  componentWillMount() {
    const { selectedSatellite, constellationSelected } = this.props;
    constellationSelected && this.loadSatelliteDefinitions();
    if (selectedSatellite && selectedSatellite.satelliteDefinitionSummary) {
      this.props
        .getUserDashboards(
          selectedSatellite.satelliteDefinitionSummary.satelliteDefinitionId
        )
        .then(({ data }) => {
          this.props.addDashboards(data || []);
        });
    }
  }

  componentDidUpdate(prevProps: DashboardListProps) {
    const { constellationSelected } = this.props;
    if (!prevProps.constellationSelected && constellationSelected) {
      this.loadSatelliteDefinitions();
    }
  }

  loadSatelliteDefinitions() {
    let satelliteDefinitions: any[] = this.props.constellationSelected.satelliteInstances.map(
      (satelliteInstance: SatelliteInstance) => {
        return satelliteInstance.satelliteDefinitionSummary;
      }
    );
    satelliteDefinitions = satelliteDefinitions.filter(
      (v, i, a) =>
        a.findIndex(
          (t) => t.satelliteDefinitionId === v.satelliteDefinitionId
        ) === i
    );
    const selectedSatelliteDefinitionId = getQueryParam(
      "satelliteDefinitionId"
    );
    this.setState({
      satelliteDefinitions,
      selectedSatelliteDefinitionId: selectedSatelliteDefinitionId
        ? selectedSatelliteDefinitionId
        : satelliteDefinitions.length > 0
        ? satelliteDefinitions[0].satelliteDefinitionId
        : null
    });
  }

  getSatelliteDefinitionIds() {
    if (!this.props.constellationSelected) return [];

    return this.props.constellationSelected.satelliteInstances.map(
      (satelliteInstance: SatelliteInstance) =>
        satelliteInstance.satelliteDefinitionSummary.satelliteDefinitionId
    );
  }

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    listed: "listed",
    unlisted: "unlisted"
  };

  getList = (id: string, data: any) => (data as any)[(this.id2List as any)[id]];

  onDragEnd = (result: any, newData: any) => {
    const { source, destination } = result;
    const { selectedSatelliteDefinitionId } = this.state;
    const { selectedSatellite } = this.props;

    // dropped outside the list
    if (!destination || selectedSatelliteDefinitionId === null) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const listed = reorder(
        this.getList(source.droppableId, newData),
        source.index,
        destination.index
      );
      if (source.droppableId === "unlisted") {
        return null;
      }
      newData.listed = listed;
      this.setState({ data: newData });
      const updatedData = { listed: listed.map((d: any) => d.id) };
      this.props
        .updateUserDashboardsLists(selectedSatelliteDefinitionId, updatedData)
        .then((response) => {
          this.setState({ data: response.data });
          if (selectedSatellite) {
            this.props
              .getUserDashboards(
                selectedSatellite.satelliteDefinitionSummary
                  .satelliteDefinitionId
              )
              .then(({ data }) => {
                this.props.addDashboards(data || []);
              });
          }
        });
    } else {
      const moveResult = move(
        this.getList(source.droppableId, newData),
        this.getList(destination.droppableId, newData),
        source,
        destination
      );
      newData = moveResult;
      this.setState({ data: newData });
      const updatedData = { listed: moveResult.listed.map((d: any) => d.id) };
      this.props
        .updateUserDashboardsLists(selectedSatelliteDefinitionId, updatedData)
        .then((response) => {
          this.setState({ data: response.data });
          if (selectedSatellite) {
            this.props
              .getUserDashboards(
                selectedSatellite.satelliteDefinitionSummary
                  .satelliteDefinitionId
              )
              .then(({ data }) => {
                this.props.addDashboards(data || []);
              });
          }
        });
    }
  };

  render() {
    const {
      selectedSatelliteDefinitionId,
      data,
      satelliteDefinitions
    } = this.state;
    return (
      selectedSatelliteDefinitionId && (
        <>
          <Suspense>
            <SuspenseQuery query={this.query}>
              {({ response, reload }) => {
                const { listed, unlisted } = data ? data : response.data;
                return (
                  <Flex flexDirection="column">
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      mb={2}
                    >
                      <Heading display={1}>Dashboards</Heading>
                      <Flex>
                        <Box mr={2}>
                          <Select
                            required={true}
                            name="Satellite Definition"
                            autoWidth
                            native
                            onChange={(e) =>
                              this.setState(
                                {
                                  data: null,
                                  selectedSatelliteDefinitionId: e.target.value
                                },
                                () => reload()
                              )
                            }
                            defaultValue={selectedSatelliteDefinitionId || ""}
                          >
                            {satelliteDefinitions &&
                              satelliteDefinitions.map((satDef: any) => (
                                <option
                                  key={satDef.satelliteDefinitionId}
                                  value={satDef.satelliteDefinitionId}
                                >
                                  {satDef.name}
                                </option>
                              ))}
                          </Select>
                        </Box>
                        <Link to="/dashboard/create">
                          <AddButton>Create</AddButton>
                        </Link>
                      </Flex>
                    </Flex>
                    <DragDropContext
                      onDragEnd={(result: any) =>
                        this.onDragEnd(result, data ? data : response.data)
                      }
                    >
                      <Flex justifyContent="center">
                        <Flex flexDirection="column">
                          <Flex overflow="visible" justifyContent="center">
                            <Text fontSize={18} bold color="text.default">
                              Listed
                            </Text>
                          </Flex>
                          <Droppable droppableId="listed">
                            {(provided: any, snapshot: any) => (
                              <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {listed.map((item: any, index: number) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(
                                      draggableProvided: any,
                                      draggableSnapshot: any
                                    ) => (
                                      <Flex
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}
                                        style={getItemStyle(
                                          draggableSnapshot.isDragging,
                                          draggableProvided.draggableProps.style
                                        )}
                                      >
                                        <Flex alignItems="center">
                                          <Box mr={1} mb={1}>
                                            <Text color="text.default">::</Text>
                                          </Box>
                                          {item.name}
                                        </Flex>
                                        <Flex
                                          marginLeft="auto"
                                          overflow="visible"
                                        >
                                          <Box mr={2} overflow="visible">
                                            <Link
                                              to={`/dashboard/edit/${item.id}`}
                                            >
                                              <EditButton overflow="visible">
                                                Edit
                                              </EditButton>
                                            </Link>
                                          </Box>
                                          <DashboardDelete
                                            dashboard={item}
                                            onChange={() =>
                                              this.setState(
                                                { data: null },
                                                () => reload()
                                              )
                                            }
                                          />
                                        </Flex>
                                      </Flex>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </Flex>
                        <Flex flexDirection="column">
                          <Flex overflow="visible" justifyContent="center">
                            <Text fontSize={18} bold color="text.default">
                              Unlisted
                            </Text>
                          </Flex>
                          <Droppable droppableId="unlisted">
                            {(provided: any, snapshot: any) => (
                              <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {unlisted.map((item: any, index: number) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(
                                      draggableProvided: any,
                                      draggableSnapshot: any
                                    ) => (
                                      <Flex
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}
                                        style={getItemStyle(
                                          draggableSnapshot.isDragging,
                                          draggableProvided.draggableProps.style
                                        )}
                                      >
                                        <Flex alignItems="center">
                                          {item.name}
                                        </Flex>
                                        <Flex
                                          marginLeft="auto"
                                          overflow="visible"
                                        >
                                          <Box mr={2} overflow="visible">
                                            <Link
                                              to={`/dashboard/edit/${item.id}`}
                                            >
                                              <EditButton overflow="visible">
                                                Edit
                                              </EditButton>
                                            </Link>
                                          </Box>
                                          <DashboardDelete
                                            dashboard={item}
                                            onChange={() => reload()}
                                          />
                                        </Flex>
                                      </Flex>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </Flex>
                      </Flex>
                    </DragDropContext>
                  </Flex>
                );
              }}
            </SuspenseQuery>
          </Suspense>
        </>
      )
    );
  }
}

import React from "react";
import { Dialog, DialogTitle, DialogActions, Grid } from "@material-ui/core";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { DeleteButton, CancelButton } from "components";
import { connect } from "react-redux";
import { deleteScript } from "../services";
import { Script } from "../models";

interface ScriptDeleteProps {
  script: Script;
  onChange: () => void;
  deleteScript: (id: number) => Promise<any>;
}

export const ScriptDelete = ({
  script,
  onChange,
  ...props
}: ScriptDeleteProps) => {
  const submit = async (onToggle: () => void) => {
    await props.deleteScript(script.id);
    if (onChange) {
      onChange();
    }
    onToggle();
  };

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <DeleteButton onClick={onToggle}>Delete</DeleteButton>

          <Dialog
            fullWidth={true}
            open={toggled}
            maxWidth="sm"
            data-testid="ScriptDelete"
          >
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>Delete {script.name}?</DialogTitle>

                    <DialogActions>
                      <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                      >
                        <DeleteButton
                          onClick={() => action(() => submit(onToggle))}
                          mr={1}
                          disabled={loading}
                        >
                          Delete
                        </DeleteButton>
                        <CancelButton onClick={onToggle}>Cancel</CancelButton>
                      </Grid>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};

const mapDispatchToProps = () => ({
  deleteScript
});

export const ScriptDeleteContainer = connect(
  null,
  mapDispatchToProps
)(ScriptDelete);

import { Passage } from "../models";
import { PassageActions, PassageActionType } from "../actions";

interface PassageStoreState {
  passages: Passage[];
  nextPassages: Passage[];
  selectedPassage: Passage | null;
}

const initialState: PassageStoreState = {
  passages: [],
  nextPassages: [],
  selectedPassage: null
};

export const passageReducer = (
  state: PassageStoreState = initialState,
  action: PassageActions
): PassageStoreState => {
  switch (action.type) {
    case PassageActionType.FetchPassages:
      return { ...state, passages: action.payload };
    case PassageActionType.FetchNextPassages:
      return { ...state, nextPassages: action.payload };
    case PassageActionType.SelectPassage:
      return {
        ...state,
        selectedPassage: action.payload
      };
    default:
      return state;
  }
};
